import { useParams } from 'react-router-dom'
import { ModuleMatchParams } from 'src/models/routeParams'
import { useGetModule } from 'src/shared/hooks/modules'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import Module from '../views/Module'

const ModuleContainer = () => {
  /**
   * ----- Hook Initialization -----
   */

  const { id: moduleId } = useParams<ModuleMatchParams>()

  const { module } = useGetModule(moduleId)

  /**
   * ----- Render -----
   */

  if (!module) {
    return <LoadingScreen />
  } else {
    return <Module module={module} />
  }
}

export default ModuleContainer
