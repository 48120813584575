import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from 'react-query'
import { useParams } from 'react-router-dom'

import { IDrugEditableFields, IDrugLibraryItem } from 'src/models/drugLibrary'
import { IIndicationAndSideEffect } from 'src/models/program'
import {
  combineDrugIngredientsIntoValue,
  formatDrugFromLibrary
} from 'src/utils/drugLib'
import { DinsApi } from '../../api/client'
import {
  FETCH_SEARCHED_DRUGS,
  FETCH_DIN,
  FETCH_SEARCHED_INDICATIONS,
  FETCH_SEARCHED_SIDE_EFFECTS,
  FETCH_INDICATION,
  FETCH_SIDE_EFFECT
} from '../../constants/queries'

interface RouteParams {
  din: string
}

interface DrugSearchQueryParams {
  din?: string
  dins?: string[]
  manufacturer?: string
  brandName?: string
  activeIngredient?: string
}

const defaultQueryConfig = {
  refetchOnWindowFocus: false
}

const composeMedicationSearchQuery = (
  {
    din,
    dins,
    manufacturer,
    brandName,
    activeIngredient
  }: DrugSearchQueryParams,
  limit?: number
): string => {
  let query = ''
  if (dins && dins.length > 1) {
    query += `dins=${dins}&`
  } else if (din) {
    return din
  }

  if (brandName) {
    query += `brand_name=${brandName}&`
  }
  if (activeIngredient) {
    query += `active_ingredient=${activeIngredient}&`
  }

  if (manufacturer) {
    query += `manufacturer=${manufacturer}&`
  }

  query += `limit=${limit || '100'}`

  return query
}

export const useDrugSearch = (
  values: DrugSearchQueryParams,
  limit?: number,
  options?: UseQueryOptions<IDrugLibraryItem[] | IDrugLibraryItem>
) => {
  const queryClient = useQueryClient()

  const queryFn =
    values.din && values.dins && values.dins.length <= 1
      ? DinsApi.getDin
      : DinsApi.searchDins

  const query = useQuery<IDrugLibraryItem[] | IDrugLibraryItem>({
    queryKey: FETCH_SEARCHED_DRUGS,
    queryFn: () => queryFn(composeMedicationSearchQuery(values, limit)),
    onSuccess: (matchingDrugs: IDrugLibraryItem[]) => {
      if (!values.din) {
        matchingDrugs.map((matchingDrug) =>
          queryClient.setQueryData(
            FETCH_DIN(matchingDrug.din.value),
            matchingDrug
          )
        )
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(FETCH_SEARCHED_DRUGS)
    },
    enabled: false,
    ...defaultQueryConfig,
    ...options
  })

  let formattedDrugList: IDrugLibraryItem[]
  if (query.data && !query.isError) {
    formattedDrugList = Array.isArray(query.data)
      ? query.data?.map(formatDrugFromLibrary)
      : [formatDrugFromLibrary(query.data)]
  } else {
    formattedDrugList = []
  }

  return { ...query, search: query.refetch, dins: formattedDrugList }
}

export const useDrug = (id?: string) => {
  const { din } = useParams<RouteParams>()
  const dinFromRoute = id ?? din
  let formattedDrug, formattedActiveIngredients

  const query = useQuery<IDrugLibraryItem>({
    queryKey: FETCH_DIN(dinFromRoute),
    queryFn: () => DinsApi.getDin(dinFromRoute),
    ...defaultQueryConfig
  })

  if (query.data) {
    formattedDrug = formatDrugFromLibrary(query.data)
    formattedActiveIngredients = combineDrugIngredientsIntoValue(
      formattedDrug.activeIngredients
    )
  }
  return {
    ...query,
    dinInfo: formattedDrug,
    formattedActiveIngredients
  }
}

export const useIndicationSearch = (
  name: string,
  limit: number,
  enabled?: boolean
) => {
  const queryClient = useQueryClient()

  const query = useQuery<IIndicationAndSideEffect[]>({
    queryKey: FETCH_SEARCHED_INDICATIONS(name),
    queryFn: () => DinsApi.searchIndications(name, limit),
    onSuccess: (matchingIndications: IIndicationAndSideEffect[]) => {
      matchingIndications.map((matchingIndication) =>
        queryClient.setQueryData(
          FETCH_INDICATION(matchingIndication.id),
          matchingIndication
        )
      )
    },
    enabled: enabled || false,
    ...defaultQueryConfig
  })

  return { ...query, search: query.refetch, indications: query.data }
}

export const useSideEffectSearch = (
  name: string,
  limit: number,
  enabled?: boolean
) => {
  const queryClient = useQueryClient()

  const query = useQuery<IIndicationAndSideEffect[]>({
    queryKey: FETCH_SEARCHED_SIDE_EFFECTS(name),
    queryFn: () => DinsApi.searchSideEffects(name, limit),
    onSuccess: (matchingSideEffects: IIndicationAndSideEffect[]) => {
      matchingSideEffects.map((matchingSideEffect) =>
        queryClient.setQueryData(
          FETCH_SIDE_EFFECT(matchingSideEffect.id),
          matchingSideEffect
        )
      )
    },
    enabled: enabled || false,
    staleTime: 0,
    cacheTime: 0,
    ...defaultQueryConfig
  })

  return { ...query, search: query.refetch }
}

export const useIndicationOrSideEffect = (
  id = '',
  type: 'indication' | 'side-effect'
) => {
  const queryKey =
    type === 'indication' ? FETCH_INDICATION(id) : FETCH_SIDE_EFFECT(id)
  const queryFn =
    type === 'indication' ? DinsApi.getIndication : DinsApi.getSideEffect

  const query = useQuery<IIndicationAndSideEffect>({
    queryKey,
    queryFn: () => queryFn(id),
    enabled: !!id
  })

  return { ...query }
}

export const useUpdateIndicationOrSideEffect = (
  type: 'indication' | 'side-effect',
  id: string
) => {
  const isUpdating = !!id
  let mutationFn: (
    name: string,
    id?: string
  ) => Promise<IIndicationAndSideEffect>

  if (type === 'indication') {
    mutationFn = isUpdating
      ? DinsApi.updateIndication
      : DinsApi.createIndication
  } else {
    mutationFn = isUpdating
      ? DinsApi.updateSideEffect
      : DinsApi.createSideEffect
  }

  const mutation = useMutation((name: string) => {
    if (isUpdating) {
      return mutationFn(name, id)
    } else {
      return mutationFn(name)
    }
  })

  return {
    ...mutation
  }
}

export const useUpdateDinMutation = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    ({ din, newValues }: { din: string; newValues: IDrugEditableFields }) =>
      DinsApi.updateDin(din, newValues),
    {
      onSuccess: (_, { din }) => {
        queryClient.invalidateQueries(FETCH_DIN(din))
      }
    }
  )

  return {
    ...mutation,
    updateDin: mutation.mutateAsync
  }
}
