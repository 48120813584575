import { FC } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  DialogProps
} from '@material-ui/core/'
import styled from 'styled-components'

import { FONTS } from '../theme'
import { noop } from 'lodash'

interface ModalProps {
  isOpen: boolean
  fullWidth?: boolean
  title?: string
  lockUntilActionSelected?: boolean
  subtitle?: string
  handleClose: () => void
  dialogActions?: JSX.Element
  scroll?: DialogProps['scroll']
}

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    padding: 30px;
  }
`

const DialogTitleText = styled(Typography)`
  font-family: ${FONTS.medium};
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
`

const Modal: FC<ModalProps> = ({
  isOpen,
  handleClose,
  lockUntilActionSelected = false,
  title,
  fullWidth = true,
  subtitle,
  children,
  dialogActions,
  scroll = 'paper'
}) => {
  return (
    <>
      <StyledDialog
        open={isOpen}
        maxWidth="md"
        fullWidth={fullWidth}
        scroll={scroll}
        onClose={(_, reason) => {
          if (lockUntilActionSelected) {
            if (reason === 'backdropClick' || reason === 'escapeKeyDown') noop()
            else handleClose()
          } else {
            handleClose()
          }
        }}
        aria-labelledby="dialog-popup"
      >
        {title && (
          <DialogTitle>
            <DialogTitleText>{title}</DialogTitleText>
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText>{subtitle}</DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>{dialogActions}</DialogActions>
      </StyledDialog>
    </>
  )
}

export default Modal
