import React from 'react'

import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { IPrescription } from 'src/models/prescription'
import { UserAdminMatchParams } from 'src/models/routeParams'
import {
  useAddPrescriptionProgramMembership,
  useDeletePrescriptionProgramMembership,
  useUserPrescriptionCompatiblePrograms
} from 'src/shared/hooks/prescription'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'
import { Heading } from 'src/shared/styled/Text'
import { DropDown } from 'src/shared/views/DropDown'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import styled from 'styled-components'

interface IPrescriptionProgramMembership {
  prescription: IPrescription
  canCurrentUserWrite: boolean
  setPrescription: React.Dispatch<
    React.SetStateAction<IPrescription | undefined>
  >
}

const Container = styled.div`
  padding: 1em;
`

const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  padding: 1em;
  justify-content: space-between;
`

const ContainerHeading = styled(Heading)`
  font-size: 1.25em;
`

const ProgramName = styled.h4`
  margin-top: auto;
  margin-bottom: auto;
`

const Button = styled(ButtonWithMargin)`
  height: 3em;
  margin-top: auto;
  margin-bottom: auto;
`

const formFieldStyles = {
  marginBottom: '30px',
  marginRight: '20px',
  minWidth: '200px',
  width: '75%'
}

const PrescriptionProgramMembership = ({
  prescription,
  canCurrentUserWrite,
  setPrescription
}: IPrescriptionProgramMembership) => {
  /**
   * ----- Hook Instantiation -----
   */
  const { id: userId } = useParams<UserAdminMatchParams>()

  const {
    programs,
    isLoading: isCompatibleProgramsLoading,
    isFetched: isCompatibleProgramsFetched
  } = useUserPrescriptionCompatiblePrograms(userId, prescription.prescriptionId)

  const {
    deleteProgramMembership,
    data: newPrescriptionAfterDeletion,
    isLoading: isMembershipDeletionLoading,
    isSuccess: isMembershipDeletionSuccessful,
    error: membershipDeletionError
  } = useDeletePrescriptionProgramMembership(
    userId,
    prescription.prescriptionId
  )

  const {
    addProgramMembership,
    data: newPrescriptionAfterMembership,
    isLoading: isMembershipAddLoading,
    isSuccess: isMembershipAddSuccess,
    error: membershipAddError
  } = useAddPrescriptionProgramMembership(userId, prescription.prescriptionId)

  const [selectedProgramId, setSelectedProgramId] = React.useState<string>()

  /**
   * ----- Variables -----
   */

  const options = React.useMemo(() => {
    if (programs) {
      // Remove program from list if prescription already has this program membership
      const programsCopy = [...programs]
      const index = programsCopy.findIndex(
        (program) => prescription.programMembership?.programId === program.id
      )
      if (index !== -1) programsCopy.splice(index, 1)

      return programsCopy.map((program, index) => {
        return {
          label: program.name,
          value: program.id || index.toString()
        }
      })
    } else return []
  }, [programs, prescription])

  /**
   * ----- Use-effects and other logic -----
   */

  React.useEffect(() => {
    if (
      !isMembershipDeletionLoading &&
      isMembershipDeletionSuccessful &&
      newPrescriptionAfterDeletion
    ) {
      setPrescription(newPrescriptionAfterDeletion)
    } else if (membershipDeletionError) {
      toast.error(`${membershipDeletionError}`)
    }
  }, [
    isMembershipDeletionLoading,
    isMembershipDeletionSuccessful,
    membershipDeletionError,
    newPrescriptionAfterDeletion,
    setPrescription
  ])

  React.useEffect(() => {
    if (
      !isMembershipAddLoading &&
      isMembershipAddSuccess &&
      newPrescriptionAfterMembership
    ) {
      setPrescription(newPrescriptionAfterMembership)
    } else if (membershipAddError) {
      toast.error(`${membershipAddError}`)
    }
  }, [
    isMembershipAddLoading,
    isMembershipAddSuccess,
    membershipAddError,
    newPrescriptionAfterMembership,
    setPrescription
  ])

  /**
   * ----- Rendering -----
   */

  let newProgramSection = <LoadingScreen />

  if (
    options.length === 0 &&
    !isCompatibleProgramsLoading &&
    isCompatibleProgramsFetched
  )
    newProgramSection = (
      <Container>
        <ContainerHeading>New Program</ContainerHeading>
        <SectionContainer>
          <ProgramName>No other compatible programs available</ProgramName>
        </SectionContainer>
      </Container>
    )
  if (options.length > 0)
    newProgramSection = (
      <Container>
        <ContainerHeading>NewProgram</ContainerHeading>
        <SectionContainer>
          <DropDown
            style={formFieldStyles}
            label="Compatible Programs"
            items={options}
            onChange={(e) => setSelectedProgramId(e.target.value)}
            value={selectedProgramId}
            disabled={!canCurrentUserWrite}
          />
          <Button
            onClick={() => {
              if (selectedProgramId) {
                addProgramMembership(selectedProgramId)
              } else toast.error('Must select a program first')
            }}
            isLoading={isMembershipAddLoading}
            disabled={!canCurrentUserWrite}
          >
            Submit
          </Button>
        </SectionContainer>
      </Container>
    )

  return (
    <Container>
      <Container>
        <ContainerHeading>Current Program</ContainerHeading>
        {prescription.programMembership ? (
          <SectionContainer>
            <ProgramName>
              {prescription.programMembership.programName}
            </ProgramName>
            <Button
              onClick={() => {
                if (prescription.programMembership?.programId) {
                  if (
                    window.confirm('Are you sure you want to delete program?')
                  )
                    deleteProgramMembership(
                      prescription.programMembership.programId
                    )
                }
              }}
              isLoading={isMembershipDeletionLoading}
              disabled={!canCurrentUserWrite}
            >
              Delete
            </Button>
          </SectionContainer>
        ) : (
          <SectionContainer>
            <ProgramName>No current program</ProgramName>
          </SectionContainer>
        )}
      </Container>
      {newProgramSection}
    </Container>
  )
}

export default PrescriptionProgramMembership
