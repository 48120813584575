import { useState, useEffect, FC } from 'react'
import { Prompt } from 'react-router-dom'
import { Location } from 'history'
import { DialogActions } from '@material-ui/core'

import Modal from 'src/shared/views/Modal'
import { Buttons } from 'src/shared/styled'

const { ButtonWithMargin, ButtonWithWidth } = Buttons

type Props = {
  shouldPrompt?: boolean | undefined
  navigate: (path: string) => void
  shouldBlockNavigation: (location: Location) => boolean
}

const initBeforeUnLoad = (showExitPrompt: boolean) => {
  window.onbeforeunload = (event: any) => {
    if (showExitPrompt) {
      const e = event || window.event
      e.preventDefault()
      if (e) {
        e.returnValue = ''
      }
      return ''
    }
    return null
  }
}

const ExitPrompt: FC<Props> = ({
  shouldPrompt,
  navigate,
  shouldBlockNavigation
}) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const closeModal = () => {
    setModalVisible(false)
  }
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true)
      setLastLocation(nextLocation)
      return false
    }
    initBeforeUnLoad(modalVisible)
    return true
  }
  const handleConfirmNavigationClick = () => {
    setModalVisible(false)
    setConfirmedNavigation(true)
  }
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname)
    }
  }, [confirmedNavigation, lastLocation, navigate])

  // Initialize the beforeunload event listener after the resources are loaded
  window.onload = function () {
    initBeforeUnLoad(modalVisible)
  }

  // Re-Initialize the onbeforeunload event listener
  useEffect(() => {
    initBeforeUnLoad(modalVisible)
  }, [modalVisible])

  const Actions = () => {
    return (
      <DialogActions>
        <ButtonWithWidth
          onClick={handleConfirmNavigationClick}
          variant="contained"
          color="primary"
        >
          LEAVE
        </ButtonWithWidth>
        <ButtonWithMargin
          onClick={closeModal}
          variant="outlined"
          color="primary"
        >
          CANCEL
        </ButtonWithMargin>
      </DialogActions>
    )
  }

  return (
    <>
      <Prompt when={shouldPrompt} message={handleBlockedNavigation} />
      <Modal
        isOpen={modalVisible}
        title="Unsaved Changes"
        subtitle="Are you sure you want to leave this page? Changes may not be saved."
        dialogActions={<Actions />}
        handleClose={closeModal}
      />
    </>
  )
}

export default ExitPrompt
