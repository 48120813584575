import React from 'react'
import { InputAdornment, TextField } from '@material-ui/core'
import styled from 'styled-components'

import { EditableCellProps } from '../../../models/program'
import NumberFormatCustom from '../NumberFormatCustom'
import { COLORS } from 'src/shared/theme'

const StyledTextField = styled(TextField)`
  & .MuiInputBase-input {
    text-align: center;
  }
  & .MuiInputBase-root {
    height: 50px;
  }
  && :hover {
    background: ${COLORS.turquoisePale};
  }
`

const Percentage = styled(InputAdornment)`
  padding: 5px;
`

// Create an editable cell renderer
export const StrategyAllCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData // This is a custom function that we supplied to our table instance
}: EditableCellProps) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(e.target.value))
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    const newValue = updateMyData(index, id, value)
    if (newValue) setValue(newValue)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const adherenceOrPointsPortion =
    id === 'skippedDose' ||
    id === 'adherenceContribution' ||
    id.includes('lateDiscount') ||
    id === 'literacyContribution'

  let endAdornment
  if (adherenceOrPointsPortion)
    endAdornment = <Percentage position="end">%</Percentage>

  return initialValue === null ? (
    value
  ) : (
    <StyledTextField
      style={{
        width: '100%',
        border: 'none'
      }}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      InputProps={{
        disableUnderline: true,
        inputComponent: NumberFormatCustom as any,
        endAdornment
      }}
    />
  )
}
