import React from 'react'

import { Controller } from 'react-hook-form'
import { useParams } from 'react-router'
import { UserAdminMatchParams } from 'src/models/routeParams'
import { IDirectDepositData } from 'src/models/user'
import {
  useAddDirectDeposit,
  useUpdateDirectDeposit
} from 'src/shared/hooks/users'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'
import { SubHeaderName } from 'src/shared/styled/Text'
import ThemedTextField from 'src/shared/views/TextField'
import styled from 'styled-components'
import useDirectDepositForm from '../hooks/useDirectDepositForm'

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`

const TextField = styled(ThemedTextField)`
  margin: 1em;
`

interface IDirectDepositForm {
  closeModal?: () => void
  existingDirectDepositId?: string
  defaultValues?: IDirectDepositData
}

const DirectDepositForm = ({
  existingDirectDepositId,
  defaultValues,
  closeModal
}: IDirectDepositForm) => {
  const { id: userId } = useParams<UserAdminMatchParams>()

  const { control, handleSubmit } = useDirectDepositForm({
    defaultValues
  })

  const { addDirectDeposit, isLoading: addLoading } = useAddDirectDeposit(
    userId,
    {
      onSuccess: () => {
        if (closeModal) closeModal()
      }
    }
  )

  const { updateDirectDeposit } = useUpdateDirectDeposit(
    userId,
    existingDirectDepositId || '',
    {
      onSuccess: () => {
        if (closeModal) closeModal()
      }
    }
  )

  /**
   * ----- Functions -----
   */

  const submitHandler = (data: IDirectDepositData) => {
    if (!existingDirectDepositId) {
      addDirectDeposit(data)
    } else {
      updateDirectDeposit(data)
    }
  }

  /**
   * ----- Variables -----
   */

  const isLoading = React.useMemo(() => {
    return addLoading
  }, [addLoading])

  return (
    <div>
      <FormContainer onSubmit={handleSubmit(submitHandler)}>
        <SubHeaderName>Direct Deposit</SubHeaderName>
        <Controller
          name="label"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField error={!!error?.message} label="Label" {...field} />
          )}
        />
        <Controller
          name="institutionNumber"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              error={!!error?.message}
              label="Institution Number"
              {...field}
            />
          )}
        />
        <Controller
          name="transitNumber"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              error={!!error?.message}
              label="Transit Number"
              {...field}
            />
          )}
        />
        <Controller
          name="accountNumber"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              error={!!error?.message}
              label="Account Number"
              {...field}
            />
          )}
        />
        <ButtonWithMargin type="submit" isLoading={isLoading}>
          Submit
        </ButtonWithMargin>
      </FormContainer>
    </div>
  )
}

export default DirectDepositForm
