import { useParams } from 'react-router-dom'
import { UserAdminMatchParams } from 'src/models/routeParams'
import { useUser } from 'src/shared/hooks/users'

export const useUserFromQueryString = () => {
  const { id: userId } = useParams<UserAdminMatchParams>()

  const userQuery = useUser(userId)

  return userQuery?.data
}
