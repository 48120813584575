import { Position } from '@xyflow/react'
import React from 'react'
import { useModuleFormContext } from 'src/contexts/ModuleForm'
import { IMultiSelectQuestion } from 'src/models/module'
import {
  ModuleContainer,
  ModuleContent,
  ModuleHeaderContainer,
  ModuleSelectableOptionContainer
} from 'src/shared/styled/Module'
import LanguageMapDisplay from 'src/shared/views/LanguageMap'
import LanguageMapForm from 'src/shared/views/LanguageMapForm'
import styled from 'styled-components'
import FirstTaskButton from './FirstTaskButton'
import NodeHandle from './NodeHandle'
import SelectableOptionList from './SelectableOptionList'

const Row = styled.div`
  display: flex;
  flex-direction: row;
`
interface IMultiSelectQuestionNode {
  data: IMultiSelectQuestion
}

const MultiSelectQuestionNode = React.memo(
  ({ data }: IMultiSelectQuestionNode) => {
    /**
     * ----- Hook Initialization -----
     */

    const { moduleFormData, updateTask } = useModuleFormContext()

    /**
     * ----- Render -----
     */

    return (
      <>
        <NodeHandle type="target" position={Position.Top} />
        <ModuleContainer>
          <ModuleHeaderContainer>
            <h3>Multi-select Question</h3>
            <FirstTaskButton taskId={data.taskId} />
          </ModuleHeaderContainer>
          <ModuleContent>
            <LanguageMapForm
              value={data.text}
              onChange={(value) => {
                updateTask({ ...data, text: value })
              }}
              supportedLanguages={moduleFormData.supportedLanguages}
              textFieldProps={{
                multiline: true,
                minRows: 2
              }}
            />
          </ModuleContent>
          <Row>
            {data.selectableOptions.map((option) => {
              return (
                <ModuleSelectableOptionContainer
                  style={{
                    width: `${(1 / data.selectableOptions.length) * 100}%`
                  }}
                  key={option.selectionId}
                >
                  <LanguageMapDisplay
                    value={option.text}
                    supportedLanguages={moduleFormData.supportedLanguages}
                  />
                </ModuleSelectableOptionContainer>
              )
            })}
          </Row>
        </ModuleContainer>
        <NodeHandle type="source" position={Position.Bottom} />
      </>
    )
  }
)

export const MultiSelectQuestionNodeForm = ({
  data
}: IMultiSelectQuestionNode) => {
  /**
   * ----- Hook Initialization -----
   */

  const { moduleFormData, updateTask } = useModuleFormContext()

  /**
   * ----- Render -----
   */

  return (
    <div>
      <h3>Multi Select Question</h3>
      <LanguageMapForm
        value={data.text}
        onChange={(value) => {
          updateTask({ ...data, text: value })
        }}
        supportedLanguages={moduleFormData.supportedLanguages}
        textFieldProps={{
          multiline: true
        }}
      />
      <h4>Options</h4>
      <SelectableOptionList
        taskId={data.taskId}
        selectableOptions={data.selectableOptions}
        onOptionsChange={(options) => {
          updateTask({ ...data, selectableOptions: options })
        }}
      />
    </div>
  )
}

export default MultiSelectQuestionNode
