import { ChangeEvent, FC, useState } from 'react'
import { Box } from '@material-ui/core'

import Tab, { TabChildren } from 'src/shared/views/Navigation/TabNavigation'
import { USER_PROGRAMS_TAB_OPTIONS } from 'src/constants/user'
import UserProgramOverview from './UserProgramOverview'
import { HeaderName, SubHeaderName } from 'src/shared/styled/Text'
import {
  DetailsContainer,
  DetailsHeaderContainer,
  DetailsHeader
} from 'src/shared/styled/Layout'
import { FONTS } from 'src/shared/theme'
import { useUserFromQueryString } from '../hooks/useUserFromQueryString'
import { IUserProgramProgramMemberships } from 'src/models/user'

type ProgramProps = {
  programs: IUserProgramProgramMemberships[]
  userId: string
}

const Program: FC<ProgramProps> = ({ programs, userId }) => {
  const [value, setValue] = useState(0)
  const user = useUserFromQueryString()

  const handleChange = (
    _event: ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => {
    setValue(newValue)
  }

  const tabPanelOptions = [
    <UserProgramOverview userId={userId} programs={programs} />
  ]

  return (
    <DetailsContainer>
      <DetailsHeaderContainer>
        <DetailsHeader>
          <div>
            <HeaderName style={{ textTransform: 'uppercase' }}>
              Users /{' '}
              {user?.firstName && (
                <strong style={{ fontFamily: FONTS.bold }}>
                  {user.firstName} {user.lastName}
                </strong>
              )}
            </HeaderName>
            <SubHeaderName>Program Memberships</SubHeaderName>
          </div>
        </DetailsHeader>
        <Tab
          value={value}
          onChange={handleChange}
          tabOptions={USER_PROGRAMS_TAB_OPTIONS}
          aria-label="user programs"
        />
      </DetailsHeaderContainer>
      <Box p={3}>
        <TabChildren
          type="userPrograms"
          value={value}
          tabPanelOptions={tabPanelOptions}
        />
      </Box>
    </DetailsContainer>
  )
}

export default Program
