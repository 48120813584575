import ClaimsTable from 'src/features/userAdmin/views/ClaimsTable'
import { IUserClaim } from 'src/models/user'

interface IClaimsView {
  claims: IUserClaim[]
}

const ClaimsView = ({ claims }: IClaimsView) => {
  return <ClaimsTable claims={claims} />
}

export default ClaimsView
