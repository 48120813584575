import { useParams } from 'react-router-dom'

import { UserAdminMatchParams } from 'src/models/routeParams'
import { useUserPrescriptions } from 'src/shared/hooks/prescription'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import UserInfoMedicationHistoryView from '../views/UserInfoMedicationHistory'

const UserInfoMedicationHistory = () => {
  const { id: userId } = useParams<UserAdminMatchParams>()

  const { isLoading, error, prescriptions } = useUserPrescriptions(userId)

  if (error) return <p>There was an error fetching this user</p>
  if (!isLoading && prescriptions)
    return <UserInfoMedicationHistoryView prescriptions={prescriptions} />

  return <LoadingScreen />
}

export default UserInfoMedicationHistory
