import React from 'react'
import styled from 'styled-components'

const TooltipElement = styled.div`
  position: absolute;
  background-color: white;
  padding: 10px;
  border: 1px solid black;
  z-index: 1;
`

interface ITooltip {
  description: string
}

const Tooltip: React.FC<ITooltip> = ({ description, children }) => {
  const [showTooltip, setShowTooltip] = React.useState(false)

  return (
    <div>
      <span
        style={{ cursor: 'help' }}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {children}
      </span>
      {showTooltip && <TooltipElement>{description}</TooltipElement>}
    </div>
  )
}

export default Tooltip
