import React from 'react'
import { useHistory } from 'react-router-dom'
import { UserPermissionsEnum } from 'src/models/user'
import { useGetModules } from 'src/shared/hooks/modules'
import { usePermissions } from 'src/shared/hooks/usePermissions'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'
import { Container, HeaderContainer } from 'src/shared/styled/Module'
import { PageLevelHeading } from 'src/shared/styled/Text'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import Modules from '../views/Modules'

const ModulesContainer = () => {
  /**
   * ----- Hook Initialization -----
   */

  const history = useHistory()
  const permissions = usePermissions()

  const { modules, isLoading } = useGetModules()

  /**
   * ----- Render -----
   */

  const content = React.useMemo(() => {
    if (!modules || isLoading) {
      return <LoadingScreen />
    } else {
      return <Modules modules={modules} />
    }
  }, [isLoading, modules])

  return (
    <Container>
      <HeaderContainer>
        <PageLevelHeading>Module Management</PageLevelHeading>
        {permissions.includes(UserPermissionsEnum.LearningModuleWrite) && (
          <div>
            <ButtonWithMargin onClick={() => history.push('/module/create')}>
              CREATE MODULE
            </ButtonWithMargin>
          </div>
        )}
      </HeaderContainer>
      {content}
    </Container>
  )
}

export default ModulesContainer
