import { FC } from 'react'

import ProgramDetails from '../views/ProgramDetails'
import { ProgramMatchParams } from 'src/models/routeParams'
import { useProgram } from 'src/shared/hooks/program'
import { useParams } from 'react-router-dom'
import LoadingScreen from 'src/shared/views/LoadingScreen'

const ProgramDetailsContainer: FC = () => {
  /**
   * ----- Hook Initialization -----
   */

  const { id: programId } = useParams<ProgramMatchParams>()
  const { isLoading, program } = useProgram(programId)

  /**
   * ----- Render -----
   */

  if (isLoading || !program) {
    return <LoadingScreen />
  }

  return (
    <div style={{ flexDirection: 'column', width: '100%' }}>
      <ProgramDetails program={program} />
    </div>
  )
}

export default ProgramDetailsContainer
