import { Controller } from 'react-hook-form'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { IProgramMembershipClaimData } from 'src/models/program'
import {
  ProgramMatchParams,
  UserAdminMatchParams
} from 'src/models/routeParams'
import { useAddClaimToProgramMembership } from 'src/shared/hooks/users'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import { Heading } from 'src/shared/styled/Text'
import styled from 'styled-components'
import useProgramMembershipClaim from '../hooks/useProgramMembershipClaim'
import TextField from 'src/shared/views/TextField'
import CurrencySelect from 'src/shared/views/CurrencySelect'

interface IClaimTransactionForm {
  closeModal: () => void
}

const Container = styled.div`
  padding: 1em;
`

const FormFieldsRow = styled.div`
  display: flex;
  margin-bottom: 2em;
`

const SubmitButton = styled(ButtonWithWidth)`
  margin-top: 1em;
`

const ClaimTransactionForm = ({ closeModal }: IClaimTransactionForm) => {
  /**
   * ----- Hook Initialization -----
   */

  const { id: userId } = useParams<UserAdminMatchParams>()
  const { programId } = useParams<ProgramMatchParams>()

  const { addClaim, isLoading } = useAddClaimToProgramMembership(
    userId,
    programId,
    {
      onSuccess: () => {
        closeModal()
        toast.success('Claim successfully posted')
      },
      onError: () => {
        toast.error(
          "Unable to submit, the user likely doesn't have a sufficient balance"
        )
      }
    }
  )

  const { handleSubmit, control } = useProgramMembershipClaim({
    defaultValues: {
      amount: {
        currencyCode: 'CAD'
      }
    }
  })

  /**
   * ----- Functions -----
   */

  const submitHandler = (value: IProgramMembershipClaimData) => {
    addClaim(value)
  }

  return (
    <Container>
      <Heading>Claim Transaction Form</Heading>
      <form onSubmit={handleSubmit(submitHandler)}>
        <FormFieldsRow>
          <Controller
            control={control}
            defaultValue={0}
            name="amount.amount"
            render={({ field, fieldState: { error } }) => (
              <TextField
                error={!!error?.message}
                label="Amount ($)"
                type="number"
                noNegative
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue="CAD"
            name="amount.currencyCode"
            render={({ field, fieldState: { error } }) => (
              <CurrencySelect error={!!error?.message} {...field} />
            )}
          />
        </FormFieldsRow>
        <Controller
          control={control}
          name="notes"
          defaultValue=""
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField
              error={!!error}
              style={{ width: '100%' }}
              placeholder="Add a note"
              label="Notes"
              type="text"
              rows={4}
              multiline
              ref={ref}
              {...field}
            />
          )}
        />
        <SubmitButton isLoading={isLoading} type="submit">
          Submit
        </SubmitButton>
      </form>
    </Container>
  )
}

export default ClaimTransactionForm
