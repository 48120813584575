import UserAdminView from '../views/Main'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import ErrorScreen from 'src/shared/views/ErrorScreen'
import { useUsers } from 'src/shared/hooks/users'

const UserAdmin = () => {
  const { isLoading, error, users } = useUsers()

  if (error) return <ErrorScreen />
  if (!isLoading && users) return <UserAdminView data={users} />

  return <LoadingScreen />
}

export default UserAdmin
