import React from 'react'
import { InputAdornment, TextField } from '@material-ui/core'
import styled from 'styled-components'

import { EditableCellProps } from 'src/models/program'
import NumberFormatCustom from '../NumberFormatCustom'
import { COLORS } from 'src/shared/theme'

const StyledTextField = styled(TextField)`
  & .MuiInput-root {
    height: 60px;
  }
  && :hover {
    background: ${COLORS.turquoisePale};
  }
`

// Create an editable cell renderer
export const EditableAllCell = ({
  value: initialValue,
  row: {
    index,
    original: { drugAdminType }
  },
  column: { id },
  updateMyData // This is a custom function that we supplied to our table instance
}: EditableCellProps) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(e.target.value))
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, Number(value))
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return id === 'Drug Administration Type' ? (
    `${drugAdminType}`
  ) : drugAdminType === 'Scheduled' ? (
    ''
  ) : (
    <StyledTextField
      style={{ width: '100%', border: 'none' }}
      value={Number(value || 0).toFixed(2)}
      onChange={onChange}
      onBlur={onBlur}
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment style={{ paddingLeft: '0.4em' }} position="start">
            $
          </InputAdornment>
        ),
        inputComponent: NumberFormatCustom as any
      }}
    />
  )
}
