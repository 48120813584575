import { FC, forwardRef } from 'react'
import { match, NavLink, NavLinkProps } from 'react-router-dom'
import { List, ListItemText } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'

import { Navigation } from '../styled'
import {
  IBackButton,
  INavigationDrawerStep
} from '../../models/navigationDrawer'

const { StyledDrawer, StyledListItem, StyledToolbar, BackButtonPlaceHolder } =
  Navigation

interface MatchParams {
  id: string
}

export interface DrawerProps {
  backButton?: IBackButton
  match: match<MatchParams> | null
  navigationSteps: INavigationDrawerStep[]
}

const ClippedDrawer: FC<DrawerProps> = ({
  backButton,
  match,
  navigationSteps
}) => {
  const id = match?.params?.id
  return (
    <StyledDrawer variant="permanent">
      <StyledToolbar />
      <div style={{ borderTop: '1px solid white' }}>
        {backButton ? (
          <StyledListItem
            button
            component={forwardRef((props: NavLinkProps, ref: any) => (
              <NavLink exact {...props} innerRef={ref} />
            ))}
            to={backButton.route}
            style={{
              paddingBottom: '40px'
            }}
          >
            <ArrowBack />
            <p style={{ marginLeft: '10px' }}>{backButton.text}</p>
          </StyledListItem>
        ) : (
          <BackButtonPlaceHolder></BackButtonPlaceHolder>
        )}
        <List component="nav" disablePadding>
          {navigationSteps.map((step) => (
            <StyledListItem
              button
              key={step.text}
              component={forwardRef((props: NavLinkProps, ref: any) => (
                <NavLink {...props} innerRef={ref} />
              ))}
              style={{
                paddingLeft: '30px',
                alignItems: 'center'
              }}
              to={
                id
                  ? `${step.linkStart}/${id}/${step.linkEnd}`
                  : `${step.linkStart}/${step.linkEnd}`
              }
            >
              <div
                style={{
                  width: 30,
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <step.Icon />
              </div>
              <ListItemText
                primary={step.text}
                style={{ marginLeft: '10px' }}
              />
            </StyledListItem>
          ))}
        </List>
      </div>
    </StyledDrawer>
  )
}

export default ClippedDrawer
