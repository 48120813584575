import { Table, TableCell, TableRow } from '@material-ui/core'
import styled from 'styled-components'
import { COLORS, FONTS } from '../theme'

export const StyledTable = styled(Table)`
  border: 1px solid ${COLORS.mercury};
  width: 100%;
  border-radius: 4px;
  border-collapse: separate;
`

export const TableHeaderCell = styled(TableCell)`
  font-family: ${FONTS.medium};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.08px;
  line-height: 21.98px;
  color: rgba(0, 0, 0, 0.87);
`

export const FocusedTableCell = styled(TableCell)`
  font-family: ${FONTS.main};
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.11px;
  line-height: 20px;
  & .MuiInput-root.Mui-focused {
    background: ${COLORS.white};
    border: ${(props) => `1px solid ${props.theme.palette.secondary.main}`};
  }
  & .MuiInput-root {
    && :hover {
      background: ${COLORS.turquoisePale};
    }
  }
  & .MuiTableCell-root:hover {
    color: 'red';
    background: ${COLORS.turquoisePale};
    && :hover {
      background: ${COLORS.turquoisePale};
    }
  }
`

export const HoverableTableRow = styled(TableRow)`
  &:hover {
    background-color: ${COLORS.blizzardBlue};
    cursor: pointer;
  }
`
