import { ProgramMembershipVerificationStatuses } from './user'

export enum PrescriptionVerificationStatuses {
  Verified = 'VERIFIED',
  Submitted = 'SUBMITTED',
  Unverified = 'UNVERIFIED',
  Rejected = 'REJECTED'
}

export interface IVerificationDetail {
  status: PrescriptionVerificationStatuses
  imageUrl?: string
  customRejectionMsg?: string
  dateSubmitted?: Date
}

export interface IPrescriptionVerificationTask {
  prescriptionId: string
  din?: string
  drugName?: string
  doseStrength?: string
  verification: IVerificationDetail
}

export interface IVerificationTask {
  userId: string
  userName?: string
  programId: string
  programName?: string
  programVerificationStatus: ProgramMembershipVerificationStatuses
  prescriptions: IPrescriptionVerificationTask[]
  usersUsingSameEmail:
    | { userId: string; firstName: string; lastName: string }[]
    | null
  under18: boolean
}

export enum VerificationTaskStatus {
  Actionable = 'ACTIONABLE',
  NotActionable = 'NOT_ACTIONABLE',
  Verified = 'COMPLETED_VERIFIED',
  Rejected = 'COMPLETED_REJECTED'
}

export interface IVerificationTaskResponseBody {
  tabTotals: {
    [key in VerificationTaskStatus]: number
  }
  data: IVerificationTask[]
}

export interface IVerificationTaskAuditLog {
  desc: string
  imageUrl?: string
  date: string
}
