import { FormLabel } from '@material-ui/core'
import { LanguageMap } from 'src/models/misc'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
`

const Text = styled.p`
  margin: 10px 0;
  width: 100%;
`

interface ILanguageMap {
  value?: LanguageMap
  label?: string
  supportedLanguages: ('en' | 'fr')[]
}

const LanguageMapDisplay = ({
  value,
  label,
  supportedLanguages
}: ILanguageMap) => {
  /**
   * ----- Functions -----
   */

  // const getTextFieldLabel = React.useCallback((lang: string) => {
  //   switch (lang) {
  //     case 'en':
  //       return 'English'
  //     case 'fr':
  //       return 'French'
  //     default:
  //       return lang
  //   }
  // }, [])

  /**
   * ----- Render -----
   */

  return (
    <Container>
      {label && <FormLabel>{label}</FormLabel>}
      {supportedLanguages.map((lang) => (
        <Text key={lang}>{value ? value[lang] : ''}</Text>
      ))}
    </Container>
  )
}

export default LanguageMapDisplay
