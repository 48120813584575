import React from 'react'

import { Controller } from 'react-hook-form'
import { Form } from 'src/shared/styled/Tools'
import TextField from 'src/shared/views/TextField'

interface IToolsModalFormProps {
  formId: string
  onSubmit: (formValues: any) => any
  form: any
  data: any
  name: string
  label: string
}

const formFieldStyles = {
  marginBottom: '30px',
  marginRight: '20px',
  minWidth: '200px',
  width: '50%',
  maxWidth: '250px'
}

const ToolsModalForm: React.FC<IToolsModalFormProps> = ({
  formId,
  onSubmit,
  form,
  data,
  name,
  label
}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = form

  watch()

  return (
    <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        defaultValue={data?.name || ''}
        name="name"
        control={control}
        render={({ field: { ref, ...props } }) => (
          <TextField
            id={name}
            label={label}
            style={formFieldStyles}
            inputRef={ref}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
            {...props}
          />
        )}
      />
    </Form>
  )
}

export default ToolsModalForm
