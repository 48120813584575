import { logger } from '@sentry/utils'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { PrescriptionsApi } from 'src/api/client'
import {
  FETCH_USER_PRESCRIPTIONS,
  FETCH_USER_PRESCRIPTION_COMPATIBLE_PROGRAMS,
  FETCH_USER_PROGRAMS
} from 'src/constants/queries'
import {
  IPrescription,
  IPrescriptionCreationBody,
  IPrescriptionDosageUpdateBody,
  IPrescriptionRefillUpdateBody
} from 'src/models/prescription'
import { IProgram } from 'src/models/program'

/**
 * ----- Queries -----
 */

export const useUserPrescriptions = (userId: string) => {
  const query = useQuery<IPrescription[]>(
    FETCH_USER_PRESCRIPTIONS(userId),
    () => PrescriptionsApi.listUserPrescriptions(userId)
  )

  return { ...query, prescriptions: query.data }
}

export const useUserPrescriptionCompatiblePrograms = (
  userId: string,
  prescriptionId: string
) => {
  const query = useQuery<IProgram[]>(
    FETCH_USER_PRESCRIPTION_COMPATIBLE_PROGRAMS(userId, prescriptionId),
    () =>
      PrescriptionsApi.listPrescriptionCompatiblePrograms(
        userId,
        prescriptionId
      )
  )

  return { ...query, programs: query.data }
}

/**
 * ----- Mutations -----
 */

export const useCreatePrescription = (userId: string) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (data: IPrescriptionCreationBody) =>
      PrescriptionsApi.createPrescription(userId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_USER_PRESCRIPTIONS(userId))
        queryClient.invalidateQueries(FETCH_USER_PROGRAMS(userId))
      },
      onError: (err) => {
        logger.error('Error creating a prescription', err)
      }
    }
  )

  return { ...mutation, createPrescription: mutation.mutate }
}

export const useUpdatePrescriptionDosage = (
  userId: string,
  prescriptionId: string
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (data: IPrescriptionDosageUpdateBody) =>
      PrescriptionsApi.updatePrescriptionDosage(userId, prescriptionId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_USER_PRESCRIPTIONS(userId))
        queryClient.invalidateQueries(FETCH_USER_PROGRAMS(userId))
      },
      onError: (err) => {
        logger.error('Error updating this prescription', err)
      }
    }
  )

  return { ...mutation, updatePrescriptionDosage: mutation.mutate }
}

export const useUpdatePrescriptionRefill = (
  userId: string,
  prescriptionId: string
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (data: IPrescriptionRefillUpdateBody) =>
      PrescriptionsApi.updatePrescriptionRefill(userId, prescriptionId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_USER_PRESCRIPTIONS(userId))
      },
      onError: (err) => {
        logger.error('Error updating this prescriptions refills', err)
      }
    }
  )

  return { ...mutation, updatePrescriptionRefill: mutation.mutate }
}

export const useAddPrescriptionProgramMembership = (
  userId: string,
  prescriptionId: string
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (programId: string) =>
      PrescriptionsApi.addPrescriptionProgramMembership(
        userId,
        prescriptionId,
        programId
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_USER_PRESCRIPTIONS(userId))
        queryClient.invalidateQueries(FETCH_USER_PROGRAMS(userId))
      },
      onError: (err) => {
        logger.error('Unable to add program membership to prescription', err)
      }
    }
  )

  return { ...mutation, addProgramMembership: mutation.mutate }
}

export const useDeletePrescription = (userId: string) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (prescriptionId: string) =>
      PrescriptionsApi.deletePrescription(userId, prescriptionId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_USER_PRESCRIPTIONS(userId))
        queryClient.invalidateQueries(FETCH_USER_PROGRAMS(userId))
      },
      onError: (err) => {
        logger.error('Error deleting this prescription', err)
      }
    }
  )

  return { ...mutation, deletePrescription: mutation.mutate }
}

export const useDeletePrescriptionProgramMembership = (
  userId: string,
  prescriptionId: string
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (programId: string) =>
      PrescriptionsApi.deletePrescriptionMembership(
        userId,
        prescriptionId,
        programId
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_USER_PRESCRIPTIONS(userId))
        queryClient.invalidateQueries(FETCH_USER_PROGRAMS(userId))
      },
      onError: (err) => {
        logger.error('Error deleting this program membership', err)
      }
    }
  )

  return { ...mutation, deleteProgramMembership: mutation.mutate }
}

export const useUpdatePrescriptionVerificationImage = (
  userId: string,
  prescriptionId: string
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (imageKey: string) =>
      PrescriptionsApi.updatePrescriptionImage(
        userId,
        prescriptionId,
        imageKey
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_USER_PRESCRIPTIONS(userId))
        queryClient.invalidateQueries('/verification-tasks')
      },
      onError: (err) => {
        logger.error('Error updating this prescription image', err)
      }
    }
  )

  return { ...mutation, updateImage: mutation.mutate }
}
