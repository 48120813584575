import { IconButton, Tooltip } from '@material-ui/core'
import { LooksOne, LooksOneOutlined } from '@material-ui/icons'
import { useHandleConnections } from '@xyflow/react'
import { useModuleFormContext } from 'src/contexts/ModuleForm'

interface IFirstTaskButton {
  taskId: string
}

const FirstTaskButton = ({ taskId }: IFirstTaskButton) => {
  /**
   * ----- Hook Initialization -----
   */

  const connections = useHandleConnections({
    type: 'target',
    nodeId: taskId
  })

  const { moduleFormData, updateFirstTask } = useModuleFormContext()

  /**
   * ----- Render -----
   */

  return connections.length === 0 ? (
    <Tooltip title="Set as first task">
      <IconButton onClick={() => updateFirstTask(taskId)}>
        {moduleFormData.firstTask === taskId ? (
          <LooksOne />
        ) : (
          <LooksOneOutlined />
        )}
      </IconButton>
    </Tooltip>
  ) : null
}

export default FirstTaskButton
