import React from 'react'

import { Text } from 'src/shared/styled'
import HoverToEdit from './HoverToEdit'
import AddTextSection from './AddTextSection'
import { ILMSTextSection } from 'src/models/learning'
import { useLMS } from 'src/contexts/Learning'

interface IDisplayTextSection {
  section: ILMSTextSection
  pageId: string
}

const { LMSTextSectionDisplay } = Text

const DisplayTextSection: React.FC<IDisplayTextSection> = ({
  section,
  pageId
}) => {
  const { removeSection } = useLMS()
  const [isEditingText, setIsEditingText] = React.useState(() => {
    if (section.text) {
      return false
    } else {
      return true
    }
  })
  return (
    <div>
      {isEditingText ? (
        <AddTextSection
          cancelEditText={() => setIsEditingText(false)}
          section={section}
          pageId={pageId}
        />
      ) : (
        <div className="hover-to-edit-anchor">
          <LMSTextSectionDisplay>{section.text}</LMSTextSectionDisplay>
          <HoverToEdit
            onEditClick={() => setIsEditingText(true)}
            onDeleteClick={() => removeSection(pageId, section.id)}
          />
        </div>
      )}
    </div>
  )
}

export default DisplayTextSection
