import { FC } from 'react'
import styled from 'styled-components'

import { COLORS, FONTS } from '../theme'

const Notification = styled.div`
  height: 35px;
  background-color: ${COLORS.anakiwa};
  color: ${(props) => props.theme.palette.common.black};
  font-family: ${FONTS.main};
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
`
interface WarningNotificationProps {
  message?: string
}
const WarningNotification: FC<WarningNotificationProps> = ({ message }) => {
  return (
    <Notification>
      {message ??
        'You have unsaved changes. You must complete program setup in order to publish a new program.'}
    </Notification>
  )
}

export default WarningNotification
