import { useHistory, useParams } from 'react-router-dom'

import { UserAdminMatchParams } from 'src/models/routeParams'
import { useUpdateUser, useUser } from 'src/shared/hooks/users'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import UserInfoView from '../views/UserInfo'

const UserInfo = () => {
  const { id: userId } = useParams<UserAdminMatchParams>()
  const history = useHistory()

  const { data, isLoading, error } = useUser(userId)

  const updateUser = useUpdateUser(userId, {
    onSuccess: () => history.push('/users')
  })

  if (error) return <p>There was an error fetching this user</p>
  if (isLoading || !data) return <LoadingScreen />

  const defaultValues = {
    ...data,
    city: data.address?.city,
    street: data.address?.street,
    unit: data.address?.unit,
    province: data.address?.province,
    postalCode: data.address?.postalCode
  }

  return (
    <UserInfoView
      updateUserMutation={updateUser}
      user={defaultValues}
      userId={userId}
    />
  )
}

export default UserInfo
