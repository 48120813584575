import { useClaims } from 'src/shared/hooks/claims'
import { SubHeaderName } from 'src/shared/styled/Text'
import { ToolsViewContainer } from 'src/shared/styled/Tools'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import ClaimsView from '../views/Claims'

const Claims = () => {
  const { isLoading, error, data } = useClaims()

  if (error) return <div>An error has occured</div>
  if (!isLoading && data) {
    return (
      <ToolsViewContainer>
        <SubHeaderName>Claims</SubHeaderName>
        <ClaimsView claims={data} />
      </ToolsViewContainer>
    )
  }

  return <LoadingScreen />
}

export default Claims
