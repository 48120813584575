import VerificationTable from 'src/components/VerificationTable'

const VerificationView = () => {
  /**
   * ----- Render -----
   */

  return <VerificationTable />
}

export default VerificationView
