import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { UserAdminMatchParams } from 'src/models/routeParams'
import { useUpdatePrescriptionDosage } from 'src/shared/hooks/prescription'
import styled from 'styled-components'
import TextField from 'src/shared/views/TextField'
import { DropDown } from 'src/shared/views/DropDown'
import {
  DAYS_OF_WEEK_AUTOCOMPLETE_OPTIONS,
  DOSES_PER_DAY_DROPDOWN_ITEMS,
  FREQUENCY_DROPDOWN_ITEMS,
  INTERVAL_DAYS_DROPDOWN_ITEMS
} from 'src/constants/prescriptions'
import {
  FrequencyEnum,
  IPrescription,
  IPrescriptionDosageUpdateForm
} from 'src/models/prescription'
import AutoComplete from 'src/shared/views/AutoComplete'
import { hasTimeDuplication } from 'src/utils/validation'
import { DetailsContent } from 'src/shared/styled/Layout'
import { capitalize } from 'lodash'
import { toast } from 'react-toastify'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import { generateDosingArray } from 'src/utils/prescription'

interface IPrescriptionDosageForm {
  prescription: IPrescription
  canCurrentUserWrite: boolean
  setPrescription: React.Dispatch<
    React.SetStateAction<IPrescription | undefined>
  >
}

const DoseTimesRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: 'space-between';
`

const FormFieldsRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const ScheduleRow = styled.div`
  display: flex;
`

const HiddenDiv = styled.div``

const formFieldStyles = {
  marginBottom: '30px',
  marginRight: '0',
  minWidth: '200px',
  width: '50%',
  maxWidth: '230px'
}

const scheduleFieldStyles = { ...formFieldStyles, marginRight: '47px' }

const PrescriptionDosageForm: FC<IPrescriptionDosageForm> = ({
  prescription,
  canCurrentUserWrite,
  setPrescription
}) => {
  /**
   * ----- Hook Initialization -----
   */

  const { id: userId } = useParams<UserAdminMatchParams>()
  const {
    updatePrescriptionDosage,
    isLoading,
    isSuccess,
    isError,
    error,
    data
  } = useUpdatePrescriptionDosage(userId, prescription.prescriptionId)

  const defaultValues: IPrescriptionDosageUpdateForm = React.useMemo(() => {
    const prescriptionTimes =
      prescription.dosing &&
      prescription.dosing?.length > 0 &&
      prescription.dosing[0]?.times

    let frequency: IPrescriptionDosageUpdateForm['frequency'] =
      FrequencyEnum.Daily
    let dosesPerDay: IPrescriptionDosageUpdateForm['dosesPerDay'] = 0
    let everyNthDay: IPrescriptionDosageUpdateForm['everyNthDay']
    let daysOfWeek: IPrescriptionDosageUpdateForm['daysOfWeek']
    let doseTimes: IPrescriptionDosageUpdateForm['doseTimes']
    if (prescriptionTimes) {
      dosesPerDay = prescriptionTimes.length

      if (prescription.dosing) {
        if (prescription.dosing[0]?.everyNthDay) {
          frequency = FrequencyEnum.Interval
          everyNthDay = prescription.dosing[0].everyNthDay
        } else if (prescription.dosing.length === 7) {
          frequency = FrequencyEnum.Daily
        } else {
          frequency = FrequencyEnum.Specific
        }

        if (
          prescription.dosing[0]?.dayOfWeek &&
          prescription.dosing.length !== 7
        ) {
          daysOfWeek = prescription.dosing.map((ds) =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ({ value: capitalize(ds.dayOfWeek!.toLowerCase()) })
          )
        }
      }

      doseTimes = prescriptionTimes.map((t) => t.substring(0, 5))
    }

    return {
      intakeInstructions: prescription.intakeInstructions,
      unitsPerDose: prescription.unitsPerDose,
      frequency,
      dosesPerDay,
      everyNthDay,
      daysOfWeek,
      doseTimes
    }
  }, [prescription])

  const { control, setValue, watch, handleSubmit } =
    useForm<IPrescriptionDosageUpdateForm>({ defaultValues })

  const frequency = watch('frequency')
  const doseTimes = watch('doseTimes')
  const dosesPerDay = watch('dosesPerDay')

  /**
   * ----- Functions -----
   */

  const setDoseTime = React.useCallback(
    (time: string, i: number) => {
      const existingValues = doseTimes ? [...doseTimes] : []
      existingValues[i] = time

      setValue('doseTimes', existingValues)
    },
    [doseTimes, setValue]
  )

  const submitForm = (values: IPrescriptionDosageUpdateForm) => {
    updatePrescriptionDosage({
      unitsPerDose: values.unitsPerDose,
      dosing: generateDosingArray(
        frequency,
        dosesPerDay,
        doseTimes,
        values.daysOfWeek,
        values.everyNthDay
      )
    })
  }

  /**
   * ----- Variables -----
   */

  const renderedDoseTimes = React.useMemo(() => {
    const renderedDoseTimes = []
    if (!!dosesPerDay && dosesPerDay > 0) {
      for (let i = 0; i < dosesPerDay; i++) {
        renderedDoseTimes.push(
          <TextField
            key={i}
            style={{ width: '155px', marginBottom: '30px' }}
            name={`doseTimes[${i}]`}
            label={`Dose ${i + 1}`}
            value={doseTimes && doseTimes[i]}
            InputLabelProps={{ shrink: true }}
            type="time"
            placeholder="ex. 10:00am"
            onChange={(e) => setDoseTime(e.target.value, i)}
            disabled={!canCurrentUserWrite}
          />
        )
      }
    }
    return renderedDoseTimes
  }, [canCurrentUserWrite, doseTimes, dosesPerDay, setDoseTime])

  React.useEffect(() => {
    if (!isLoading && isSuccess) {
      toast.success('Successfully updated prescription dosage')
      setPrescription(data)
    } else if (!isLoading && isError) {
      toast.error(`${error}`)
    }
  }, [isLoading, isSuccess, isError, error, data, setPrescription])

  return (
    <DetailsContent>
      <FormFieldsRow>
        <TextField
          disabled
          style={formFieldStyles}
          type="date"
          label="Date Medication Started"
          InputLabelProps={{ shrink: true }}
          value={prescription.startedOn || null}
        />
        <Controller
          defaultValue={undefined}
          render={({ field: { ref, ...props } }) => (
            <DropDown
              items={FREQUENCY_DROPDOWN_ITEMS}
              label="How often is it taken?"
              style={formFieldStyles}
              inputRef={ref}
              {...props}
              disabled={!canCurrentUserWrite}
            />
          )}
          control={control}
          name="frequency"
        />
        <Controller
          defaultValue={1}
          render={({ field: { ref, ...props } }) => (
            <TextField
              label="How many units per dose?"
              style={formFieldStyles}
              type="number"
              inputRef={ref}
              {...props}
              disabled={!canCurrentUserWrite}
              noNegative
            />
          )}
          control={control}
          name="unitsPerDose"
        />
      </FormFieldsRow>
      <ScheduleRow>
        <Controller
          defaultValue={1}
          render={({ field: { ref, ...props } }) => (
            <>
              {frequency && (
                <DropDown
                  items={DOSES_PER_DAY_DROPDOWN_ITEMS}
                  label="How many times a day?"
                  style={scheduleFieldStyles}
                  inputRef={ref}
                  {...props}
                  disabled={!canCurrentUserWrite}
                />
              )}
            </>
          )}
          control={control}
          name="dosesPerDay"
        />
        <Controller
          defaultValue={[]}
          render={({ field: { ...props } }) => (
            <>
              {frequency === FrequencyEnum.Specific && (
                <AutoComplete
                  style={scheduleFieldStyles}
                  inputId="daysOfWeek"
                  label="Days of Week"
                  multiple
                  options={DAYS_OF_WEEK_AUTOCOMPLETE_OPTIONS}
                  {...props}
                  onChange={(_e, v) =>
                    setValue('daysOfWeek', [{ value: v[0] }])
                  }
                  disabled={!canCurrentUserWrite}
                />
              )}
            </>
          )}
          name="daysOfWeek"
          control={control}
        />
        <Controller
          defaultValue={1}
          render={({ field: { ...props } }) => (
            <>
              {frequency === FrequencyEnum.Interval && (
                <DropDown
                  style={scheduleFieldStyles}
                  label="How many interval days?"
                  items={INTERVAL_DAYS_DROPDOWN_ITEMS}
                  {...props}
                  disabled={!canCurrentUserWrite}
                />
              )}
            </>
          )}
          name="everyNthDay"
          control={control}
        />
      </ScheduleRow>
      <Controller
        render={() => <HiddenDiv />}
        name="doseTimes"
        control={control}
        defaultValue={[]}
      />
      <DoseTimesRow>
        {renderedDoseTimes}

        {doseTimes &&
          dosesPerDay &&
          hasTimeDuplication(doseTimes, dosesPerDay) && (
            <p style={{ color: 'red' }}>Cannot have duplicate times</p>
          )}
      </DoseTimesRow>
      {canCurrentUserWrite && (
        <ButtonWithWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit(submitForm)}
          isLoading={isLoading}
        >
          Save
        </ButtonWithWidth>
      )}
    </DetailsContent>
  )
}

export default PrescriptionDosageForm
