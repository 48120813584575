import React from 'react'
import { Create } from '@material-ui/icons'
import { SummaryContainer } from 'src/shared/styled/Layout'
import { Heading } from 'src/shared/styled/Text'
import DrugSearch from 'src/features/userAdmin/containers/DrugSearch'
import { IDrugLibraryItem } from 'src/models/drugLibrary'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'
import {
  useProgramAddDins,
  useProgramRemoveDins
} from 'src/shared/hooks/program'
import styled from 'styled-components'
import { COLORS, FONTS } from 'src/shared/theme'
import { IProgram } from 'src/models/program'
import { useQueryClient } from 'react-query'
import { FETCH_PROGRAM } from 'src/constants/queries'

const DINItem = styled.span`
  color: ${COLORS.matterhorn};
  font-family: ${FONTS.main};
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 26px;
`

interface IDinUpdate {
  program: IProgram
}

const DinUpdate = ({ program }: IDinUpdate) => {
  /**
   * ----- Hook Initialization -----
   */

  const queryClient = useQueryClient()

  const [edit, setEdit] = React.useState(false)

  const [newDins, setNewDins] = React.useState<IDrugLibraryItem[]>([])

  const { addDins } = useProgramAddDins(program.id, {
    onSuccess: () => {
      queryClient.invalidateQueries(FETCH_PROGRAM(program.id))
      setEdit(false)
      setNewDins([])
    }
  })

  const { removeDins } = useProgramRemoveDins(program.id, {
    onSuccess: () => {
      queryClient.invalidateQueries(FETCH_PROGRAM(program.id))
      setEdit(false)
    }
  })

  /**
   * ----- Functions -----
   */

  const removeDin = React.useCallback(
    (din: IDrugLibraryItem) => {
      const index = newDins.findIndex(
        (item) => item.din.value === din.din.value
      )
      if (index !== -1) {
        const dinsCopy = JSON.parse(JSON.stringify(newDins))
        dinsCopy.splice(index, 1)
        setNewDins(dinsCopy)
      }
    },
    [newDins]
  )

  /**
   * ----- Render -----
   */

  const updateForm = React.useMemo(() => {
    if (edit) {
      return (
        <DrugSearch
          existingDins={program.coveredDins.map((din) => din.value)}
          selectedDins={newDins.map((din) => din.din.value)}
          onItemSelected={(item) => {
            setNewDins([...newDins, item])
          }}
          showSelectedDins
          onRemoveItem={(item) => removeDin(item)}
          dinsToDisplay={newDins}
          onRemoveExistingItem={(drug) => removeDins([drug.din])}
        />
      )
    } else return null
  }, [edit, newDins, program.coveredDins, removeDin, removeDins])

  return (
    <SummaryContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <Heading>DINs</Heading>
        <div>
          <Create
            onClick={() => setEdit(!edit)}
            style={{ cursor: 'pointer', marginLeft: '0.5em' }}
          />
          {newDins.length > 0 ? (
            <ButtonWithMargin
              onClick={() => {
                addDins(newDins.map((din) => din.din))
              }}
            >
              Save
            </ButtonWithMargin>
          ) : null}
        </div>
      </div>
      {program.coveredDins.map((drug, i) => {
        return (
          <DINItem key={drug.value}>
            {drug.value}
            {i !== program.coveredDins.length - 1 ? ', ' : null}
          </DINItem>
        )
      })}
      {updateForm}
    </SummaryContainer>
  )
}

export default DinUpdate
