import { FC } from 'react'
import {
  Box,
  Table as MaUTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core'
import {
  Column,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useSortBy,
  useTable,
  useRowSelect,
  useExpanded,
  TableState
} from 'react-table'
import styled from 'styled-components'
import { ArrowDownward, ArrowUpward, InfoOutlined } from '@material-ui/icons'

import { COLORS, FONTS } from '../../theme'
import FilterInput from '../FilterInput'
import { EditableAllCell } from './EditableAllCell'
import { StrategyAllCell } from './StrategyAllCell'
import { ActionsCell } from './ActionsCell'

const EmptyTableRow = styled(TableRow)`
  background: ${COLORS.lavender};
  width: '100%';
`

const EmptyTableCell = styled(TableCell)`
  text-align: center;
  font-family: ${FONTS.main};
  font-size: 16px;
`

const TableHeaderCell = styled(TableCell)`
  font-family: ${FONTS.medium};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.08px;
  line-height: 21.98px;
  color: rgba(0, 0, 0, 0.87);
`

const FocusedTableCell = styled(TableCell)`
  font-family: ${FONTS.main};
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.11px;
  line-height: 20px;
  & .MuiInput-root.Mui-focused {
    background: ${COLORS.white};
    border: ${(props) => `1px solid ${props.theme.palette.secondary.main}`};
  }
  & .MuiInput-root {
    && :hover {
      background: ${COLORS.turquoisePale};
    }
  }
  & .MuiTableCell-root:hover {
    color: 'red';
    background: ${COLORS.turquoisePale};
    && :hover {
      background: ${COLORS.turquoisePale};
    }
  }
`

const StyledTable = styled(MaUTable)`
  border: 1px solid ${COLORS.mercury};
  border-radius: 4px;
  margin-top: 2em;
  border-collapse: separate;
`

const StyledTooltip = styled((props) => (
  <Tooltip
    classes={{ popper: props.className, tooltip: 'tooltip', arrow: 'arrow' }}
    {...props}
  />
))`
  & .tooltip {
    background-color: ${COLORS.white};
    color: ${COLORS.black};
    box-shadow: 0 2px 10px 0 ${COLORS.darkGray};
    border-radius: 2px;
  }
  & .arrow {
    font-size: 20;
    color: ${COLORS.charcoal};
    width: 30px;
    &::before {
      background-color: ${COLORS.white};
      border: 2px solid ${COLORS.white};
    }
  }
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export type TableProps = {
  columns: Column[]
  data: any
  placeholder?: string
  emptyMessage?: string
  getHeaderProps?: (value: any) => Record<string, unknown>
  getColumnProps?: (value: any) => Record<string, unknown>
  getRowProps?: (value: any) => Record<string, unknown>
  getCellProps?: (value: any) => Record<string, unknown>
  skipPageReset?: boolean
  editAllCells?: boolean
  user?: boolean
  strategy?: string
  updateMyData?: (rowIndex: number, columnId: any, value: any) => void
  userAdmin?: boolean
  additionalElements?: React.ReactNode
  initialState?: Partial<TableState>
}

// Create a default prop getter
const defaultPropGetter = () => ({})

const Table: FC<TableProps> = ({
  columns,
  data,
  placeholder,
  emptyMessage,
  getRowProps = defaultPropGetter,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  updateMyData,
  editAllCells,
  strategy,
  skipPageReset,
  user,
  userAdmin,
  additionalElements,
  initialState
}) => {
  let defaultColumn
  if (editAllCells) {
    defaultColumn = {
      Cell: strategy === 'yes' ? StrategyAllCell : EditableAllCell
    }
  }
  if (user) {
    defaultColumn = {
      Cell: ActionsCell
    }
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { globalFilter },
    setGlobalFilter
  } = useTable(
    {
      columns,
      data: data || [],
      // @ts-expect-error - idk
      defaultColumn,
      autoResetPage: !skipPageReset,
      updateMyData,
      strategy,
      initialState: { ...initialState }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout,
    useExpanded,
    useRowSelect
  )

  return (
    <>
      <TopContainer>
        {placeholder && (
          <FilterInput
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            placeholder={placeholder}
          />
        )}
        {additionalElements}
      </TopContainer>
      <StyledTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeaderCell
                  {...column.getHeaderProps([
                    getColumnProps(column),
                    getHeaderProps(column),
                    column.getSortByToggleProps()
                  ])}
                >
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <div style={{ display: 'flex' }}>
                        <ArrowDownward />
                        {column.render('Header')}
                      </div>
                    ) : (
                      <div style={{ display: 'flex' }}>
                        <ArrowUpward />
                        {column.render('Header')}
                      </div>
                    )
                  ) : (
                    <div>{column.render('Header')}</div>
                  )}
                  {column.Header === 'Status' && userAdmin && (
                    <StyledTooltip
                      title={
                        <>
                          <Typography component={'div'}>
                            <Box display="inline" fontWeight="fontWeightBold">
                              Active -{' '}
                            </Box>
                            User has been active within the last 30 days.
                          </Typography>
                          <Typography component={'div'}>
                            <Box display="inline" fontWeight="fontWeightBold">
                              Inactive -{' '}
                            </Box>
                            No user activity within the last 30 days.
                          </Typography>
                          <Typography component={'div'}>
                            <Box display="inline" fontWeight="fontWeightBold">
                              Blocked -{' '}
                            </Box>
                            User account is disabled.
                          </Typography>
                        </>
                      }
                      arrow
                    >
                      <InfoOutlined />
                    </StyledTooltip>
                  )}
                  {column.Header === 'Late Definition' && (
                    <StyledTooltip
                      title={
                        <Typography component={'div'}>
                          Number of seconds after medication time that is
                          &lsquo;late&rsquo;
                        </Typography>
                      }
                      arrow
                    >
                      <InfoOutlined />
                    </StyledTooltip>
                  )}
                </TableHeaderCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.length > 0 ? (
            rows.map((row, _i) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map((cell) => {
                    return (
                      <FocusedTableCell
                        {...cell.getCellProps([
                          getColumnProps(cell.column),
                          getCellProps(cell)
                        ])}
                      >
                        {cell.render('Cell')}
                      </FocusedTableCell>
                    )
                  })}
                </TableRow>
              )
            })
          ) : (
            <EmptyTableRow>
              <EmptyTableCell colSpan={visibleColumns.length}>
                {emptyMessage}
              </EmptyTableCell>
            </EmptyTableRow>
          )}
        </TableBody>
      </StyledTable>
    </>
  )
}

export default Table
