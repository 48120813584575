import React from 'react'
import { Controller, useForm } from 'react-hook-form'

import {
  IPrescription,
  IPrescriptionGeneralForm
} from 'src/models/prescription'
import { DetailsContent } from 'src/shared/styled/Layout'
import styled from 'styled-components'
import TextField from 'src/shared/views/TextField'
import AutoComplete from 'src/shared/views/AutoComplete'
import { BoldHeading } from 'src/shared/styled/Text'
import { Divider } from '@material-ui/core'
import { downloadImage } from 'src/api/services/amplify'

interface IPrescriptionGeneralFormProps {
  prescription?: IPrescription
  canCurrentUserWrite: boolean
  setPrescription: React.Dispatch<
    React.SetStateAction<IPrescription | undefined>
  >
}

const FormFieldsRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const MarginDivider = styled(Divider)`
  margin: 1em 0 2em 0;
`

const formFieldStyles = {
  marginBottom: '30px',
  marginRight: '0',
  minWidth: '200px',
  width: '50%',
  maxWidth: '230px'
}

const HeadingTitle = styled(BoldHeading)`
  margin-bottom: 30px;
`

const PrescriptionImage = styled.img`
  max-width: 50%;
  max-height: 50%;
`

const PrescriptionGeneralForm: React.FC<IPrescriptionGeneralFormProps> = ({
  prescription
}) => {
  const [imageUrl, setImageUrl] = React.useState<string>()
  const { control } = useForm<IPrescriptionGeneralForm>({
    defaultValues: {
      ...prescription,
      condition: { value: prescription?.condition }
    }
  })

  const getImage = React.useCallback(async () => {
    if (prescription?.imageUrl) {
      return await downloadImage(prescription.imageUrl)
    } else return null
  }, [prescription])

  React.useEffect(() => {
    const fetchImage = async () => {
      const url = await getImage()
      if (url) setImageUrl(url.toString())
    }
    fetchImage()
  }, [getImage])

  return (
    <DetailsContent>
      <HeadingTitle>Details</HeadingTitle>
      <FormFieldsRow>
        <Controller
          defaultValue={prescription?.medicationName}
          render={({ field: { ref, ...props } }) => (
            <TextField
              style={formFieldStyles}
              label="Medication Name"
              inputRef={ref}
              placeholder="Ex. Gabapentin"
              InputLabelProps={{ shrink: true }}
              {...props}
              disabled
            />
          )}
          name="medicationName"
          control={control}
        />
        <Controller
          defaultValue={prescription?.din}
          render={({ field: { ref, ...props } }) => (
            <TextField
              style={formFieldStyles}
              label="DIN"
              placeholder="Ex. 012345678"
              inputRef={ref}
              InputLabelProps={{ shrink: true }}
              {...props}
              disabled
            />
          )}
          name="din"
          control={control}
        />
        <Controller
          defaultValue={prescription?.unitType}
          render={({ field: { ref, ...props } }) => (
            <TextField
              style={formFieldStyles}
              label="Appearance"
              placeholder="Ex. Capsule"
              inputRef={ref}
              InputLabelProps={{ shrink: true }}
              {...props}
              disabled
            />
          )}
          name="unitType"
          control={control}
        />
      </FormFieldsRow>
      <FormFieldsRow>
        <Controller
          defaultValue={prescription?.doseStrength}
          render={({ field: { ref, ...props } }) => (
            <TextField
              style={formFieldStyles}
              label="Dose Strength"
              placeholder="Ex. 120 mg"
              inputRef={ref}
              InputLabelProps={{ shrink: true }}
              {...props}
              disabled
            />
          )}
          name="doseStrength"
          control={control}
        />
      </FormFieldsRow>
      <MarginDivider />
      <FormFieldsRow>
        <Controller
          defaultValue={{ value: '' }}
          render={({ field: { ...props } }) => (
            <AutoComplete
              style={formFieldStyles}
              inputId="condition"
              label="Condition"
              options={[]}
              {...props}
              disabled
            />
          )}
          name="condition"
          control={control}
        />
      </FormFieldsRow>
      <MarginDivider />
      {imageUrl && <PrescriptionImage src={imageUrl} />}
    </DetailsContent>
  )
}

export default PrescriptionGeneralForm
