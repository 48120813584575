import React from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'

import LogoImg from '../../../assets/images/logo.png'
import Button from '../../../shared/views/Button'
import { ReactComponent as GoogleIcon } from '../../../assets/images/googleIcon.svg'
import { COLORS } from '../../../shared/theme'

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.palette.primary.main};
`

const Main = styled.div`
  height: 100vh;
`

const ContentWrapper = styled(Box)`
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`

const Content = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 95px 68px;
  border-radius: 5px;
`

const LoginButton = styled(Button)`
  background-color: ${COLORS.royalBlue};
`

const IconWrap = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`

const Logo = styled.img`
  width: 264px;
  display: flex;
  justify-self: center;
  margin-bottom: 189px;
`

type Props = {
  signIn: () => void
}

const Login: React.FC<Props> = ({ signIn }: Props) => {
  return (
    <Wrapper>
      <Main>
        <ContentWrapper>
          <Content>
            <Logo src={LogoImg} alt="logo" />
            <LoginButton onClick={signIn}>
              <IconWrap>
                <GoogleIcon />
                <span style={{ marginLeft: '20px', fontSize: '15px' }}>
                  Sign In with Google
                </span>
              </IconWrap>
            </LoginButton>
          </Content>
        </ContentWrapper>
      </Main>
    </Wrapper>
  )
}

export default Login
