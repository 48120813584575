import { sendSentryError } from 'src/api/services/sentry'

/** Logger
 *
 * Provides all the different sorts of logging
 * error debug query mutation analytics
 *
 */

const isDev = process.env.NODE_ENV === 'development'

export const logger = {
  debug: (...args: any) => {
    if (isDev) {
      const textArg: string = args[0]
      console.log(`DEBUG ====> ${textArg}`)
      if (args[1]) {
        for (const arg of args) {
          if (arg !== textArg) {
            console.log('DEBUG DATA ====>', arg)
          }
        }
      }
    }
  },
  error: (text: string, error: any) => {
    if (isDev) {
      console.warn(`ERROR ====> ${text}`, error)
    } else {
      sendSentryError(text, error)
    }
  }
}
