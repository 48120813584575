import { createMuiTheme } from '@material-ui/core/styles'

export const COLORS = {
  dodgerBlueLight: '#439cfc',
  prussianBlue: '#003E7F',
  prussianBlueOpaque: 'rgba(0, 62, 127, 0.04)',
  prussianBlueDarker: '#003165',
  midnightBlue: '#023164',
  pacificBlue: '#00A0DE',
  dodgerBlue: '#318AF5',
  dodgerBlueLighter: '#2196F3',
  royalBlue: '#1B73E8',
  blueBayoux: '#516B86',
  blizzardBlue: '#9BD9E9',
  cornFlower: '#9AD9E9',
  anakiwa: '#86D3E5',
  white: '#ffffff',
  black: '#000000',
  alto: '#E0E0E0',
  lavender: '#EDF9FB',
  botticelli: '#D9E2EC',
  mercury: '#E1E1E1',
  gainsboro: '#D9D9D9',
  cerulean: '#0092DA',
  matterhorn: '#505050',
  alabaster: '#fafafa',
  darkGray: '#aaaaaa',
  doveGray: '#6c6c6c',
  borderGray: 'rgba(0, 0, 0, 0.23)',
  turquoisePale: '#ceedf4',
  charcoal: '#4a4a4a',
  guardsmanRed: '#C70008',
  empress: '#737373',
  lightRed: '#FFCCCB',
  warningRed: '#f44336',
  orange200: '#fed7aa',
  orange800: '#9a3412',
  slate: {
    100: '#f1f5f9',
    200: '#e2e8f0',
    300: '#cbd5e1',
    400: '#94a3b8'
  },
  blue: {
    400: '#60a5fa'
  }
}

export const FONTS = {
  main: 'Gotham Book',
  medium: 'Gotham Medium',
  bold: 'Gotham Bold'
}

const theme = createMuiTheme({
  palette: {
    primary: {
      light: COLORS.dodgerBlueLight,
      main: COLORS.prussianBlue,
      dark: COLORS.prussianBlueDarker,
      contrastText: COLORS.white
    },
    secondary: {
      light: COLORS.dodgerBlue,
      main: COLORS.cerulean,
      dark: COLORS.royalBlue,
      contrastText: COLORS.white
    },
    common: {
      white: COLORS.white,
      black: COLORS.black
    }
  },
  typography: {
    fontFamily: FONTS.main
  },
  overrides: {
    MuiButton: {
      contained: {
        backgroundColor: COLORS.doveGray,
        color: COLORS.white,
        '&:disabled': {
          backgroundColor: COLORS.gainsboro,
          color: COLORS.black
        }
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: COLORS.dodgerBlueLighter
        }
      },
      outlined: {
        backgroundColor: COLORS.alabaster,
        '&:hover': {
          backgroundColor: COLORS.prussianBlueOpaque
        }
      }
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: COLORS.pacificBlue
        },
        '&$active': {
          color: COLORS.pacificBlue
        },
        color: 'rgba(0,0,0,0.38)'
      },
      text: {
        fill: COLORS.white,
        fontFamily: FONTS.main,
        fontSize: '12px',
        fontWeight: 300,
        letterSpacing: '0.3px',
        lineHeight: '14px',
        textAlign: 'center'
      },
      active: {},
      completed: {}
    },
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: '#E4E7EB'
      },
      colorSecondary: {
        '&$checked': {
          // Controls checked color for the thumb
          color: COLORS.dodgerBlueLighter
        }
      },
      track: {
        // Controls default (unchecked) color for the track
        backgroundColor: 'rgba(18, 22, 27, 0.2)',
        '$checked$checked + &': {
          // Controls checked color for the track
          backgroundColor: COLORS.cerulean
        }
      }
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          '&$focused': {
            color: COLORS.black
          }
        }
      }
    },
    MuiTableRow: {
      root: {
        background: COLORS.lavender,
        '&:nth-of-type(even)': {
          background: COLORS.botticelli
        }
      }
    }
  }
})

export default theme
