import React from 'react'

import { Container, Typography } from '@material-ui/core'
import { IUserClaim, UserClaimStatus } from 'src/models/user'
import { DropDown } from 'src/shared/views/DropDown'
import ImageDisplay from 'src/shared/views/ImageDisplay'
import { momentFormat } from 'src/utils/dates'
import styled from 'styled-components'
import { useUpdateClaimStatus } from 'src/shared/hooks/users'
import { toast } from 'react-toastify'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: top;
  margin-bottom: 0.5rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const FormRow = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const Header = styled.h2`
  margin-top: 0;
  margin-right: 1em;
`

const Text = styled(Typography)`
  margin-top: 0.25rem;
`

const StyledDropDown = styled(DropDown)`
  min-width: 50%;
`

const UserLink = styled.a`
  display: grid;
`

interface IClaimDetails {
  claim: IUserClaim
  userId: string
}

const ClaimDetails = ({ claim, userId }: IClaimDetails) => {
  const [status, setStatus] = React.useState<UserClaimStatus>(claim.status)

  const { updateClaimStatus } = useUpdateClaimStatus(userId, claim.claimId, {
    onSuccess: () => {
      toast.success('Status successfully updated')
    },
    onError: () => {
      toast.error('Unable to update status')
    }
  })

  const paymentMethod = React.useMemo(() => {
    switch (claim.paymentMethod?.type) {
      case 'DirectDepositInfo': {
        return (
          <ContentContainer>
            <Header>Direct Deposit</Header>
            <Column>
              <Text>Institution - {claim.paymentMethod.institutionNumber}</Text>
              <Text>Transit - {claim.paymentMethod.transitNumber}</Text>
              <Text>Account - {claim.paymentMethod.accountNumber}</Text>
            </Column>
          </ContentContainer>
        )
      }
      case 'ETransferEmail': {
        return (
          <ContentContainer>
            <Header>E-Transfer</Header>
            <Column>
              <Text>
                Full name - {claim.lastName}, {claim.firstName}
              </Text>
              <Text>Email - {claim.paymentMethod.email}</Text>
            </Column>
          </ContentContainer>
        )
      }
      case 'DirectDepositAccountId': {
        return (
          <ContentContainer>
            <Header>Direct Deposit Id</Header>
            <Column>
              <i>
                Something probably went wrong, please report if this is seen
              </i>
              <Text>ID - {claim.paymentMethod.id}</Text>
            </Column>
          </ContentContainer>
        )
      }
      default: {
        return (
          <ContentContainer>
            <Header>Payment</Header>
            <Column>
              <i>Unable to find payment information</i>
            </Column>
          </ContentContainer>
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claim.firstName, claim.lastName, claim.paymentMethod?.type])

  return (
    <Container>
      <ContentContainer>
        <Header>Status</Header>
        <FormRow
          onSubmit={(e) => {
            e.preventDefault()
            updateClaimStatus(status)
          }}
        >
          <StyledDropDown
            onChange={(e) => {
              setStatus(e.target.value as UserClaimStatus)
            }}
            items={[
              { label: 'Submitted', value: UserClaimStatus.Submit },
              {
                label: 'Pending',
                value: UserClaimStatus.Pending
              },
              {
                label: 'Pending Direct Deposit Approval',
                value: UserClaimStatus.PendingDirectDepositApproval
              },
              {
                label: 'Pending Verification',
                value: UserClaimStatus.PendingVerification
              },
              {
                label: 'Pending Program Completion',
                value: UserClaimStatus.PendingProgramCompletion
              },
              {
                label: 'Resolved',
                value: UserClaimStatus.Resolved
              },
              {
                label: 'Rejected',
                value: UserClaimStatus.Rejected
              },
              {
                label: 'Deleted',
                value: UserClaimStatus.Deleted
              }
            ]}
            value={status}
          />
          <ButtonWithWidth type="submit">Submit</ButtonWithWidth>
        </FormRow>
      </ContentContainer>
      <ContentContainer>
        <Header>Notes</Header>
        <Text>{claim.notes}</Text>
      </ContentContainer>
      <ContentContainer>
        <Header>Amount</Header>
        <Text>
          {claim.amount.amount} {claim.amount.currencyCode}
        </Text>
      </ContentContainer>
      {claim.programName ? (
        <ContentContainer>
          <Header>Program</Header>
          <Text>{claim.programName}</Text>
        </ContentContainer>
      ) : null}
      {claim.certificate &&
        claim.certificate.certificateNumber &&
        claim.certificate.planNumber &&
        claim.certificate.policyNumber && (
          <ContentContainer>
            <Header>Certificate</Header>
            <Column>
              <Text>
                Certificate Number - {claim.certificate.certificateNumber}
              </Text>
              <Text>Policy - {claim.certificate.policyNumber}</Text>
              <Text>Plan - {claim.certificate.planNumber}</Text>
            </Column>
          </ContentContainer>
        )}
      {paymentMethod}
      {claim.attestationContactEmail && (
        <ContentContainer>
          <Header>Contact Email</Header>
          <Text>{claim.attestationContactEmail}</Text>
        </ContentContainer>
      )}
      <ContentContainer>
        <Header>Created At</Header>
        <Text>
          {momentFormat(new Date(claim.createdAt), 'MM-D-YYYY, h:mm a')}
        </Text>
      </ContentContainer>
      {claim.usersUsingSameEmail && claim.usersUsingSameEmail.length > 0 && (
        <ContentContainer>
          <Header>Users with the same E-transfer Email</Header>
          <Column>
            {claim.usersUsingSameEmail.map((user, index) => (
              <UserLink
                href={`/users/${user.userId}`}
                target="_blank"
                key={index}
              >
                <Text>
                  {user.firstName} {user.lastName}
                </Text>
              </UserLink>
            ))}
          </Column>
        </ContentContainer>
      )}
      <ContentContainer>
        <Header>Image{claim.images.length > 1 ? 's' : ''}</Header>
        {claim.images.map((key, index) => (
          <ImageDisplay imageKey={key} key={index} />
        ))}
      </ContentContainer>
    </Container>
  )
}

export default ClaimDetails
