import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Button, Chip } from '@material-ui/core'
import { COLORS, FONTS } from '../theme'

export const StyledChip = styled(Chip)`
  color: ${(props) =>
    props.color === 'default' ? COLORS.black : COLORS.white};
  background-color: ${(props) =>
    props.color === 'primary'
      ? COLORS.dodgerBlueLighter
      : props.color === 'secondary'
      ? COLORS.matterhorn
      : COLORS.gainsboro};
  font-family: ${FONTS.medium};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.35px;
  line-height: 17px;
  text-align: center;
`
export const DetailsLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 16px;
  font-weight: 500;
  font-family: ${FONTS.medium};
  letter-spacing: 0.37px;
  line-height: 22px;
  text-align: center;
`
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2em;
`
export const AddUserButton = styled(Button)`
  font-family: ${FONTS.medium};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.34px;
  line-height: 24px;
  padding: 8px 22px;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
    color: ${(props) => props.theme.palette.common.white};
  }
`

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.common.white};
  text-decoration: none;
`
