import React from 'react'

import { StateContext } from 'src/contexts/Program'
import { Text, Layout } from 'src/shared/styled'
import LearningModules from '../views/LearningModules'

interface ILiteracyProps {
  programDuration: any
}

const { DetailsContainer, DetailsHeader, DetailsHeaderContainer } = Layout
const { HeaderName, SubHeaderName } = Text

const Literacy: React.FC<ILiteracyProps> = ({ programDuration }) => {
  const state = React.useContext(StateContext)

  const { name: programName } = state

  return (
    <DetailsContainer>
      <DetailsHeaderContainer>
        <DetailsHeader>
          <div>
            <HeaderName>{programName}</HeaderName>
            <SubHeaderName>Literacy</SubHeaderName>
          </div>
        </DetailsHeader>
      </DetailsHeaderContainer>
      <LearningModules programDuration={programDuration} />
    </DetailsContainer>
  )
}

export default Literacy
