import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { UserAdminMatchParams } from 'src/models/routeParams'
import { useBlockUser } from 'src/shared/hooks/users'
import BlockUserView from '../views/BlockUser'

interface IBlockUserContainer {
  closeModal: () => void
}

const BlockUserContainer: FC<IBlockUserContainer> = ({ closeModal }) => {
  const { id: userId } = useParams<UserAdminMatchParams>()

  const blockUser = useBlockUser(userId, { onSuccess: () => closeModal })

  return <BlockUserView closeModal={closeModal} blockUserMutation={blockUser} />
}

export default BlockUserContainer
