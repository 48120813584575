import { ArrowDownward, ArrowUpward } from '@material-ui/icons'
import React from 'react'

import { IUserClaim } from 'src/models/user'
import Modal from 'src/shared/views/Modal'
import Tooltip from 'src/shared/views/Tooltip'
import { momentFormat } from 'src/utils/dates'
import truncateString from 'src/utils/truncateString'
import styled from 'styled-components'
import ClaimDetails from './ClaimDetails'
import {
  CellContext,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import {
  FocusedTableCell,
  StyledTable,
  TableHeaderCell
} from 'src/shared/styled/Table'
import { Chip, TableRow } from '@material-ui/core'
import { fuzzyFilter } from 'src/utils/table'
import DebouncedInput from 'src/components/DebounceInput'
import { COLORS } from 'src/shared/theme'

const Container = styled.div`
  padding: 1em;
`

const UserLink = styled.a`
  display: flex;
`

const HoverableTableRow = styled(TableRow)`
  &:hover {
    background-color: ${COLORS.blizzardBlue};
    cursor: pointer;
  }
`

interface IClaimsTable {
  claims: IUserClaim[]
  hideName?: boolean
}

const ClaimsTable = ({ claims, hideName = false }: IClaimsTable) => {
  const [modalClaim, setModalClaim] = React.useState<IUserClaim>()

  const columns: ColumnDef<IUserClaim, any>[] = React.useMemo(() => {
    const columns: ColumnDef<IUserClaim, any>[] = [
      // Full name will go here if !hideName
      {
        header: 'Issues',
        cell: ({
          row
        }: React.PropsWithChildren<CellContext<IUserClaim, any>>) => {
          if (
            row.original.usersUsingSameEmail &&
            row.original.usersUsingSameEmail.length > 0
          ) {
            return (
              <Chip
                label={`${row.original.usersUsingSameEmail.length} matching email(s) found`}
                color="secondary"
                style={{ backgroundColor: COLORS.warningRed }}
                size="small"
              />
            )
          } else return ''
        }
      },
      {
        header: 'Amount ($)',
        accessorKey: 'amount.amount'
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: ({
          row
        }: React.PropsWithChildren<CellContext<IUserClaim, any>>) => {
          return (
            <Tooltip description={row.original.status}>
              {truncateString(row.original.status)}
            </Tooltip>
          )
        }
      },
      {
        header: 'Created At',
        accessorKey: 'createdAt',
        enableColumnFilter: false,
        cell: ({
          row
        }: React.PropsWithChildren<CellContext<IUserClaim, any>>) => {
          return (
            <div>
              {momentFormat(
                new Date(row.original.createdAt),
                'MM-D-YYYY, h:mm a'
              )}
            </div>
          )
        },
        sortingFn: 'datetime',
        enableSorting: true
      }
    ]

    if (!hideName)
      columns.unshift({
        header: 'Full Name',
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        cell: ({
          row
        }: React.PropsWithChildren<CellContext<IUserClaim, any>>) => {
          return (
            <UserLink target="_blank" href={`/users/${row.original.userId}`}>
              {row.original.lastName}, {row.original.firstName}
            </UserLink>
          )
        }
      })

    return columns
  }, [hideName])

  /**
   * --- Render ---
   */

  const table = useReactTable({
    columns,
    data: claims,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    initialState: {
      columnFilters: [
        {
          id: 'status',
          value: 'Submitted'
        }
      ],
      sorting: [{ id: 'createdAt', desc: true }]
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  })

  return (
    <Container>
      <StyledTable>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeaderCell
                  key={header.id}
                  style={{ width: header.getSize() }}
                >
                  <>
                    <div
                      {...{
                        style: {
                          cursor: header.column.getCanSort()
                            ? 'pointer'
                            : undefined
                        },
                        onClick: header.column.getToggleSortingHandler()
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {header.column.getIsSorted() === 'asc' ? (
                        <ArrowUpward />
                      ) : header.column.getIsSorted() === 'desc' ? (
                        <ArrowDownward />
                      ) : null}
                    </div>

                    {header.column.getCanFilter() ? (
                      <DebouncedInput
                        type="text"
                        value={(header.column.getFilterValue() ?? '') as string}
                        onChange={(value) =>
                          header.column.setFilterValue(value)
                        }
                        placeholder={`Search...`}
                        list={header.column.id + 'list'}
                      />
                    ) : null}
                  </>
                </TableHeaderCell>
              ))}
            </TableRow>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <HoverableTableRow
                key={row.id}
                onClick={() => {
                  setModalClaim(row.original)
                }}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <FocusedTableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </FocusedTableCell>
                  )
                })}
              </HoverableTableRow>
            )
          })}
        </tbody>
      </StyledTable>

      <Modal isOpen={!!modalClaim} handleClose={() => setModalClaim(undefined)}>
        {modalClaim && (
          <ClaimDetails claim={modalClaim} userId={modalClaim.userId} />
        )}
      </Modal>
    </Container>
  )
}

export default ClaimsTable
