import { INavigationDrawerStep } from '../models/navigationDrawer'
import { ISearchTypes } from '../models/tools'

import { ReactComponent as ClaimsIcon } from '../assets/images/claims.svg'
import { ReactComponent as SideEffectsIcon } from '../assets/images/sideEffects.svg'
import { ReactComponent as DinsIcon } from '../assets/images/dins.svg'
import { ReactComponent as IndicationsIcon } from '../assets/images/indications.svg'
import { ReactComponent as AdminPermissions } from '../assets/images/adminPermissions.svg'
import { ReactComponent as ReferralIcon } from '../assets/images/referral.svg'

export const TOOLS_NAV_STEPS: INavigationDrawerStep[] = [
  {
    Icon: AdminPermissions,
    text: 'Verification',
    linkStart: '/tools',
    linkEnd: 'verification'
  },
  {
    Icon: ClaimsIcon,
    text: 'Batch Payments',
    linkStart: '/tools',
    linkEnd: 'batch-payments'
  },
  {
    Icon: ReferralIcon,
    text: 'Codes',
    linkStart: '/tools',
    linkEnd: 'commercial-codes'
  },
  {
    Icon: DinsIcon,
    text: 'DINS',
    linkStart: '/tools',
    linkEnd: 'dins'
  },
  {
    Icon: IndicationsIcon,
    text: 'Indications',
    linkStart: '/tools',
    linkEnd: 'indications'
  },
  {
    Icon: SideEffectsIcon,
    text: 'Side Effects',
    linkStart: '/tools',
    linkEnd: 'side-effects'
  },
  {
    Icon: ClaimsIcon,
    text: 'Claims',
    linkStart: '/tools',
    linkEnd: 'claims'
  }
]

export const SEARCH_TYPES: ISearchTypes[] = [
  { label: 'DIN Search', value: 'din' },
  {
    label: 'Manufacturer, Brand Name, and Active Ingredient Search',
    value: 'other'
  }
]
