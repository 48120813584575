import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  FETCH_ALL_LEARNING_MODULES,
  FETCH_LEARNING_MODULE,
  FETCH_ATTACHED_LEARNING_MODULES,
  FETCH_WEEKLY_PROGRAM_LEARNING_POINT_ALLOCATION
} from 'src/constants/queries'
import { LMSApi, ProgramsApi } from 'src/api/client'
import { IFetchedLMS, ILMS } from 'src/models/learning'
import { IRequiredLearning } from 'src/models/program'

const useLearningModules = () => {
  const query = useQuery<IFetchedLMS[]>({
    queryKey: FETCH_ALL_LEARNING_MODULES,
    queryFn: () => LMSApi.getLearningModules()
  })

  return { ...query, learningModules: query.data }
}

const useLearningModule = (id: string) => {
  const query = useQuery<IFetchedLMS | undefined>({
    queryKey: FETCH_LEARNING_MODULE(id),
    queryFn: () => LMSApi.getLearningModule(id),
    enabled: !!id,
    staleTime: 0,
    cacheTime: 0
  })

  return { ...query, learningModule: query.data }
}

const useSaveLearningModule = () => {
  const mutation = useMutation(
    ({ learningModule, id }: { learningModule: ILMS; id?: string }) =>
      id
        ? LMSApi.updateLearningModule(id, learningModule)
        : LMSApi.createLearningModule(learningModule)
  )

  return { ...mutation, save: mutation.mutateAsync }
}

const useDeleteLearningModule = () => {
  const mutation = useMutation(({ id }: { id: string }) =>
    LMSApi.deleteLearningModule(id)
  )

  return { ...mutation, deleteModule: mutation.mutateAsync }
}

const useDraftLearningModule = () => {
  const mutation = useMutation(({ id }: { id: string }) =>
    LMSApi.draftLearningModule(id)
  )

  return { ...mutation, draftModule: mutation.mutateAsync }
}

const usePublishLearningModule = () => {
  const mutation = useMutation(({ id }: { id: string }) =>
    LMSApi.publishLearningModule(id)
  )

  return { ...mutation, publishModule: mutation.mutateAsync }
}

const useAttachLearningModule = (programId: string) => {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    async ({ moduleId, body }: { moduleId: string; body: any }) => {
      const formattedBody = {
        dependencies: body.dependencies,
        latePolicyWeekOne: {
          percent: body.firstLateDiscount
        },
        latePolicyWeekTwo: {
          percent: body.secondLateDiscount
        },
        latePolicyWeekThreePlus: {
          percent: body.thirdLateDiscount
        },
        releaseWeek: parseInt(body.releaseWeek, 10),
        pointWeek: parseInt(body.pointWeek, 10)
      }
      return ProgramsApi.attachLearningModule(
        programId,
        moduleId,
        formattedBody
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          FETCH_ATTACHED_LEARNING_MODULES(programId)
        )
        queryClient.invalidateQueries(
          FETCH_WEEKLY_PROGRAM_LEARNING_POINT_ALLOCATION(programId)
        )
      }
    }
  )

  return { ...mutation, attach: mutation.mutate }
}

const useUpdateAttachedLearningModule = (programId: string) => {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    async ({ moduleId, body }: { moduleId: string; body: any }) => {
      const formattedBody = {
        ...body,
        latePolicyWeekOne: {
          percent: body.firstLateDiscount
        },
        latePolicyWeekTwo: {
          percent: body.secondLateDiscount
        },
        latePolicyWeekThreePlus: {
          percent: body.thirdLateDiscount
        },
        releaseWeek: parseInt(body.releaseWeek, 10),
        pointWeek: parseInt(body.pointWeek, 10)
      }
      return ProgramsApi.updateAttachedLearningModule(
        programId,
        moduleId,
        formattedBody
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          FETCH_ATTACHED_LEARNING_MODULES(programId)
        )
        queryClient.invalidateQueries(
          FETCH_WEEKLY_PROGRAM_LEARNING_POINT_ALLOCATION(programId)
        )
      }
    }
  )

  return { ...mutation, update: mutation.mutate }
}

const useUnlinkLearningModule = (programId: string) => {
  const queryClient = useQueryClient()
  const mutation = useMutation(
    ({ moduleId }: { moduleId: string }) =>
      ProgramsApi.unlinkAttachedLearningModule(programId, moduleId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          FETCH_ATTACHED_LEARNING_MODULES(programId)
        )
        queryClient.invalidateQueries(
          FETCH_WEEKLY_PROGRAM_LEARNING_POINT_ALLOCATION(programId)
        )
      }
    }
  )

  return { ...mutation, unlink: mutation.mutate }
}

const useAttachedLearningModules = (programId: string) => {
  const attachedModulesQuery = useQuery<IRequiredLearning[]>({
    queryKey: FETCH_ATTACHED_LEARNING_MODULES(programId),
    queryFn: () => ProgramsApi.getAttachedLearningModules(programId)
  })

  const modulesWithWeights: IRequiredLearning[] | undefined =
    attachedModulesQuery.data?.map((requiredLearning) => {
      return { ...requiredLearning }
    })

  return {
    attachedModules: modulesWithWeights,
    isLoading: attachedModulesQuery.isLoading
  }
}
export {
  useLearningModules,
  useLearningModule,
  useSaveLearningModule,
  useAttachLearningModule,
  useUpdateAttachedLearningModule,
  useUnlinkLearningModule,
  useAttachedLearningModules,
  useDeleteLearningModule,
  useDraftLearningModule,
  usePublishLearningModule
}
