/* eslint-disable no-useless-return */
import { IProgram } from '../../models/program'

export enum ActionKind {
  createProgramInfo = 'createProgramInfo',
  initializeAccessCodes = 'initializeAccessCodes',
  removeAccessCodes = 'removeAccessCodes',
  updateAccessCode = 'updateAccessCode'
}

export type CreateProgramAction = {
  type: ActionKind
  payload: any
  fieldName: string
}

export const createProgramReducer = (
  _draft: IProgram,
  action: CreateProgramAction
) => {
  switch (action.type) {
    case 'createProgramInfo': {
      return
    }
    case 'initializeAccessCodes': {
      return
    }
    case 'removeAccessCodes': {
      return
    }
    case 'updateAccessCode': {
      return
    }
    default:
      return
  }
}
