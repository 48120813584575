import Modal from '../../../shared/views/Modal'
import { Buttons } from 'src/shared/styled'

import SearchSelect, { ISelectItem } from 'src/shared/views/SearchSelect'
import { IModule } from 'src/models/module'
import { useUpdateModulesForProgram } from 'src/shared/hooks/modules'
import { toast } from 'react-toastify'

interface ISelectModuleProps {
  isOpen: boolean
  handleClose: () => void
  selectableModules: IModule[]
  programId: string
  onChange: (modules: ISelectItem[]) => void
  selectedModules: ISelectItem[]
  loading?: boolean
}

const { ButtonWithMargin } = Buttons

const SelectModuleModal = ({
  programId,
  isOpen,
  handleClose,
  selectableModules,
  loading,
  selectedModules,
  onChange
}: ISelectModuleProps) => {
  /**
   * ----- Hook Initialization -----
   */

  const { isLoading, updateModulesForProgram } = useUpdateModulesForProgram(
    programId,
    {
      onSuccess: () => handleClose(),
      onError: (err) => toast.warning(err.message)
    }
  )

  /**
   * ----- Functions -----
   */

  const onSave = () => {
    updateModulesForProgram(selectedModules.map((module) => module.value))
  }

  /**
   * ----- Render -----
   */

  return (
    <Modal
      isOpen={isOpen}
      title={'Select Module'}
      handleClose={handleClose}
      dialogActions={
        <>
          <ButtonWithMargin
            type="submit"
            form="module-form"
            onClick={onSave}
            isLoading={isLoading}
          >
            SAVE CHANGES
          </ButtonWithMargin>
          <ButtonWithMargin variant="outlined" onClick={handleClose}>
            CANCEL
          </ButtonWithMargin>
        </>
      }
    >
      <div className="form-row">
        <SearchSelect
          isMulti
          disabled={loading || isLoading}
          label="Learning Module"
          options={selectableModules.map((module) => ({
            label: module.title.en || '',
            value: module.moduleId
          }))}
          value={selectedModules}
          onChange={(newValue) =>
            newValue && onChange(newValue as ISelectItem[])
          }
        />
      </div>
    </Modal>
  )
}

export default SelectModuleModal
