import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

const ProgramMembershipClaimSchema = yup
  .object()
  .shape({
    notes: yup.string().required(),
    amount: yup
      .object()
      .required()
      .shape({
        amount: yup.number().moreThan(0, 'must be greater than 0').required(),
        currencyCode: yup.string().required()
      })
  })
  .required()

const useProgramMembershipClaim = (options?: any) => {
  const form = useForm({
    resolver: yupResolver(ProgramMembershipClaimSchema),
    ...options
  })

  return form
}

export default useProgramMembershipClaim
