import { Tools } from 'src/shared/styled'
import VerificationView from '../views/Verification'

const { ToolsViewContainer } = Tools

const Verifications = () => {
  return (
    <ToolsViewContainer>
      <VerificationView />
    </ToolsViewContainer>
  )
}

export default Verifications
