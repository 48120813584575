import { Position } from '@xyflow/react'
import React from 'react'
import { useModuleFormContext } from 'src/contexts/ModuleForm'
import { IAnswerOption, ISingleSelectQuestion } from 'src/models/module'
import {
  ModuleContainer,
  ModuleContent,
  ModuleHeaderContainer,
  ModuleSelectableOptionContainer
} from 'src/shared/styled/Module'
import LanguageMap from 'src/shared/views/LanguageMap'
import LanguageMapForm from 'src/shared/views/LanguageMapForm'
import styled from 'styled-components'
import FirstTaskButton from './FirstTaskButton'
import NodeHandle from './NodeHandle'
import SelectableOptionList from './SelectableOptionList'

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

interface ISingleSelectQuestionNode {
  data: ISingleSelectQuestion
}

const SingleSelectQuestionNode = React.memo(
  ({ data }: ISingleSelectQuestionNode) => {
    /**
     * ----- Hook Initialization -----
     */

    const { moduleFormData, updateTask } = useModuleFormContext()

    /**
     * ----- Render -----
     */

    return (
      <>
        <NodeHandle type="target" position={Position.Top} />
        <ModuleContainer>
          <ModuleHeaderContainer>
            <h3>Single Select Question</h3>
            <FirstTaskButton taskId={data.taskId} />
          </ModuleHeaderContainer>
          <ModuleContent>
            <LanguageMapForm
              value={data.text}
              onChange={(value) => {
                updateTask({ ...data, text: value })
              }}
              supportedLanguages={moduleFormData.supportedLanguages}
              textFieldProps={{
                multiline: true,
                minRows: 2
              }}
            />
          </ModuleContent>
          <Row>
            {data.selectableOptions.map((option) => {
              return (
                <ModuleSelectableOptionContainer
                  style={{
                    width: `${(1 / data.selectableOptions.length) * 100}%`
                  }}
                  key={option.selectionId}
                >
                  <LanguageMap
                    value={option.text}
                    supportedLanguages={moduleFormData.supportedLanguages}
                  />
                </ModuleSelectableOptionContainer>
              )
            })}
          </Row>
        </ModuleContainer>
        {data.selectableOptions.map((option, index) => {
          // length 1 - 1 @ 50%
          // Length 2 - 1 @ 25%, 2 @ 75%
          // Length 3 - 1 @ 16.6%, 2 @ 50%, 3 @ 83.3%
          const leftPosition =
            ((2 * index + 1) / (2 * data.selectableOptions.length)) * 100

          return (
            <NodeHandle
              id={option.selectionId}
              type="source"
              position={Position.Bottom}
              style={{ left: `${leftPosition}%` }}
              key={option.selectionId}
            />
          )
        })}
      </>
    )
  }
)

export const SingleSelectQuestionNodeForm = ({
  data
}: ISingleSelectQuestionNode) => {
  /**
   * ----- Hook Initialization -----
   */

  const { moduleFormData, updateTask } = useModuleFormContext()

  /**
   * ----- Functions -----
   */

  const handleOptionChange = React.useCallback(
    (options: IAnswerOption[]) => {
      const updatedTask = { ...data, selectableOptions: options }

      const validOptionIds = updatedTask.selectableOptions.map(
        (option) => option.selectionId
      )
      Object.keys(updatedTask.navigationMap).forEach((key) => {
        // Check if the key is not in the validIds array
        if (!validOptionIds.includes(key)) {
          // Delete the entry from the record
          delete updatedTask.navigationMap[key]
        }
      })

      updateTask(updatedTask)
    },
    [data, updateTask]
  )

  /**
   * ----- Render -----
   */

  return (
    <div>
      <h3>Single Select Question</h3>
      <LanguageMapForm
        value={data.text}
        onChange={(value) => {
          updateTask({ ...data, text: value })
        }}
        supportedLanguages={moduleFormData.supportedLanguages}
        textFieldProps={{
          multiline: true
        }}
      />
      <h4>Options</h4>
      <SelectableOptionList
        taskId={data.taskId}
        selectableOptions={data.selectableOptions}
        onOptionsChange={handleOptionChange}
        singleSelect
      />
    </div>
  )
}

export default SingleSelectQuestionNode
