import { FormControlLabel, Switch, Typography } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { downloadImage, uploadImage } from 'src/api/services/amplify'
import { FETCH_PROGRAM } from 'src/constants/queries'
import { IEntryPointData, IProgram } from 'src/models/program'
import {
  useCreateProgramEntryPoint,
  useDeleteProgramEntryPoint,
  useUpdateProgramEntryPoint
} from 'src/shared/hooks/program'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'
import { FONTS } from 'src/shared/theme'
import { ImageUploader } from 'src/shared/views/ImageUploader'
import ThemedTextField from 'src/shared/views/TextField'
import styled from 'styled-components'
import { useEntryPointForm } from '../hooks/forms'

interface IProgramEntryPoint {
  program: IProgram
}

const FormContainer = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: rgb(203, 213, 224);
  padding: 20px;
  border-radius: 5px;
`

const EditButton = styled(ButtonWithMargin)`
  max-height: 35px;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  align-self: flex-start;
`

const StyledTypography = styled(Typography)`
  font-family: ${FONTS.main};
  color: ${(props) => props.theme.palette.common.black};
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 300;
  line-height: 19px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 20px;
`

const ProgramEntryPoint: React.FC<IProgramEntryPoint> = ({ program }) => {
  /**
   * ----- Hook Initialization -----
   */

  const [imageToDisplay, setImageToDisplay] = React.useState<any>('')

  const queryClient = useQueryClient()

  const {
    setError,
    clearErrors,
    setValue,
    watch,
    handleSubmit,
    control,
    formState: { errors, isDirty }
  } = useEntryPointForm({
    defaultValues: {
      ...program.entryPoint
    }
  })

  const { updateEntryPoint, isLoading: updateLoading } =
    useUpdateProgramEntryPoint(program.id, {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_PROGRAM(program.id))
        toast.success('Entry point updated successfully')
      }
    })

  const { createEntryPoint, isLoading: createLoading } =
    useCreateProgramEntryPoint(program.id, {
      onSuccess: () => {
        toast.success('Entry point created successfully')
      }
    })

  const { deleteEntryPoint } = useDeleteProgramEntryPoint(program.id, {
    onSuccess: () => {
      toast.success('Entry point deleted successfully')
    }
  })

  /**
   * ----- Variables -----
   */

  const imageUrl = watch('imageUrl')

  const loading = updateLoading || createLoading

  /**
   * ----- Functions -----
   */

  const submitHandler = React.useCallback(
    (data: IEntryPointData) => {
      if (program.entryPoint) {
        updateEntryPoint(data)
      } else {
        createEntryPoint(data)
      }
    },
    [program.entryPoint, updateEntryPoint, createEntryPoint]
  )

  const onHandleImageChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const imageFile = e?.target?.files && e?.target?.files[0]

      if (!imageFile) return

      if (!['image/png', 'image/jpg', 'image/jpeg'].includes(imageFile.type)) {
        setError('imageUrl', { message: 'File must be a png or jpeg' })
      } else {
        clearErrors('imageUrl')
        const img = URL.createObjectURL(imageFile)
        const remoteImage = await uploadImage(img, 'access-code')
        setValue('imageUrl', remoteImage, { shouldDirty: true })
      }
    },
    [clearErrors, setError, setValue]
  )

  const onClearImage = React.useCallback(() => {
    setValue('imageUrl', '', { shouldDirty: true })
  }, [setValue])

  /**
   * ----- UseEffect -----
   */

  React.useEffect(() => {
    const getImage = async () => {
      if (imageUrl) {
        const remoteImageUrl = await downloadImage(imageUrl)
        setImageToDisplay(remoteImageUrl)
      } else {
        setImageToDisplay('')
      }
    }

    getImage()
  }, [imageUrl])

  /**
   * ----- Rendering -----
   */

  return (
    <FormContainer onSubmit={handleSubmit(submitHandler)}>
      <Column>
        <Controller
          control={control}
          name="displayLabel"
          render={({ field: { ref, ...props } }) => (
            <ThemedTextField
              label="Display Label"
              inputRef={ref}
              helperText={errors?.displayLabel?.message}
              error={!!errors?.displayLabel?.message}
              {...props}
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field: { ref, ...props } }) => (
            <ThemedTextField
              label="Description"
              inputRef={ref}
              helperText={errors?.description?.message}
              error={!!errors?.description?.message}
              multiline
              {...props}
            />
          )}
        />
      </Column>
      <Controller
        control={control}
        name="imageUrl"
        render={({ field: { ...props }, fieldState: { error } }) => (
          <ImageUploader
            id="imageUrl"
            label="Upload Image"
            error={error?.message}
            onHandleImageChange={onHandleImageChange}
            onClearImage={onClearImage}
            imagePath={imageToDisplay}
            width={300}
            height={170}
            {...props}
          />
        )}
      />
      <Column>
        <Controller
          name="visibleToPatient"
          control={control}
          render={({ field: { onChange, value, ...props } }) => (
            <StyledFormControlLabel
              control={
                <Switch
                  checked={value}
                  onChange={(e) => onChange(e.target.checked)}
                  {...props}
                />
              }
              label={<StyledTypography>Visible</StyledTypography>}
            />
          )}
        />
        <Controller
          name="promptForCode"
          control={control}
          render={({ field: { onChange, value, ...props } }) => (
            <StyledFormControlLabel
              control={
                <Switch
                  checked={value}
                  onChange={(e) => onChange(e.target.checked)}
                  {...props}
                />
              }
              label={<StyledTypography>Code Required</StyledTypography>}
            />
          )}
        />
      </Column>
      <EditButton disabled={!isDirty} type="submit" isLoading={loading}>
        Save
      </EditButton>
      <EditButton startIcon={<Delete />} onClick={() => deleteEntryPoint()}>
        Delete
      </EditButton>
    </FormContainer>
  )
}

export default ProgramEntryPoint
