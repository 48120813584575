import { FC, PropsWithChildren, useMemo, useState } from 'react'
import { CellProps, Column } from 'react-table'
import { Link } from 'react-router-dom'
import { ExitToApp } from '@material-ui/icons'
import { Button } from '@material-ui/core'
import styled from 'styled-components'

import { DetailsContent } from 'src/shared/styled/Layout'
import Table from 'src/shared/views/Table/Table'
import { IPrescription, PrescriptionStatusEnum } from 'src/models/prescription'
import Modal from 'src/shared/views/Modal'
import { User } from 'src/shared/styled'
import { COLORS, FONTS } from 'src/shared/theme'
import PrescriptionForms from '../containers/PrescriptionForms'

const { StyledChip } = User

interface IUserInfoMedicationHistoryView {
  prescriptions: IPrescription[]
}

const ViewButton = styled(Button)`
  font-family: ${FONTS.medium};
  color: ${COLORS.prussianBlue};
  font-size: 16px;
`

const ProgramLink = styled.div`
  display: flex;
`

const NoProgram = styled.p`
  margin: 0;
`

const UserInfoMedicationHistoryView: FC<IUserInfoMedicationHistoryView> = ({
  prescriptions = []
}) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedPrescription, setSelectedPrescription] =
    useState<IPrescription>()

  const columns: Column[] = useMemo(
    () => [
      {
        Header: 'Medication Name',
        accessor: 'medicationName'
      },
      {
        Header: 'DIN',
        accessor: 'din',
        disableSortBy: true
      },
      {
        Header: 'Verification',
        accessor: 'verification.status'
      },
      {
        Header: 'Appearance',
        accessor: 'unitType',
        disableSortBy: true
      },
      {
        Header: 'Dose Strength',
        accessor: 'doseStrength',
        disableSortBy: true
      },
      {
        Header: 'Condition',
        accessor: 'condition',
        disableSortBy: true
      },
      {
        Header: 'Program',
        accessor: 'programName',
        disableSortBy: true,
        width: 200,
        Cell: ({ row }: PropsWithChildren<CellProps<IPrescription, any>>) => {
          return (
            <>
              {row.original.programMembership?.programId ? (
                <ProgramLink>
                  {row.original.programMembership?.programName || 'Program'}
                  <Link
                    to={`/programs/${row.original.programMembership?.programId}/details`}
                  >
                    <ExitToApp color="primary" />
                  </Link>
                </ProgramLink>
              ) : (
                <NoProgram>No program</NoProgram>
              )}
            </>
          )
        }
      },
      {
        Header: ' Status',
        accessor: 'status',
        Cell: ({ row }: PropsWithChildren<CellProps<IPrescription, any>>) => {
          return (
            <StyledChip
              label={row.original.status || 'Active'}
              color={
                row.original.status === PrescriptionStatusEnum.Active
                  ? 'primary'
                  : row.original.status === PrescriptionStatusEnum.Paused
                  ? 'default'
                  : 'secondary'
              }
            />
          )
        }
      },
      {
        Header: 'Details',
        accessor: 'details',
        Cell: ({ row }: PropsWithChildren<CellProps<IPrescription, any>>) => {
          return row.original.status === 'Active' ? (
            <ViewButton
              onClick={() => {
                setSelectedPrescription(row.original)
                setModalOpen(true)
              }}
            >
              VIEW
            </ViewButton>
          ) : null
        }
      }
    ],
    []
  )

  const closeModal = () => setModalOpen(false)

  return (
    <>
      <Modal isOpen={isModalOpen} handleClose={closeModal}>
        <PrescriptionForms
          prescription={selectedPrescription}
          closeModal={closeModal}
        />
      </Modal>
      <DetailsContent>
        <Table columns={columns} data={prescriptions} />
      </DetailsContent>
    </>
  )
}

export default UserInfoMedicationHistoryView
