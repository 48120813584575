import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const SENTRY_DSN =
  'https://d2c2648c102746b5a3be9e1ceeb395f8@o442218.ingest.sentry.io/5413609'

const isEnabled = process.env.NODE_ENV !== 'development'

export const sentryInit = () => {
  if (isEnabled) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: process.env.NODE_ENV,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0
    })
  }
}

export const sendSentryMessage = (msg: string, info: any) => {
  if (isEnabled) {
    Sentry.captureMessage(msg, info)
  }
}

export const sendSentryError = (errorText: string, error: Error) => {
  if (isEnabled) {
    Sentry.captureMessage(errorText)
    if (error) {
      Sentry.captureException(error)
    }
  }
}
