import { FC, PropsWithChildren, useMemo } from 'react'
import { CellProps, Column } from 'react-table'

import { COLORS } from 'src/shared/theme'
import Table from 'src/shared/views/Table/Table'
import { momentFormat } from 'src/utils/dates'
import { IAdherenceTransaction } from 'src/models/transaction'
import { TRANSACTION_DATE_TIME_FORMAT } from 'src/constants/datetimes'
import roundDecimal from 'src/utils/roundDecimal'
import Tooltip from 'src/shared/views/Tooltip'

type AdherenceProps = {
  data: IAdherenceTransaction[] | undefined
}

const Adherence: FC<AdherenceProps> = ({ data }) => {
  const columns = useMemo<Column<IAdherenceTransaction>[]>(
    () => [
      {
        Header: 'Scheduled',
        accessor: 'scheduledAt',
        Cell: ({
          row
        }: PropsWithChildren<CellProps<IAdherenceTransaction, any>>) => (
          <p>
            {momentFormat(
              new Date(row.original.scheduledAt),
              TRANSACTION_DATE_TIME_FORMAT
            )}
          </p>
        )
      },
      {
        Header: 'Marked as Taken',
        accessor: 'performedAt',
        Cell: ({
          row
        }: PropsWithChildren<CellProps<IAdherenceTransaction, any>>) => (
          <p>
            {row.original.performedAt
              ? momentFormat(
                  new Date(row.original.performedAt),
                  TRANSACTION_DATE_TIME_FORMAT
                )
              : '- -'}
          </p>
        )
      },
      {
        Header: 'Offset',
        accessor: 'offsetInMinutes',
        disableSortBy: true,
        Cell: ({
          row
        }: PropsWithChildren<CellProps<IAdherenceTransaction, any>>) => (
          <p>
            {Number(row.original.offsetInMinutes) <= 0
              ? `${row.original.offsetInMinutes} min`
              : `+${row.original.offsetInMinutes} min`}
          </p>
        )
      },
      {
        Header: 'Mode',
        accessor: 'mode',
        disableSortBy: true
      },
      {
        Header: 'Policy Applied',
        accessor: 'policyApplied',
        disableSortBy: true
      },
      {
        Header: 'Discount',
        width: 90,
        disableSortBy: true,
        Cell: ({
          row
        }: PropsWithChildren<CellProps<IAdherenceTransaction, any>>) => (
          <p>
            {Number(row.original.discount.percent) < 0
              ? `-${row.original.discount.percent} %`
              : Number(row.original.discount.percent) === 0
              ? `${row.original.discount.percent} %`
              : `+${row.original.discount.percent} %`}
          </p>
        )
      },
      {
        Header: 'Points',
        accessor: 'earnedPoints',
        disableSortBy: true,
        Cell: ({
          row
        }: PropsWithChildren<CellProps<IAdherenceTransaction, any>>) => (
          <p>{row.original.earnedPoints} points</p>
        )
      },
      {
        Header: 'Conversion Rate',
        accessor: 'conversionRate',
        disableSortBy: true,
        Cell: ({
          row
        }: PropsWithChildren<CellProps<IAdherenceTransaction, any>>) => (
          <Tooltip description={row.original.conversionRate.toString()}>
            {roundDecimal(row.original.conversionRate)}/pt
          </Tooltip>
        )
      },
      {
        Header: 'Value',
        width: 80,
        disableSortBy: true,
        Cell: ({
          row
        }: PropsWithChildren<CellProps<IAdherenceTransaction, any>>) => (
          <Tooltip description={row.original.value.amount.toString()}>
            ${roundDecimal(row.original.value.amount, 2)}
          </Tooltip>
        )
      }
    ],
    []
  )

  return (
    <Table
      columns={columns}
      data={data}
      placeholder="Filter"
      emptyMessage="There are no adherences for this program."
      getHeaderProps={() => ({
        style: {
          background: COLORS.white
        }
      })}
    />
  )
}

export default Adherence
