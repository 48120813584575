import React from 'react'

import { useIndicationOrSideEffectModal } from 'src/features/tools/hooks/modals'
import Modal from 'src/shared/views/Modal'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import ToolsModalForm from 'src/features/tools/views/ToolsModalForm'
import ModalActions from 'src/features/tools/views/ModalActions'

interface ToolsModalProps {
  isOpen: boolean
  handleClose: () => void
  id: string
  type: 'indication' | 'side-effect'
}

const ToolsModal: React.FC<ToolsModalProps> = ({
  isOpen,
  handleClose,
  id,
  type
}) => {
  const {
    data: sideEffect,
    isLoading,
    save,
    mutationSuccess,
    saveLoading,
    setSubmitButtonActive,
    title,
    form
  } = useIndicationOrSideEffectModal({ id, type })

  React.useEffect(() => {
    if (mutationSuccess) {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutationSuccess])
  const formId = `${type}-form`

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      handleClose={handleClose}
      dialogActions={
        <ModalActions
          isSubmitActive={setSubmitButtonActive}
          handleClose={handleClose}
          formId={formId}
          saveLoading={saveLoading}
          submitLabel="SAVE"
        />
      }
    >
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <ToolsModalForm
          formId={formId}
          onSubmit={(formValues) => {
            save(formValues.name)
          }}
          form={form}
          data={sideEffect}
          name={type}
          label={type === 'indication' ? 'Indication' : 'Side Effect'}
        />
      )}
    </Modal>
  )
}

export default ToolsModal
