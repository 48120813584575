import React from 'react'
import { downloadImage } from 'src/api/services/amplify'
import styled from 'styled-components'

const Image = styled.img`
  max-width: 300px;
  height: auto;
  object-fit: cover;
  cursor: pointer;
`

interface IImageDisplay {
  imageKey: string
  style?: React.CSSProperties
}

const ImageDisplay = ({ imageKey, style }: IImageDisplay) => {
  /**
   * ----- Hook Initialization -----
   */

  const [imageToDisplay, setImageToDisplay] = React.useState<string>()

  /**
   * ----- Functions -----
   */

  const openImageInNewTab = React.useCallback(() => {
    if (imageToDisplay) {
      const newWindow = window.open(
        imageToDisplay,
        '_blank',
        'noopener,noreferrer'
      )
      if (newWindow) newWindow.opener = null

      return newWindow
    }
  }, [imageToDisplay])

  /**
   * ----- Lifecycle -----
   */

  React.useEffect(() => {
    const getImage = async () => {
      if (imageKey) {
        const remoteImageUrl = await downloadImage(imageKey)
        if (remoteImageUrl) setImageToDisplay(remoteImageUrl)
      }
    }

    getImage()
  }, [imageKey])

  /**
   * ----- Render -----
   */

  return (
    <Image onClick={openImageInNewTab} src={imageToDisplay} style={style} />
  )
}

export default ImageDisplay
