import { Tools } from 'src/shared/styled'
import SideEffects from 'src/features/tools/views/SideEffects'

const { ToolsViewContainer } = Tools

const SideEffectsContainer = () => {
  return (
    <ToolsViewContainer>
      <SideEffects />
    </ToolsViewContainer>
  )
}

export default SideEffectsContainer
