import styled from 'styled-components'

import Button from '../views/Button'

export const SmallButton = styled(Button)`
  font-size: 14px;
  letter-spacing: 0.3px;
`
export const ButtonWithMargin = styled(SmallButton)`
  margin-left: 30px;
`

export const ButtonWithWidth = styled(Button)`
  min-width: 150px;
  margin-right: 20px;
`
export const TinyButton = styled(ButtonWithMargin)`
  font-size: 13px;
  padding: 5px 10px;
`
export const LargeIconButton = styled(Button)`
  min-width: 150px;
  & .MuiButton-label span {
    display: flex;
    flex-direction: column;
    align-items: center;

    & svg {
      margin-bottom: 10px;
      font-size: 25px;
    }
  }
`
