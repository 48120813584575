import React from 'react'
import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Controller } from 'react-hook-form'

import { useDrugLibItemForm } from '../hooks/forms'
import {
  useDrug,
  useIndicationSearch,
  useSideEffectSearch,
  useUpdateDinMutation
} from '../../../shared/hooks/drugLibrary'
import { Tools, Buttons } from '../../../shared/styled'
import { HeaderName, BoldHeading, SubHeaderName } from 'src/shared/styled/Text'
import { Form } from '../../../shared/styled/Tools'
import SearchList from './SearchList'
import TextField from 'src/shared/views/TextField'
import { FONTS } from 'src/shared/theme'
import WarningNotification from 'src/shared/views/WarningNotification'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import { IIndicationAndSideEffect } from 'src/models/program'

const { HeaderContainer, DrugDetailsContainer, TextArea, DinInfoContainer } =
  Tools

const { ButtonWithWidth } = Buttons

const DinInfo = () => {
  const {
    watch,
    control,
    handleSubmit,
    reset,
    formState: { isDirty, errors }
  } = useDrugLibItemForm()
  const { dinInfo, isLoading, formattedActiveIngredients } = useDrug()
  const [searchListChanges, setSearchListChanges] = React.useState(false)
  const showWarningNotifcation = isDirty || searchListChanges
  const { updateDin, isLoading: drugUpdateIsLoading } = useUpdateDinMutation()
  const history = useHistory()
  const [indicationQuery, setIndicationQuery] = React.useState('')
  const [sideEffectQuery, setSideEffectQuery] = React.useState('')
  const [sideEffects, setSideEffects] =
    React.useState<IIndicationAndSideEffect[]>()
  const [indications, setIndications] =
    React.useState<IIndicationAndSideEffect[]>()

  const { data: indicationSearchData, search: searchIndications } =
    useIndicationSearch(indicationQuery, 10)
  const { data: sideEffectSearchData, search: searchSideEffects } =
    useSideEffectSearch(sideEffectQuery, 10)

  const handleSaveForm = async (formData: any) => {
    try {
      if (dinInfo) {
        await updateDin({
          din: dinInfo.din.value,
          newValues: {
            ...formData,
            indications,
            sideEffects
          }
        })
        // Kind of a hack, need to reset form values to the ones we just saved
        // so we can clear the unsaved changes banner
        reset(formData)
        setSearchListChanges(false)
      }
    } catch {
      throw new Error('Could not update DIN')
    }
  }

  React.useEffect(() => {
    if (!isLoading) {
      setSideEffects(dinInfo?.sideEffects || [])
      setIndications(dinInfo?.indications || [])
    }
  }, [dinInfo?.sideEffects, dinInfo?.indications, isLoading])

  if (isLoading) return <LoadingScreen />

  watch()

  return (
    <div>
      {showWarningNotifcation ? (
        <WarningNotification message="You have unsaved changes" />
      ) : null}
      <DinInfoContainer>
        <HeaderName>
          TOOLS / <strong style={{ fontFamily: FONTS.bold }}>DINS</strong>
        </HeaderName>
        <HeaderContainer>
          <SubHeaderName>{dinInfo?.brandName}</SubHeaderName>
          <div>
            <ButtonWithWidth
              variant="outlined"
              onClick={() => history.push('/tools/dins')}
            >
              CANCEL
            </ButtonWithWidth>
            <ButtonWithWidth
              type="submit"
              form="din-form"
              isLoading={drugUpdateIsLoading}
              disabled={!showWarningNotifcation}
            >
              SAVE
            </ButtonWithWidth>
          </div>
        </HeaderContainer>
        <DrugDetailsContainer>
          <BoldHeading className="drug-information">
            Drug Information
          </BoldHeading>
          <div>
            <BoldHeading className="key">DIN:</BoldHeading>
            <Typography className="value">{dinInfo?.din.value}</Typography>
          </div>
          <div>
            <BoldHeading className="key">Drug Name:</BoldHeading>
            <Typography className="value">{dinInfo?.brandName}</Typography>
          </div>
          <div>
            <BoldHeading className="key">Company:</BoldHeading>
            <Typography className="value">
              {dinInfo?.manufacturerName}
            </Typography>
          </div>
          <div>
            <BoldHeading className="key">Active Ingredient(s):</BoldHeading>
            <Typography className="value">
              {dinInfo && dinInfo?.activeIngredients?.length > 1
                ? formattedActiveIngredients
                : `${dinInfo?.activeIngredients[0].name} ${formattedActiveIngredients}`}
            </Typography>
          </div>
          <div>
            <BoldHeading className="key">Dose Unit Type:</BoldHeading>
            <Typography className="value">
              {dinInfo?.pharmaceuticalForm}
            </Typography>
          </div>
        </DrugDetailsContainer>
        <SearchList
          title="Indications"
          setHasChanges={(newVal) => setSearchListChanges(newVal)}
          options={indicationSearchData}
          values={indications}
          setQuery={setIndicationQuery}
          searchFn={searchIndications}
          removeValue={(id: string) =>
            setIndications(
              indications?.filter((indication) => indication.id !== id)
            )
          }
          setNewValues={(newValue: IIndicationAndSideEffect[]) =>
            setIndications(indications?.concat(newValue))
          }
        />
        <SearchList
          title="Side Effects"
          setHasChanges={(newVal) => {
            setSearchListChanges(newVal)
          }}
          options={sideEffectSearchData}
          values={sideEffects}
          setQuery={setSideEffectQuery}
          searchFn={searchSideEffects}
          removeValue={(id: string) =>
            setSideEffects(sideEffects?.filter((effect) => effect.id !== id))
          }
          setNewValues={(newValue: IIndicationAndSideEffect[]) =>
            setSideEffects(sideEffects?.concat(newValue))
          }
        />
        <Form id="din-form" onSubmit={handleSubmit(handleSaveForm)}>
          <TextArea>
            <BoldHeading>Warnings</BoldHeading>
            <Controller
              defaultValue={dinInfo?.warnings}
              name="warnings"
              control={control}
              render={({ field: { ref, ...props } }) => (
                <TextField
                  multiline
                  fullWidth
                  inputRef={ref}
                  rows={6}
                  error={!!errors?.warnings?.message}
                  helperText={errors?.warnings?.message}
                  {...props}
                />
              )}
            />
          </TextArea>
          <TextArea>
            <BoldHeading>Interactions</BoldHeading>
            <Controller
              defaultValue={dinInfo?.interactions}
              name="interactions"
              control={control}
              render={({ field: { ref, ...props } }) => (
                <TextField
                  multiline
                  fullWidth
                  inputRef={ref}
                  rows={6}
                  error={!!errors?.interactions?.message}
                  helperText={errors?.interactions?.message}
                  {...props}
                />
              )}
            />
          </TextArea>
        </Form>
      </DinInfoContainer>
    </div>
  )
}

export default DinInfo
