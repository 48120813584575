import React from 'react'
import { Controller } from 'react-hook-form'

import { useLMSTitleForm } from '../hooks/forms'
import { Learning, Text } from 'src/shared/styled'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import { useLMS } from 'src/contexts/Learning'

const { BoldHeading } = Text
const { AddTextContainer, Form, TitleTextField, DescriptionTextField } =
  Learning

interface IAddPageTitleProps {
  cancelEditTitle: () => void
  id: string
  header?: string
  description?: string
  saved: boolean
}

const AddPageTitle: React.FC<IAddPageTitleProps> = ({
  cancelEditTitle,
  id,
  header,
  description,
  saved
}) => {
  const { savePageTitle, setSavedFalse } = useLMS()
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isDirty }
  } = useLMSTitleForm()

  React.useEffect(() => {
    if (isDirty && saved) {
      setSavedFalse(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, id])

  const resetForm = () => {
    reset()
    cancelEditTitle()
  }
  const handleSave = (formValues: any) => {
    savePageTitle(id, formValues)
    cancelEditTitle()
  }
  watch()

  return (
    <AddTextContainer>
      <Form onSubmit={handleSubmit(handleSave)}>
        <div className="fields-container">
          <BoldHeading>Title</BoldHeading>
          <Controller
            defaultValue={header ?? ''}
            name="title"
            control={control}
            render={({ field: { ref, ...props } }) => (
              <TitleTextField
                label="Title"
                placeholder="Optional"
                variant="filled"
                inputRef={ref}
                helperText={
                  errors?.title ? errors?.title?.message : 'Max. 150 characters'
                }
                error={!!errors?.title?.message}
                {...props}
              />
            )}
          />
          <Controller
            defaultValue={description ?? ''}
            name="description"
            control={control}
            render={({ field: { ref, ...props } }) => (
              <DescriptionTextField
                label="Description"
                placeholder="Optional"
                variant="filled"
                inputRef={ref}
                {...props}
              />
            )}
          />
        </div>
        <div className="button-container">
          <ButtonWithWidth type="submit" color="secondary" disabled={!isDirty}>
            SAVE
          </ButtonWithWidth>
          <ButtonWithWidth variant="outlined" onClick={resetForm}>
            CANCEL
          </ButtonWithWidth>
        </div>
      </Form>
    </AddTextContainer>
  )
}

export default AddPageTitle
