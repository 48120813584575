import React from 'react'
import { Controller } from 'react-hook-form'

import { Text, Learning } from 'src/shared/styled'
import { ImageUploader } from 'src/shared/views/ImageUploader'
import { useLMS } from 'src/contexts/Learning'
import { useLMSImageForm } from '../hooks/forms'
import { ILMSImageSection } from 'src/models/learning'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import { downloadImage, uploadImage } from 'src/api/services/amplify'

interface IAddImageSection {
  cancelEditText: () => void
  section: ILMSImageSection
  pageId: string
}

const { BoldHeading } = Text
const { AddImageContainer, DescriptionTextField } = Learning

const AddImageSection: React.FC<IAddImageSection> = ({
  cancelEditText,
  section,
  pageId
}) => {
  const { saveSection, setSavedFalse } = useLMS()
  const {
    control,
    handleSubmit,
    watch,
    setError,
    setValue,
    clearErrors,
    getValues,
    reset,
    formState: { errors, isDirty }
  } = useLMSImageForm()

  React.useEffect(() => {
    if (isDirty && section.saved) {
      setSavedFalse(pageId, section.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, section.id, pageId])

  React.useEffect(() => {
    const getImage = async (imagePath: string) => {
      const image = await downloadImage(imagePath)
      if (image) {
        setValue('imageUrl', image)
      }
    }

    const defaultImage = section?.imageUrl

    if (defaultImage) {
      getImage(defaultImage)
    }
  }, [section?.imageUrl, setValue])

  const onHandleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageFile = e?.target?.files && e?.target?.files[0]

    if (!imageFile) return

    if (!['image/png', 'image/jpg', 'image/jpeg'].includes(imageFile.type)) {
      setError('imageUrl', { message: 'File must be a png or jpeg' })
    } else {
      clearErrors('imageUrl')
      const img = URL.createObjectURL(imageFile)
      setValue('imageUrl', img)
    }
  }

  const onClearImage = () => {
    setValue('imageUrl', '')
  }

  const resetForm = () => {
    cancelEditText()
    reset()
  }

  const handleSave = async (formValues: any) => {
    const uploadedImageUrl = await uploadImage(formValues.imageUrl, 'learning')
    if (uploadedImageUrl) {
      formValues.imageUrl = uploadedImageUrl
    }
    saveSection(pageId, section.id, formValues)
    cancelEditText()
  }

  watch()
  return (
    <AddImageContainer>
      <form onSubmit={handleSubmit(handleSave)}>
        <BoldHeading>Image</BoldHeading>
        <Controller
          defaultValue={''}
          name="imageUrl"
          control={control}
          render={({ fieldState: { error } }) => (
            <ImageUploader
              id="imageUrl"
              label="Upload Image"
              error={error?.message}
              onHandleImageChange={onHandleImageChange}
              onClearImage={onClearImage}
              imagePath={getValues().imageUrl}
            />
          )}
        />
        <Controller
          defaultValue={section.imageDescription}
          name="imageDescription"
          control={control}
          render={({ field: { ref, ...props } }) => (
            <DescriptionTextField
              label="Enter Image Label (optional)"
              variant="filled"
              inputRef={ref}
              error={!!errors?.imageDescription?.message}
              helperText={errors?.imageDescription?.message}
              {...props}
            />
          )}
        />
        <div className="button-container">
          <ButtonWithWidth type="submit" color="secondary">
            SAVE
          </ButtonWithWidth>
          <ButtonWithWidth variant="outlined" onClick={resetForm}>
            CANCEL
          </ButtonWithWidth>
        </div>
      </form>
    </AddImageContainer>
  )
}

export default AddImageSection
