import { Panel } from '@xyflow/react'
import React from 'react'
import { useModuleFormContext } from 'src/contexts/ModuleForm'
import { ModuleTaskType } from 'src/models/module'
import { PanelContainer } from 'src/shared/styled/Module'
import styled from 'styled-components'
import { FreeFormResponseNodeForm } from './FreeFormResponse'
import { MessageNodeForm } from './Message'
import { MultiSelectQuestionNodeForm } from './MultiSelectQuestion'
import { SingleSelectQuestionNodeForm } from './SingleSelectQuestion'

const Container = styled(PanelContainer)`
  max-height: 70vh;
  margin-bottom: 5px;
  overflow-y: auto;
`

interface INodePanel {
  nodeIds: string[]
}

const NodePanel = ({ nodeIds }: INodePanel) => {
  /**
   * ----- Hook Initialization -----
   */

  const { nodes } = useModuleFormContext()

  /**
   * ----- Render -----
   */

  const form = React.useMemo(() => {
    const foundNodes = nodes.filter((node) => nodeIds.includes(node.id))

    if (!foundNodes) return "Couldn't find nodes"

    return foundNodes.map((node) => {
      const { data } = node
      let form = null
      switch (data.type) {
        case ModuleTaskType.Message:
          form = <MessageNodeForm data={data} />
          break
        case ModuleTaskType.SingleSelectQuestion:
          form = <SingleSelectQuestionNodeForm data={data} />
          break
        case ModuleTaskType.MultiSelectQuestion:
          form = <MultiSelectQuestionNodeForm data={data} />
          break
        case ModuleTaskType.FreeFormResponse:
          form = <FreeFormResponseNodeForm data={data} />
          break
        default:
          form = 'Not yet supported'
          break
      }
      return <Container key={data.taskId}>{form}</Container>
    })
  }, [nodeIds, nodes])

  return <Panel position="top-right">{form}</Panel>
}

export default NodePanel
