import styled from 'styled-components'

import { COLORS, FONTS } from '../theme'

export const Wrapper = styled.div`
  padding: 50px 64px;
`

export const StyledHeading = styled.h1`
  margin-bottom: 2em;
  color: rgba(0, 0, 0, 0.87);
  font-family: ${FONTS.medium};
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0.05px;
  line-height: 39px;
`

export const LearningModulesContainer = styled.div`
  padding: 30px;
  & form {
    display: flex;
    flex-direction: column;
    & .form-row {
      display: flex;
      flex-direction: row;
    }
  }
`
export const WeightForm = styled.form`
  display: flex;
  flex-direction: column;
  & .fields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    & .weight-row {
      display: flex;
      margin-right: 20px;
      flex-direction: column;
      margin-bottom: 20px;
      & .MuiTypography-root {
        margin-bottom: 15px;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`
export const LiteracyOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`
export const OverviewRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  & .week {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 150px;
    padding: 20px;
    width: 15%;
    background-color: ${COLORS.prussianBlue};
    border-bottom: 3px solid ${COLORS.borderGray};
    & button {
      margin-left: 0;
    }
    & .MuiTypography-root {
      margin-top: 40px;
      color: white;
    }
    & .button-container {
      background-color: white;
      border-radius: 4px;
    }
  }
  & .modules {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    background-color: ${COLORS.alto};
    width: 85%;
    border-bottom: 3px solid ${COLORS.borderGray};
    flex-wrap: wrap;
  }
`
export const ModuleContainer = styled.div<{ backgroundImage: any }>`
  background-image: url(${(props) => props.backgroundImage});
  background-size: 100% 100%;
  position: relative;
  height: 120px;
  width: 210px;
  min-width: 210px;
  background-color: white;
  margin: 10px;
  & .text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding: 10px;
  }
  & .overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    & .title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    & .MuiTypography-root {
      color: white;
    }
  }
`
export const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  & span {
    margin-right: 10px;
  }
`

export const ChipsInputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & .MuiChip-root {
    max-width: 200px;
    margin-bottom: 5px;
  }
`
export const Disclaimer = styled.div`
  margin-bottom: 20px;
`
