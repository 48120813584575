import { FrequencyEnum } from 'src/models/prescription'

export const FREQUENCY_DROPDOWN_ITEMS = [
  {
    label: 'Daily',
    value: FrequencyEnum.Daily
  },
  {
    label: 'Days Interval',
    value: FrequencyEnum.Interval
  },
  {
    label: 'Specific Days',
    value: FrequencyEnum.Specific
  }
]

const DOSES_PER_DAY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const DOSES_PER_DAY_DROPDOWN_ITEMS = DOSES_PER_DAY.map((d) => ({
  value: d.toString(),
  label: d.toString()
}))

const INTERVAL_DAYS = [2, 3, 4, 5, 6, 7]

export const INTERVAL_DAYS_DROPDOWN_ITEMS = INTERVAL_DAYS.map((d) => ({
  value: d.toString(),
  label: d.toString()
}))

export const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export const DAYS_OF_WEEK_AUTOCOMPLETE_OPTIONS = DAYS_OF_WEEK.map((d) => ({
  value: d
}))
