import { isEqual, uniq, uniqWith } from 'lodash'
import { useEffect } from 'react'
import { IIndication } from 'src/models/program'
import { useIndicationSearch } from 'src/shared/hooks/drugLibrary'

/**
 *
 * Returns an array of indication search values
 */

interface IuseIndicationSearchOptions {
  searchQuery: string
  existingValue?: string
  previousOptions?: IIndication[]
}

export const useIndicationSearchOptions = ({
  searchQuery,
  existingValue,
  previousOptions = []
}: IuseIndicationSearchOptions) => {
  const conditionSearchQuery = useIndicationSearch(searchQuery, 20)

  const conditionData = conditionSearchQuery?.data?.map((d) => d.name) || []
  const conditionNameValues = existingValue
    ? conditionData.concat(existingValue)
    : conditionData
  const conditionNameValuesData: IIndication[] = uniq(conditionNameValues).map(
    (value) => ({
      value
    })
  )
  const conditionAutoCompleteValues: IIndication[] = uniqWith(
    [...previousOptions, conditionNameValuesData].flat(Infinity),
    isEqual
  )

  useEffect(() => {
    if (searchQuery !== '') conditionSearchQuery.search()
    // eslint-disable-next-line
  }, [searchQuery])

  return {
    conditionAutoCompleteValues,
    isConditionSearchLoading: conditionSearchQuery.isLoading
  }
}
