import { useParams } from 'react-router-dom'

import { useUserPrograms } from 'src/shared/hooks/users'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import { UserAdminMatchParams } from '../../../models/routeParams'
import ProgramView from '../views/Program'

const Program = () => {
  const { id: userId } = useParams<UserAdminMatchParams>()

  const { programMemberships, isLoading, error } = useUserPrograms(userId)

  if (error) return <p>There was an error fetching notes</p>
  if (!isLoading && programMemberships)
    return (
      <div style={{ flexDirection: 'column', width: '100%' }}>
        <ProgramView userId={userId} programs={programMemberships} />
      </div>
    )

  return <LoadingScreen />
}

export default Program
