import React from 'react'
import styled from 'styled-components'
import { Controller, useForm } from 'react-hook-form'

import TextField from 'src/shared/views/TextField'
import {
  IPrescription,
  IPrescriptionRefillUpdateBody
} from 'src/models/prescription'
import { DetailsContent } from 'src/shared/styled/Layout'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import { useUpdatePrescriptionRefill } from 'src/shared/hooks/prescription'
import { useParams } from 'react-router'
import { UserAdminMatchParams } from 'src/models/routeParams'
import { toast } from 'react-toastify'
import { Divider } from '@material-ui/core'
import { BoldHeading } from 'src/shared/styled/Text'

interface IPrescriptionRefillForm {
  prescription: IPrescription
  canCurrentUserWrite: boolean
  setPrescription: React.Dispatch<
    React.SetStateAction<IPrescription | undefined>
  >
}

const FormFieldsRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const MarginDivider = styled(Divider)`
  margin: 1em 0 2em 0;
`

const HeadingTitle = styled(BoldHeading)`
  margin-bottom: 30px;
`

const formFieldStyles = {
  marginBottom: '30px',
  marginRight: '0',
  minWidth: '200px',
  width: '50%',
  maxWidth: '230px'
}

const PrescriptionRefillForm: React.FC<IPrescriptionRefillForm> = ({
  prescription,
  canCurrentUserWrite,
  setPrescription
}) => {
  const { id: userId } = useParams<UserAdminMatchParams>()

  const { control, formState, watch, handleSubmit, reset } =
    useForm<IPrescriptionRefillUpdateBody>({
      defaultValues: {
        units: 0
      }
    })

  const {
    updatePrescriptionRefill,
    isSuccess,
    isLoading,
    isError,
    error,
    data
  } = useUpdatePrescriptionRefill(userId, prescription.prescriptionId)

  watch()

  const submitForm = (formValues: IPrescriptionRefillUpdateBody) => {
    updatePrescriptionRefill(formValues)
  }

  React.useEffect(() => {
    if (!isLoading && isSuccess) {
      toast.success('Successfully updated prescription refill data')
      setPrescription(data)
      reset()
    } else if (!isLoading && isError) {
      toast.error(`${error}`)
    }
  }, [isLoading, isSuccess, isError, error, data, setPrescription, reset])

  return (
    <DetailsContent>
      <HeadingTitle>Details</HeadingTitle>
      <FormFieldsRow>
        <TextField
          disabled
          type="number"
          label="Units Remaining"
          value={prescription.unitsLeft}
        />
      </FormFieldsRow>
      <MarginDivider />
      <HeadingTitle>Edit</HeadingTitle>
      <FormFieldsRow>
        <Controller
          render={({ field: { ref, ...props }, fieldState: { error } }) => (
            <TextField
              style={formFieldStyles}
              type="number"
              label="Additional Units"
              inputRef={ref}
              error={!!error?.message}
              helperText={error?.message}
              {...props}
              disabled={!canCurrentUserWrite}
              noNegative
            />
          )}
          name="units"
          control={control}
        />
      </FormFieldsRow>
      {canCurrentUserWrite && (
        <ButtonWithWidth
          disabled={!formState.isDirty}
          variant="contained"
          color="primary"
          onClick={handleSubmit(submitForm)}
          isLoading={isLoading}
        >
          Add Units
        </ButtonWithWidth>
      )}
    </DetailsContent>
  )
}

export default PrescriptionRefillForm
