import React from 'react'
import { Tools } from '../../../shared/styled'
import Dins from '../views/Dins'

const { ToolsViewContainer } = Tools

const DinsContainer: React.FC = () => {
  return (
    <ToolsViewContainer>
      <Dins />
    </ToolsViewContainer>
  )
}

export default DinsContainer
