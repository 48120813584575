import { IProgramMembership } from './program'
import { IVerificationDetail } from './verificationTask'

export enum FrequencyEnum {
  Daily = 'Daily',
  Specific = 'Specific',
  Interval = 'Interval'
}

export enum ScheduleEnum {
  Fixed = 'Fixed'
}

export enum CompletionEnum {
  Skipped = 'Skipped',
  TakenOutside = 'TakenOutside',
  OnTimeReportedLate = 'OnTimeReportedLate',
  OnTime = 'OnTime'
}

export interface ILibraryDosage {
  value: string
  din: string
}

export interface ILibraryUnitType {
  type: string
  dosages: ILibraryDosage[]
}

export interface IDosingSchedule {
  times?: string[]
  dayOfWeek?: string
  everyNthDay?: number
}

export enum PrescriptionStatusEnum {
  Active = 'Active',
  Deleted = 'Deleted',
  Paused = 'Paused'
}

export interface IPrescription {
  condition: string
  din: string
  doseStrength: string
  dosing?: IDosingSchedule[]
  imageUrl?: string
  intakeInstructions?: string
  medicationName: string
  prescriptionId: string
  programMembership?: IProgramMembership
  programWeek?: number
  refillReminder: number
  refillsRemaining: number
  scheduleType: ScheduleEnum
  startedOn: string
  status: PrescriptionStatusEnum
  unitType: string
  unitsLeft: number
  unitsPerDose: number
  verification: IVerificationDetail
}
export interface IPrescriptionDosageFormItems {
  frequency: FrequencyEnum
  dosesPerDay: number
  daysOfWeek?: { value: string }[]
  everyNthDay?: number
  doseTimes?: string[]
}

export interface IPrescriptionCreationBody
  extends Pick<
    IPrescription,
    | 'din'
    | 'medicationName'
    | 'unitType'
    | 'doseStrength'
    | 'condition'
    | 'startedOn'
    | 'imageUrl'
    | 'refillsRemaining'
    | 'scheduleType'
    | 'unitsPerDose'
    | 'dosing'
  > {
  unitsInHand: number
}

export interface IPrescriptionCreationForm
  extends Omit<IPrescriptionCreationBody, 'condition'>,
    IPrescriptionDosageFormItems {
  condition: { value: string }
}

export interface IPrescriptionGeneralForm
  extends Pick<
    IPrescription,
    | 'din'
    | 'medicationName'
    | 'unitType'
    | 'doseStrength'
    | 'imageUrl'
    | 'refillsRemaining'
  > {
  condition: { value: string }
}

export type IPrescriptionDosageUpdateBody = Pick<
  IPrescription,
  'unitsPerDose' | 'dosing'
>

export interface IPrescriptionDosageUpdateForm
  extends Pick<IPrescription, 'unitsPerDose'>,
    IPrescriptionDosageFormItems {}

export type IPrescriptionRefillUpdateBody = {
  units: number
}
