import { useQuery } from 'react-query'
import { ClaimsApi } from 'src/api/client'
import { FETCH_ALL_CLAIMS } from 'src/constants/queries'
import { IUserClaim } from 'src/models/user'

export const useClaims = () => {
  const query = useQuery<IUserClaim[]>(FETCH_ALL_CLAIMS, () =>
    ClaimsApi.getAllClaims()
  )

  return { ...query }
}
