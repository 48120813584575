import { useParams } from 'react-router-dom'
import VerificationTable from 'src/components/VerificationTable'
import { UserAdminMatchParams } from 'src/models/routeParams'
import { useUser } from 'src/shared/hooks/users'
import {
  DetailsContainer,
  DetailsHeader,
  DetailsHeaderContainer
} from 'src/shared/styled/Layout'
import { HeaderName, SubHeaderName } from 'src/shared/styled/Text'
import { FONTS } from 'src/shared/theme'
import LoadingScreen from 'src/shared/views/LoadingScreen'

const UserVerifications = () => {
  /**
   * ----- Hook Initialization -----
   */

  const { id: userId } = useParams<UserAdminMatchParams>()

  const { data: user, isLoading, error } = useUser(userId)

  /**
   * ----- Render -----
   */

  if (error) return <p>There was an error fetching this user</p>
  if (isLoading || !user) return <LoadingScreen />

  return (
    <DetailsContainer>
      <DetailsHeaderContainer>
        <DetailsHeader>
          <div>
            <HeaderName style={{ textTransform: 'uppercase' }}>
              Users /{' '}
              <strong style={{ fontFamily: FONTS.bold }}>
                {user.firstName} {user.lastName}
              </strong>
            </HeaderName>
            <SubHeaderName>User Verification</SubHeaderName>
          </div>
        </DetailsHeader>
      </DetailsHeaderContainer>
      <VerificationTable userId={user.userId} disableEndDate noTitle />
    </DetailsContainer>
  )
}

export default UserVerifications
