import { createContext, FC, useState } from 'react'
import { ICognitoUser, ICurrentUser } from 'src/models/user'
interface IUserContext {
  currentUser: ICurrentUser | null
  setUser: (user: any) => void
}
const initialState = {
  currentUser: null,
  setUser: () => null
}
export const UserContext = createContext<IUserContext>(initialState)
export const UserProvider: FC = ({ children }) => {
  const [currentUser, setUserState] = useState<ICurrentUser | null>(null)
  const setUser = (user: ICognitoUser) => {
    const formattedUser = Object.assign({}, user, {
      fullName: `${user.given_name} ${user.family_name}`,
      permissions: user.profile
        ? user.profile
            .replace('[', '')
            .replace(']', '')
            .split(',')
            .map((a: string) => a.trim())
        : []
    }) as ICurrentUser
    setUserState(formattedUser)
  }
  return (
    <UserContext.Provider value={{ currentUser, setUser }}>
      {children}
    </UserContext.Provider>
  )
}
