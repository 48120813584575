import React from 'react'
import { DropDown, IDropDownProps } from 'src/shared/views/DropDown'

export const currenciesOptionList = [
  {
    value: 'CAD',
    label: 'CAD'
  }
]

const CurrencySelect = React.forwardRef<
  HTMLInputElement,
  Omit<IDropDownProps, 'items'>
>(({ value, onChange }, ref) => {
  return (
    <DropDown
      items={currenciesOptionList}
      defaultValue={currenciesOptionList[0].label}
      label="Currencies"
      value={value}
      onChange={onChange}
      style={{ width: '8rem' }}
      ref={ref}
    />
  )
})

export default CurrencySelect
