import { FC } from 'react'

import { usePrograms } from 'src/shared/hooks/program'
import LoadingScreen from 'src/shared/views/LoadingScreen'

import Dashboard from '../views/Dashboard'

const DashboardContainer: FC = () => {
  const { error, programs } = usePrograms()

  if (error) return <div>Something went wrong...</div>
  if (programs) return <Dashboard data={programs} />

  return <LoadingScreen />
}

export default DashboardContainer
