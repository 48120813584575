import axios from 'axios'

import {
  getCurrentSession,
  getCurrentUser,
  signOut
} from 'src/api/services/amplify'

// refreshes the token
// adds the jwt token to each request
axios.interceptors.request.use(function (config) {
  return new Promise((resolve) => {
    getCurrentSession()
      .then((session) => {
        const idTokenExpire = session.getIdToken().getExpiration()
        const refreshToken = session.getRefreshToken()
        const currentTimeSeconds = Math.round(Date.now() / 1000)
        if (idTokenExpire < currentTimeSeconds) {
          getCurrentUser().then((res) => {
            res.refreshSession(refreshToken, (err: any, data: any) => {
              if (err) {
                signOut()
                localStorage.removeItem('user')
              } else {
                config.headers.Authorization =
                  'Bearer ' + data.getIdToken().getJwtToken()
                resolve(config)
              }
            })
          })
        } else {
          config.headers.Authorization =
            'Bearer ' + session.getIdToken().getJwtToken()
          resolve(config)
        }
      })
      .catch(() => {
        // No logged-in user: don't set auth header
        resolve(config)
      })
  })
})
