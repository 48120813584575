import { Tools } from 'src/shared/styled'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import { useGetBatchPaymentFileList } from 'src/shared/hooks/tools'
import BatchPaymentsView from '../views/BatchPayments'

const { ToolsViewContainer } = Tools

const BatchPayments = () => {
  const { isLoading, error, batchPaymentFiles } = useGetBatchPaymentFileList()

  if (error) return <div>{`An error has occurred`}</div>
  if (!isLoading && batchPaymentFiles)
    return (
      <ToolsViewContainer>
        <BatchPaymentsView batchPayments={batchPaymentFiles || []} />
      </ToolsViewContainer>
    )

  return <LoadingScreen />
}

export default BatchPayments
