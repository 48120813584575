import { Typography, Chip } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { COLORS, FONTS } from '../theme'
import TextField from '../views/TextField'

export const ToolsViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 64px 32px;
`
export const TableContainer = styled.div`
  margin-top: 80px;
`
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const SearchContainer = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 100%;
  padding: 24px;
  margin-top: 45px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 40px;
  }
`
export const BoldText = styled(Typography)`
  font-size: 16px;
  color: #000;
  font-family: ${FONTS.medium};
  font-weight: 500;
`
export const DrugDetailsContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  & .drug-information {
    margin-bottom: 40px;
  }
  & div {
    display: flex;
    flex-direction: row;
    & .key {
      width: 200px;
    }
    & .value {
      text-align: left;
    }
  }
`
export const SearchListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  .autocomplete-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  > *:not(:last-child) {
    margin-bottom: 15px;
  }
`
export const InformationContainer = styled.div`
  border: 1px solid ${COLORS.gainsboro};
  padding: 15px;
  min-height: 100px;
  border-radius: 4px;
  margin-top: 10px;
`
export const StyledChip = styled(Chip)`
  margin: 10px;
`
export const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
  max-width: 400px;
  margin-right: 10px;
  & .MuiFormLabel-root.Mui-focused,
  .MuiOutlinedInput-root.Mui-focused {
    color: ${(props) => props.theme.palette.secondary.main};
  }
  && [class*='MuiOutlinedInput-root'] {
    padding-right: 9px;
  }
`
export const ViewLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 16px;
  font-weight: 500;
  font-family: ${FONTS.medium};
  letter-spacing: 0.37px;
  line-height: 22px;
  text-align: center;
`
export const ViewModalLink = styled.span`
  text-decoration: none;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 16px;
  font-weight: 500;
  font-family: ${FONTS.medium};
  letter-spacing: 0.37px;
  line-height: 22px;
  cursor: pointer;
  text-align: center;
`
export const Select = styled(TextField)`
  margin-bottom: 75px;
  max-width: 20em;
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
`
export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`
export const DinInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 64px 32px;
  & form {
    margin-top: 40px;
  }
`
