import React from 'react'

import { Tools } from '../../../shared/styled'
import { ISearchListProps } from '../../../models/tools'
import {
  Typography,
  TextField,
  InputAdornment,
  Checkbox,
  Chip,
  IconButton
} from '@material-ui/core'
import {
  SearchOutlined,
  CheckBoxOutlineBlank,
  CheckBox,
  AddCircle
} from '@material-ui/icons'
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete'
import { COLORS } from '../../../shared/theme'
import { BoldHeading } from 'src/shared/styled/Text'
import { IIndicationAndSideEffect } from 'src/models/program'

const {
  SearchListContainer,
  InformationContainer,
  StyledChip,
  StyledAutocomplete
} = Tools

const SearchList: React.FC<ISearchListProps> = ({
  title,
  options,
  values,
  setHasChanges,
  searchFn,
  setQuery,
  setNewValues,
  removeValue
}) => {
  const [textFieldInput, setTextFieldInput] = React.useState('')
  const [textFieldValues, setTextFieldValues] = React.useState<
    IIndicationAndSideEffect[]
  >([])

  React.useEffect(() => {
    searchFn()
  }, [searchFn, textFieldInput])

  return (
    <SearchListContainer>
      <BoldHeading>{title}</BoldHeading>
      <div className="autocomplete-container">
        <StyledAutocomplete
          multiple
          disableClearable
          disableCloseOnSelect
          id="search-list-autocomplete"
          value={textFieldValues}
          options={options || []}
          getOptionLabel={(option: IIndicationAndSideEffect) => option.name}
          getOptionSelected={(
            option: IIndicationAndSideEffect,
            value: IIndicationAndSideEffect
          ) => option.name === value.name}
          onChange={(_, val: any) => {
            setTextFieldValues(val)
          }}
          onInputChange={(_, value: string) => {
            setTextFieldInput(value)
            setQuery(value)
          }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              id="search-list-input"
              label={`Search ${title.toLowerCase()}`}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                type: 'search',
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchOutlined style={{ color: '#737373' }} />
                  </InputAdornment>
                )
              }}
            />
          )}
          renderTags={(value: IIndicationAndSideEffect[], getTagProps) =>
            value.map((option: IIndicationAndSideEffect, index: number) => (
              <Chip
                size="small"
                variant="outlined"
                label={option.name}
                {...getTagProps({ index })}
              />
            ))
          }
          renderOption={(option: IIndicationAndSideEffect, { selected }) => (
            <>
              <Checkbox
                icon={<CheckBoxOutlineBlank />}
                checkedIcon={<CheckBox />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </>
          )}
        />
        <div
          onClick={() => {
            setNewValues(textFieldValues)
            setTextFieldValues([])
            if (setHasChanges) {
              setHasChanges(true)
            }
          }}
        >
          <IconButton>
            <AddCircle htmlColor={COLORS.prussianBlue} />
          </IconButton>
        </div>
      </div>

      <InformationContainer>
        {values && values?.length > 0 ? (
          values?.map(({ id, name }) => (
            <StyledChip
              key={`value-${id}`}
              label={name}
              onDelete={() => {
                if (values.length > 1) {
                  if (setHasChanges) {
                    setHasChanges(true)
                  }
                  removeValue(id)
                }
              }}
            />
          ))
        ) : (
          <Typography>{`No ${title.toLowerCase()} provided`}</Typography>
        )}
      </InformationContainer>
    </SearchListContainer>
  )
}

export default SearchList
