import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { COLORS, FONTS } from '../theme'

export const StyledHeading = styled.h1`
  margin-bottom: 2em;
  color: rgba(0, 0, 0, 0.87);
  font-family: ${FONTS.medium};
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0.05px;
  line-height: 39px;
`

export const HeaderName = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-family: ${FONTS.main};
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.03px;
  text-transform: uppercase;
  line-height: 39px;
`

export const SubHeaderName = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-family: ${FONTS.medium};
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0.06px;
  line-height: 39px;
`

export const Heading = styled(Typography)`
  color: ${(props) => props.theme.palette.common.black};
  font-family: ${FONTS.medium};
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.82px;
  line-height: 26px;
  margin-bottom: 22px;
`

export const Summary = styled(Typography)`
  color: ${COLORS.matterhorn};
  font-family: ${FONTS.main};
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 26px;
`

export const TextLarge = styled(Typography)`
  color: ${COLORS.matterhorn};
  font-family: 'Gotham Book';
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
`
export const LightText = styled(Typography)`
  color: #808080;
  font-family: 'Gotham Book';
  font-size: 14px;
`

export const BoldHeading = styled(Typography)`
  color: ${COLORS.black};
  font-family: ${FONTS.medium};
  font-size: 16px;
`

export const LMSTextSectionDisplay = styled(TextLarge)`
  font-size: 20px;
  font-weight: normal;
  margin-top: 60px;
  line-height: 25px;
`
export const LMSTitleDisplay = styled(Typography)`
  color: ${COLORS.prussianBlue};
  font-family: 'Gotham Book';
  font-size: 25px;
`
export const LMSPreviewTitle = styled(Typography)`
  color: ${COLORS.prussianBlue};
  font-family: 'Gotham Book';
  font-size: 18px;
`
export const LMSPreviewText = styled(Typography)`
  color: #808080;
  font-size: 15px;
  font-weight: normal;
  line-height: 25px;
`
export const ErrorText = styled(Typography)`
  color: ${COLORS.warningRed};
  margin: 0;
  font-size: 12px;
  margin-top: 3px;
  text-align: left;
  font-family: 'Gotham Book';
  font-weight: 400;
  line-height: 1.66;
`
export const PageLevelHeading = styled(Typography)`
  font-family: ${FONTS.bold};
  font-size: 30px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  letter-spacing: 0.06px;
  line-height: 39px;
`

export const ErrorHeading = styled.h1`
  margin-bottom: 6px;
  color: ${COLORS.matterhorn};
  font-family: ${FONTS.medium};
  font-size: 48px;
  font-weight: 500;
  letter-spacing: 0.05px;
  line-height: 39px;
`
