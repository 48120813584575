import React from 'react'
import {
  useIndicationOrSideEffect,
  useUpdateIndicationOrSideEffect
} from 'src/shared/hooks/drugLibrary'
import { useSideEffectOrIndicationForm } from './forms'

interface useIndicationOrSideEffectModalProps {
  id: string
  type: 'indication' | 'side-effect'
}

const useIndicationOrSideEffectModal = ({
  id,
  type
}: useIndicationOrSideEffectModalProps) => {
  const form = useSideEffectOrIndicationForm()

  const {
    mutate: save,
    isSuccess: mutationSuccess,
    isLoading: saveLoading
  } = useUpdateIndicationOrSideEffect(type, id)

  const { data, isLoading } = useIndicationOrSideEffect(id, type)

  const [searchListChanges, setSearchListChanges] = React.useState(false)

  const setSubmitButtonActive = form.formState.isDirty || searchListChanges

  const title =
    data?.name ||
    `Add New ${type === 'indication' ? 'Indication' : 'Side Effect'}`

  return {
    data,
    isLoading,
    save,
    saveLoading,
    mutationSuccess,
    setSearchListChanges,
    setSubmitButtonActive,
    title,
    form
  }
}

export { useIndicationOrSideEffectModal }
