import Amplify, { Auth } from 'aws-amplify'

import { logger } from 'src/utils/logger'
import { ImagesApi } from '../client'

const AMPLIFY_CONFIG = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_AWS_OAUTH_DOMAIN,
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin'
    ],
    redirectSignIn: process.env.REACT_APP_AWS_AUTH_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_AWS_AUTH_REDIRECT_SIGN_OUT,
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_user_files_s3_bucket: process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET,
  aws_user_files_s3_bucket_region:
    process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET_REGION
}

export const initAmplify = () => {
  Amplify.configure(AMPLIFY_CONFIG)
}

export const signInWithGoogle = async () => {
  let result
  try {
    result = await Auth.federatedSignIn({ customProvider: 'GoogleSSO' })
  } catch (err) {
    logger.error('Error logging in', err)
  }
  return result
}

export const signOut = () => Auth.signOut()

export const getCurrentUser = () => Auth.currentAuthenticatedUser()

export const getCurrentSession = () => Auth.currentSession()

export const isUserAuthenticated = async () => {
  try {
    await getCurrentSession()
    return true
  } catch {
    return false
  }
}

export const uploadImage = async (
  imagePath: string,
  type: 'prescription' | 'learning' | 'access-code'
): Promise<string | void> => {
  try {
    const response = await fetch(imagePath)
    const blob = await response.blob()

    const dateId = Date.now()
    const randomId = Math.floor(Math.random() * 10000000)

    const fileName = `public/${type}-${dateId}-${randomId}.${
      blob.type.split('/')[1]
    }`

    const presignedUrl = await ImagesApi.putImagePresignedUrl(fileName)

    await ImagesApi.uploadImageToPresignedUrl(presignedUrl.presignedUrl, blob)

    return fileName
  } catch (err) {
    logger.error('Error uploading image', err)
  }
}

export const downloadImage = async (
  imageKey: string
): Promise<string | null> => {
  try {
    const res = await ImagesApi.getImagePresignedUrl(
      imageKey.replace('%2F', '/')
    )
    return res.presignedUrl
  } catch (err) {
    logger.error('Error downloading image', err)
    return null
  }
}
