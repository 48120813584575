import LearningModuleForm from '../views/LearningModuleForm'
import { LMSProvider } from 'src/contexts/Learning'

const CreateLearningModuleContainer = () => {
  return (
    <LMSProvider>
      <div style={{ height: '100%' }}>
        <LearningModuleForm />
      </div>
    </LMSProvider>
  )
}

export default CreateLearningModuleContainer
