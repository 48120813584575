import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router'

import {
  FrequencyEnum,
  IPrescription,
  IPrescriptionCreationForm
} from 'src/models/prescription'
import { UserAdminMatchParams } from 'src/models/routeParams'
import { useCreatePrescription } from 'src/shared/hooks/prescription'
import { DetailsContent } from 'src/shared/styled/Layout'
import { ImageUploader } from 'src/shared/views/ImageUploader'
import styled from 'styled-components'
import TextField from 'src/shared/views/TextField'
import AutoComplete from 'src/shared/views/AutoComplete'
import { useIndicationSearchOptions } from '../hooks/useIndicationSearchOptions'
import DrugSearch from '../containers/DrugSearch'
import { IDrugLibraryItem } from 'src/models/drugLibrary'
import { combineDrugIngredientsIntoValue } from 'src/utils/drugLib'
import { BoldHeading } from 'src/shared/styled/Text'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import { toast } from 'react-toastify'
import { Divider } from '@material-ui/core'
import { convertDateToFormattedString } from 'src/utils/dates'
import {
  DAYS_OF_WEEK_AUTOCOMPLETE_OPTIONS,
  DOSES_PER_DAY_DROPDOWN_ITEMS,
  FREQUENCY_DROPDOWN_ITEMS,
  INTERVAL_DAYS_DROPDOWN_ITEMS
} from 'src/constants/prescriptions'
import { DropDown } from 'src/shared/views/DropDown'
import { hasTimeDuplication } from 'src/utils/validation'
import { generateDosingArray } from 'src/utils/prescription'
import { uploadImage } from 'src/api/services/amplify'

interface IPrescriptionCreationFormProps {
  prescription?: IPrescription
  canCurrentUserWrite: boolean
  setPrescription: React.Dispatch<
    React.SetStateAction<IPrescription | undefined>
  >
}

const FormFieldsRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const MarginDivider = styled(Divider)`
  margin: 1em 0 2em 0;
`

const ScheduleRow = styled.div`
  display: flex;
`

const DoseTimesRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: 'space-between';
`

const HeadingTitle = styled(BoldHeading)`
  margin-bottom: 30px;
`

const HiddenDiv = styled.div``

const formFieldStyles = {
  marginBottom: '30px',
  marginRight: '0',
  minWidth: '200px',
  width: '50%',
  maxWidth: '230px'
}

const scheduleFieldStyles = { ...formFieldStyles, marginRight: '47px' }

const PrescriptionCreationForm: React.FC<IPrescriptionCreationFormProps> = ({
  canCurrentUserWrite,
  setPrescription
}) => {
  const [conditionSearchName, setConditionSearchName] = React.useState('')

  const { id: userId } = useParams<UserAdminMatchParams>()

  const { createPrescription, isLoading, isSuccess, isError, error, data } =
    useCreatePrescription(userId)

  const { control, watch, setError, clearErrors, setValue, handleSubmit } =
    useForm<IPrescriptionCreationForm>()

  watch()

  const imageUrl = watch('imageUrl')
  const din = watch('din')
  const frequency = watch('frequency')
  const doseTimes = watch('doseTimes')
  const dosesPerDay = watch('dosesPerDay')

  const { conditionAutoCompleteValues } = useIndicationSearchOptions({
    searchQuery: conditionSearchName
  })

  /**
   * ----- Functions -----
   */

  const onHandleImageChange = (e: any) => {
    const imageFile = e?.target?.files && e?.target?.files[0]

    if (!imageFile) return

    if (!['image/png', 'image/jpg', 'image/jpeg'].includes(imageFile.type)) {
      setError('imageUrl', { message: 'File must be a png or jpeg' })
    } else {
      clearErrors('imageUrl')
      const img = URL.createObjectURL(imageFile)
      setValue('imageUrl', img)
    }
  }

  const onClearImage = () => {
    setValue('imageUrl', '')
  }

  const setDrugLibraryValue = (drug: IDrugLibraryItem) => {
    setValue('medicationName', drug.brandName)
    setValue('din', drug.din.value)
    setValue('unitType', drug.pharmaceuticalForm)
    setValue(
      'doseStrength',
      combineDrugIngredientsIntoValue(drug.activeIngredients)
    )
  }

  const setDoseTime = React.useCallback(
    (time: string, i: number) => {
      const existingValues = doseTimes ? [...doseTimes] : []
      existingValues[i] = time

      setValue('doseTimes', existingValues)
    },
    [doseTimes, setValue]
  )

  const submitForm = async (data: IPrescriptionCreationForm) => {
    let image
    if (imageUrl) {
      const uploadedImageUrl = await uploadImage(imageUrl, 'prescription')
      if (uploadedImageUrl) image = uploadedImageUrl
    }

    createPrescription({
      ...data,
      condition: data.condition.value,
      imageUrl: image,
      dosing: generateDosingArray(
        frequency,
        dosesPerDay,
        doseTimes,
        data.daysOfWeek,
        data.everyNthDay
      )
    })
  }

  /**
   * ----- Variables -----
   */

  const renderedDoseTimes = React.useMemo(() => {
    const renderedDoseTimes = []
    if (!!dosesPerDay && dosesPerDay > 0) {
      for (let i = 0; i < dosesPerDay; i++) {
        renderedDoseTimes.push(
          <TextField
            key={i}
            style={{ width: '155px', marginBottom: '30px' }}
            name={`doseTimes[${i}]`}
            label={`Dose ${i + 1}`}
            value={doseTimes && doseTimes[i]}
            InputLabelProps={{ shrink: true }}
            type="time"
            placeholder="ex. 10:00am"
            onChange={(e) => setDoseTime(e.target.value, i)}
            disabled={!canCurrentUserWrite}
          />
        )
      }
    }
    return renderedDoseTimes
  }, [canCurrentUserWrite, doseTimes, dosesPerDay, setDoseTime])

  /**
   * ----- Use-effects and other logic
   */

  React.useEffect(() => {
    if (!isLoading && isSuccess) {
      if (data) {
        setPrescription(data)
      }

      toast.success('Successfully added a new prescription')
    } else if (!isLoading && isError) {
      toast.error(`${error}`)
    }
  }, [isLoading, isSuccess, isError, error, data, setPrescription])

  return (
    <DetailsContent>
      {canCurrentUserWrite && (
        <>
          <HeadingTitle>Select Medication</HeadingTitle>
          <DrugSearch
            onItemSelected={setDrugLibraryValue}
            selectedDins={[din]}
          />
        </>
      )}
      <Controller
        defaultValue=""
        render={({ fieldState: { error } }) => (
          <ImageUploader
            id="imageUrl"
            label="Upload Prescription"
            disableUploading={!canCurrentUserWrite}
            onHandleImageChange={onHandleImageChange}
            onClearImage={onClearImage}
            error={error?.message}
            imagePath={imageUrl}
          />
        )}
        name="imageUrl"
        control={control}
      />
      <HeadingTitle>Details</HeadingTitle>
      <FormFieldsRow>
        <Controller
          defaultValue=""
          render={({ field: { ref, ...props } }) => (
            <TextField
              style={formFieldStyles}
              label="Medication Name"
              inputRef={ref}
              placeholder="Ex. Gabapentin"
              InputLabelProps={{ shrink: true }}
              {...props}
              disabled
            />
          )}
          name="medicationName"
          control={control}
        />
        <Controller
          defaultValue=""
          render={({ field: { ref, ...props } }) => (
            <TextField
              style={formFieldStyles}
              label="DIN"
              placeholder="Ex. 012345678"
              inputRef={ref}
              InputLabelProps={{ shrink: true }}
              {...props}
              disabled
            />
          )}
          name="din"
          control={control}
        />
        <Controller
          defaultValue=""
          render={({ field: { ref, ...props } }) => (
            <TextField
              style={formFieldStyles}
              label="Appearance"
              placeholder="Ex. Capsule"
              inputRef={ref}
              InputLabelProps={{ shrink: true }}
              {...props}
              disabled
            />
          )}
          name="unitType"
          control={control}
        />
      </FormFieldsRow>
      <FormFieldsRow>
        <Controller
          defaultValue=""
          render={({ field: { ref, ...props } }) => (
            <TextField
              style={formFieldStyles}
              label="Dose Strength"
              placeholder="Ex. 120 mg"
              inputRef={ref}
              InputLabelProps={{ shrink: true }}
              {...props}
              disabled
            />
          )}
          name="doseStrength"
          control={control}
        />
      </FormFieldsRow>
      <MarginDivider />
      <FormFieldsRow>
        <Controller
          defaultValue={{ value: '' }}
          render={({ field: { ...props } }) => (
            <AutoComplete
              style={formFieldStyles}
              inputId="condition"
              label="Condition"
              onKeyDown={(e) => {
                // eslint-disable-next-line
                // @ts-ignore
                setConditionSearchName(e.target.value)
              }}
              options={conditionAutoCompleteValues}
              {...props}
              onChange={(_e, v) => setValue('condition', { value: v[0] })}
              disabled={!canCurrentUserWrite}
            />
          )}
          name="condition"
          control={control}
        />
      </FormFieldsRow>
      <MarginDivider />
      <FormFieldsRow>
        <Controller
          defaultValue={convertDateToFormattedString()}
          render={({ field: { ...props } }) => (
            <TextField
              style={formFieldStyles}
              type="date"
              label="Date Medication Started"
              InputLabelProps={{ shrink: true }}
              {...props}
              disabled={!canCurrentUserWrite}
            />
          )}
          control={control}
          name="startedOn"
        />
        <Controller
          defaultValue={undefined}
          render={({ field: { ref, ...props } }) => (
            <DropDown
              items={FREQUENCY_DROPDOWN_ITEMS}
              label="How often is it taken?"
              style={formFieldStyles}
              inputRef={ref}
              {...props}
              disabled={!canCurrentUserWrite}
            />
          )}
          control={control}
          name="frequency"
        />
        <Controller
          defaultValue={1}
          render={({ field: { ref, ...props } }) => (
            <TextField
              label="How many units per dose?"
              style={formFieldStyles}
              type="number"
              inputRef={ref}
              {...props}
              disabled={!canCurrentUserWrite}
              noNegative
            />
          )}
          control={control}
          name="unitsPerDose"
        />
      </FormFieldsRow>
      <ScheduleRow>
        <Controller
          defaultValue={1}
          render={({ field: { ref, ...props } }) => (
            <>
              {frequency && (
                <DropDown
                  items={DOSES_PER_DAY_DROPDOWN_ITEMS}
                  label="How many times a day?"
                  style={scheduleFieldStyles}
                  inputRef={ref}
                  {...props}
                  disabled={!canCurrentUserWrite}
                />
              )}
            </>
          )}
          control={control}
          name="dosesPerDay"
        />
        <Controller
          defaultValue={[]}
          render={({ field: { ...props } }) => (
            <>
              {frequency === FrequencyEnum.Specific && (
                <AutoComplete
                  style={scheduleFieldStyles}
                  inputId="daysOfWeek"
                  label="Days of Week"
                  multiple
                  options={DAYS_OF_WEEK_AUTOCOMPLETE_OPTIONS}
                  {...props}
                  onChange={(_e, v) =>
                    setValue('daysOfWeek', [{ value: v[0] }])
                  }
                  disabled={!canCurrentUserWrite}
                />
              )}
            </>
          )}
          name="daysOfWeek"
          control={control}
        />
        <Controller
          defaultValue={1}
          render={({ field: { ...props } }) => (
            <>
              {frequency === FrequencyEnum.Interval && (
                <DropDown
                  style={scheduleFieldStyles}
                  label="How many interval days?"
                  items={INTERVAL_DAYS_DROPDOWN_ITEMS}
                  {...props}
                  disabled={!canCurrentUserWrite}
                />
              )}
            </>
          )}
          name="everyNthDay"
          control={control}
        />
      </ScheduleRow>
      <Controller
        render={HiddenDiv}
        name="doseTimes"
        control={control}
        defaultValue={[]}
      />
      <DoseTimesRow>
        {renderedDoseTimes}

        {doseTimes &&
          dosesPerDay &&
          hasTimeDuplication(doseTimes, dosesPerDay) && (
            <p style={{ color: 'red' }}>Cannot have duplicate times</p>
          )}
      </DoseTimesRow>
      <MarginDivider />
      <FormFieldsRow>
        <Controller
          defaultValue={1}
          render={({ field: { ref, ...props } }) => (
            <TextField
              label="How many units on hand?"
              style={formFieldStyles}
              type="number"
              inputRef={ref}
              {...props}
              disabled={!canCurrentUserWrite}
              noNegative
            />
          )}
          control={control}
          name="unitsInHand"
        />
      </FormFieldsRow>
      {canCurrentUserWrite && (
        <ButtonWithWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit(submitForm)}
          isLoading={isLoading}
        >
          Create
        </ButtonWithWidth>
      )}
    </DetailsContent>
  )
}

export default PrescriptionCreationForm
