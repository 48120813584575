import { Tools } from 'src/shared/styled'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import { useGetCommercialCodes } from 'src/shared/hooks/tools'
import CommercialCodesView from '../views/CommercialCodes'

const { ToolsViewContainer } = Tools

const CommercialCodes = () => {
  const { isLoading, error, commercialCodes } = useGetCommercialCodes()

  if (error) return <div>{`An error has occurred`}</div>
  if (!isLoading && commercialCodes)
    return (
      <ToolsViewContainer>
        <CommercialCodesView commercialCodes={commercialCodes || []} />
      </ToolsViewContainer>
    )

  return <LoadingScreen />
}

export default CommercialCodes
