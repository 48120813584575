import React from 'react'

import { useLMS } from 'src/contexts/Learning'
import PhoneMockup from './PhoneMockup'
import { Learning, Text } from 'src/shared/styled'
import { ILMSImageSection, LMSSection } from 'src/models/learning'
import { downloadImage } from 'src/api/services/amplify'

const { PreviewContainer, ProgressBar, QuestionDisplayContainer } = Learning
const { LMSPreviewTitle, LMSPreviewText } = Text

const DisplayImage: React.FC<{ section: ILMSImageSection }> = ({ section }) => {
  const [imageToDisplay, setImageToDisplay] = React.useState<any>('')
  React.useEffect(() => {
    const getImage = async () => {
      if (section.imageUrl) {
        const remoteImageUrl = await downloadImage(section.imageUrl)
        setImageToDisplay(remoteImageUrl)
      }
    }

    getImage()
  }, [section.imageUrl])

  return <img className="section" src={imageToDisplay} />
}

const PreviewLearningModule = () => {
  const { state } = useLMS()
  const [currentPage, setCurrentPage] = React.useState<number>(0)
  const { pages } = state

  let pageContent: any[] = []
  if (pages[currentPage]?.sections.length > 0) {
    const populatedSections = pages[currentPage].sections as LMSSection[]
    pageContent = populatedSections.map((section) => {
      if ('text' in section) {
        return (
          <LMSPreviewText className="section" key={`${section.id}`}>
            {section.text}
          </LMSPreviewText>
        )
      } else if ('question' in section) {
        return (
          <QuestionDisplayContainer className="section" key={`${section.id}`}>
            <LMSPreviewText className="question-text">{`${section.question.questionText}`}</LMSPreviewText>
            <div className="options">
              {section.question.answerOptions.map((option) => (
                <div className="option">
                  <div className="circle" />
                  <LMSPreviewText>{option.text}</LMSPreviewText>
                </div>
              ))}
            </div>
          </QuestionDisplayContainer>
        )
      } else {
        return <DisplayImage section={section} key={section.id} />
      }
    })
  }
  return (
    <PreviewContainer>
      {pages.length ? (
        <PhoneMockup
          nextClick={() => {
            if (currentPage !== pages.length - 1) {
              setCurrentPage(currentPage + 1)
            }
          }}
        >
          <div className="content">
            <ProgressBar pages={pages.length} currentPage={currentPage + 1} />
            <div className="header">
              <LMSPreviewTitle className="title">
                {pages[currentPage].header}
              </LMSPreviewTitle>
              <LMSPreviewText>{pages[currentPage].description}</LMSPreviewText>
            </div>
            <div>{pageContent}</div>
          </div>
        </PhoneMockup>
      ) : (
        <LMSPreviewText>
          To see a preview start by adding pages to the module
        </LMSPreviewText>
      )}
    </PreviewContainer>
  )
}

export default PreviewLearningModule
