import React, { FC } from 'react'
import { DateTime } from 'luxon'

import { User, Text } from 'src/shared/styled'
import { LONG_DATE_TIME_FORMAT } from 'src/constants/datetimes'
import { IUser, UserStatusEnum } from 'src/models/user'
import styled from 'styled-components'
import {
  Checkbox,
  FormControlLabel,
  Typography,
  TableRow
} from '@material-ui/core'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  getFacetedMinMaxValues,
  getSortedRowModel,
  getPaginationRowModel
} from '@tanstack/react-table'
import DebouncedInput from 'src/components/DebounceInput'
import { fuzzyFilter, fuzzySort } from 'src/utils/table'
import {
  FocusedTableCell,
  HoverableTableRow,
  StyledTable,
  TableHeaderCell
} from 'src/shared/styled/Table'
import { useHistory } from 'react-router'

const { Header, StyledChip } = User
const { PageLevelHeading } = Text

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  align-self: flex-start;
`

interface IUserAdminView {
  data: IUser[]
}

interface ITableData extends IUser {
  userName: string
  programs: string
}

const UserAdminView: FC<IUserAdminView> = ({ data }) => {
  const history = useHistory()

  const [showDeleted, setShowDeleted] = React.useState(false)
  const [globalFilter, setGlobalFilter] = React.useState('')

  const userData: ITableData[] = React.useMemo(() => {
    let copy = [...data]

    if (!showDeleted) {
      copy = copy.filter((user) => {
        if (user.status === UserStatusEnum.Deleted) return false
        else return true
      })
    }

    return copy?.map((u) => ({
      userName: `${u.lastName}, ${u.firstName} `,
      programs: u.programMemberships
        .map((program) => program.programName)
        .join(', '),
      ...u
    }))
  }, [data, showDeleted])

  const columns = React.useMemo<ColumnDef<ITableData, any>[]>(
    () => [
      {
        header: 'ID',
        accessorKey: 'userId'
      },
      {
        header: 'User Name',
        size: 180,
        accessorKey: 'userName'
      },
      {
        header: 'Phone',
        accessorKey: 'phoneNumber'
      },
      {
        header: '# of RXs',
        cell: ({ row }: any) => {
          return `${row.original.programMemberships.length}`
        },
        size: 105
      },
      {
        header: 'Programs',
        accessorKey: 'programs',
        size: 180
      },
      {
        header: 'Joined',
        accessorKey: 'createdAt',
        size: 240,
        cell: ({ row }: any) => {
          const dateISO = DateTime.fromJSDate(
            new Date(row.original.createdAt)
          ).toISO()
          const formattedDate = DateTime.fromISO(dateISO).toFormat(
            LONG_DATE_TIME_FORMAT
          )
          return `${formattedDate}`
        },
        sortingFn: fuzzySort
      },
      {
        header: 'Status',
        accessorKey: 'status',
        size: 120,
        cell: ({ row }: any) => {
          return (
            <StyledChip
              label={row.original.status}
              color={
                row.original.status === 'Active'
                  ? 'primary'
                  : row.original.status === 'Inactive'
                  ? 'default'
                  : 'secondary'
              }
            />
          )
        }
      }
    ],
    []
  )

  const table = useReactTable({
    columns,
    data: userData,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      globalFilter,
      columnVisibility: {
        userId: false
      }
    },
    initialState: {
      sorting: [{ id: 'createdAt', desc: true }],
      pagination: {
        pageIndex: 0,
        pageSize: 25
      }
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  })

  return (
    <div style={{ padding: 50 }}>
      <Header>
        <PageLevelHeading>Users</PageLevelHeading>
      </Header>
      <StyledFormControlLabel
        control={
          <Checkbox
            checked={showDeleted}
            onChange={(e) => setShowDeleted(e.target.checked)}
          />
        }
        label={<Typography>Show Deleted</Typography>}
      />
      <DebouncedInput
        value={globalFilter ?? ''}
        onChange={(value) => setGlobalFilter(String(value))}
        placeholder="Search Users"
      />
      <StyledTable>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeaderCell
                  key={header.id}
                  style={{ width: header.getSize() }}
                >
                  <>
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                    {header.column.getCanFilter() ? (
                      <DebouncedInput
                        type="text"
                        value={(header.column.getFilterValue() ?? '') as string}
                        onChange={(value) =>
                          header.column.setFilterValue(value)
                        }
                        placeholder={`Search...`}
                        list={header.column.id + 'list'}
                      />
                    ) : null}
                  </>
                </TableHeaderCell>
              ))}
            </TableRow>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <HoverableTableRow
                key={row.id}
                onClick={() => history.push(`/users/${row.original.userId}`)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <FocusedTableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </FocusedTableCell>
                  )
                })}
              </HoverableTableRow>
            )
          })}
        </tbody>
      </StyledTable>
      <div className="flex items-center gap-2">
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
      </div>
    </div>
  )
}

export default UserAdminView
