import React from 'react'

import { useParams } from 'react-router'
import { Create, Delete } from '@material-ui/icons'
import { Column, CellProps } from 'react-table'
import { UserAdminMatchParams } from 'src/models/routeParams'
import { IDirectDeposit } from 'src/models/user'
import { useDeleteDirectDeposit } from 'src/shared/hooks/users'
import { ViewModalLink } from 'src/shared/styled/Tools'
import Modal from 'src/shared/views/Modal'
import Table from 'src/shared/views/Table/Table'
import styled from 'styled-components'
import DirectDepositForm from './DirectDepositForm'
import { HeaderName } from 'src/shared/styled/Text'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'

const Container = styled.div`
  padding: 1em;
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

interface IDirectDepositProps {
  directDeposits: IDirectDeposit[]
}

const DirectDeposit = ({ directDeposits }: IDirectDepositProps) => {
  const { id: userId } = useParams<UserAdminMatchParams>()

  const [modalDepositId, setModalDepositId] = React.useState<string>()
  const [addModal, setAddModal] = React.useState(false)

  const { deleteDirectDeposit } = useDeleteDirectDeposit(userId)

  const closeModal = () => {
    setModalDepositId(undefined)
  }

  const columns: Column[] = React.useMemo(
    () => [
      { Header: 'Label', accessor: 'label' },
      { Header: 'Institution', accessor: 'institutionNumber' },
      { Header: 'Transit', accessor: 'transitNumber' },
      { Header: 'Account', accessor: 'accountNumber' },
      {
        Header: 'Edit',
        Cell: ({
          row
        }: React.PropsWithChildren<CellProps<IDirectDeposit, any>>) => {
          return (
            <div>
              <ViewModalLink
                onClick={() => {
                  setModalDepositId(row.original.directDepositInfoId)
                }}
              >
                <Create />
              </ViewModalLink>
              <ViewModalLink
                onClick={() => {
                  if (window.confirm('Are you sure?'))
                    deleteDirectDeposit(row.original.directDepositInfoId)
                }}
              >
                <Delete />
              </ViewModalLink>
            </div>
          )
        }
      }
    ],
    [deleteDirectDeposit]
  )

  return (
    <Container>
      <FlexRow>
        <HeaderName>Direct Deposits</HeaderName>

        <ButtonWithMargin
          variant="contained"
          color="primary"
          onClick={() => setAddModal(true)}
        >
          Add Direct Deposit Info
        </ButtonWithMargin>
      </FlexRow>
      <Table columns={columns} data={directDeposits} />

      <Modal isOpen={!!modalDepositId} handleClose={closeModal}>
        {!!modalDepositId && (
          <DirectDepositForm
            existingDirectDepositId={modalDepositId}
            defaultValues={directDeposits.find(
              (directDeposit) =>
                directDeposit.directDepositInfoId === modalDepositId
            )}
            closeModal={closeModal}
          />
        )}
      </Modal>
      <Modal isOpen={addModal} handleClose={() => setAddModal(false)}>
        <DirectDepositForm closeModal={() => setAddModal(false)} />
      </Modal>
    </Container>
  )
}

export default DirectDeposit
