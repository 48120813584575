import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { sentryInit } from './api/services/sentry'
import './index.css'
import App from './App'
import './assets/fonts/Gotham-Bold.otf'
import './assets/fonts/Gotham-Book.otf'
import './assets/fonts/Gotham-Medium.otf'
import './assets/fonts/helveticaneue-regular.ttf'
import { UserProvider } from './contexts/User'

const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true
    }
  }
})

sentryInit()

ReactDOM.render(
  <QueryClientProvider client={client}>
    <BrowserRouter>
      <UserProvider>
        <App />
      </UserProvider>
    </BrowserRouter>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById('root')
)
