import { FC } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ButtonWithMargin } from '../styled/Buttons'

import { ErrorHeading, Summary } from '../styled/Text'

const ErrorContainer = styled.div`
  /* flex: 1; */
  /* display: flex; */
  margin-top: 50px;
  justify-content: center;
  text-align: center;
`

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.common.white};
  text-decoration: none;
`

const ErrorScreen: FC = () => {
  const refreshPage = () => {
    window.location.reload()
  }

  return (
    <ErrorContainer>
      <ErrorHeading>SOMETHING WENT WRONG</ErrorHeading>
      <Summary>
        We are experiencing a problem, and we are trying our best to fix it.
      </Summary>
      <div style={{ marginTop: '3em' }}>
        <ButtonWithMargin>
          <StyledLink to="/">BACK TO HOME</StyledLink>
        </ButtonWithMargin>
        <ButtonWithMargin onClick={refreshPage}>REFRESH</ButtonWithMargin>
      </div>
    </ErrorContainer>
  )
}

export default ErrorScreen
