import { useRouteMatch, withRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { StylesProvider } from '@material-ui/styles'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { CssBaseline, Grid } from '@material-ui/core'

import Header from './shared/views/Navigation/Header'
// import SubHeader from './shared/views/SubHeader'
import Routes, { RenderRoutes } from './shared/navigation/Routes'
import theme from './shared/theme'
import Drawer from './shared/views/Drawer'
import { PROGRAM_BACK_BUTTON, PROGRAM_NAV_STEPS } from './constants/program'
import { USER_BACK_BUTTON, USER_NAV_STEPS } from './constants/user'
import { TOOLS_NAV_STEPS } from './constants/tools'
import { initAmplify } from './api/services/amplify'

import 'react-toastify/dist/ReactToastify.css'
import { toast, ToastContainer } from 'react-toastify'
import { FilterFn } from '@tanstack/react-table'
import { RankingInfo } from '@tanstack/match-sorter-utils'

initAmplify()
export interface MatchParams {
  id: string
}

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const App = withRouter(({ location }) => {
  const userMatch = useRouteMatch<MatchParams>('/users/:id')
  const programMatch = useRouteMatch<MatchParams>('/programs/:id/:string')
  const toolsMatch = useRouteMatch<MatchParams>('/tools')

  const isUserPath = userMatch && userMatch.path === '/users/:id'
  const isProgramPath =
    programMatch && programMatch.path === '/programs/:id/:string'
  const isToolsPath = toolsMatch && toolsMatch.path === '/tools'
  return (
    <StylesProvider injectFirst>
      <CssBaseline />
      <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <Grid container direction="column">
            {location.pathname !== '/login' && <Header />}
            <div
              style={{
                display:
                  isUserPath || isProgramPath || isToolsPath ? 'flex' : '',
                flexDirection: 'row'
              }}
            >
              {isProgramPath && (
                <Drawer
                  backButton={PROGRAM_BACK_BUTTON}
                  navigationSteps={PROGRAM_NAV_STEPS}
                  match={programMatch}
                />
              )}
              {isUserPath && (
                <Drawer
                  backButton={USER_BACK_BUTTON}
                  navigationSteps={USER_NAV_STEPS}
                  match={userMatch}
                />
              )}
              {isToolsPath && (
                <Drawer navigationSteps={TOOLS_NAV_STEPS} match={userMatch} />
              )}
              <RenderRoutes routes={Routes} />
            </div>
          </Grid>
        </MuiThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  )
})

export default App
