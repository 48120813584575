import { FC } from 'react'
import { useParams } from 'react-router-dom'

import {
  ProgramMatchParams,
  UserAdminMatchParams
} from 'src/models/routeParams'
import { useProgram } from 'src/shared/hooks/program'
import {
  useUserCompletedTasks,
  useUserTransactionsForProgram
} from 'src/shared/hooks/users'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import UserProgramView from '../views/UserProgram'

const UserProgram: FC = () => {
  const { id: userId } = useParams<UserAdminMatchParams>()
  const { programId } = useParams<ProgramMatchParams>()

  const {
    completedTasks,
    isLoading: tasksIsLoading,
    error: tasksError
  } = useUserCompletedTasks(userId, programId)

  const {
    transactions,
    isLoading: transactionsIsLoading,
    error: transactionsError
  } = useUserTransactionsForProgram(userId, programId)

  const {
    data: program,
    isLoading: programIsLoading,
    error: programError
  } = useProgram(programId)

  if (tasksError || transactionsError || programError)
    return <div>Something went wrong...</div>
  if (
    !tasksIsLoading &&
    completedTasks &&
    !transactionsIsLoading &&
    transactions &&
    !programIsLoading &&
    program
  )
    return (
      <div style={{ flexDirection: 'column', width: '100%' }}>
        <UserProgramView
          data={completedTasks}
          transactions={transactions}
          programName={program.name}
          programId={programId}
        />
      </div>
    )

  return <LoadingScreen />
}

export default UserProgram
