import styled from 'styled-components'
import { Drawer, ListItem, Toolbar } from '@material-ui/core'

import { FONTS } from '../theme'

export const StyledDrawer = styled(Drawer)`
  width: 240px;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.palette.primary.main};
  .MuiDrawer-paperAnchorDockedLeft {
    border-right: none;
  }
  & .MuiPaper-root {
    background-color: ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.common.white};
    width: 240px;
  }
`

export const StyledToolbar = styled(Toolbar)`
  height: ${(props) => `${props.theme.mixins.toolbar.minHeight}px`};
`
export const BackButtonPlaceHolder = styled.div`
  height: 75px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
`
export const StyledListItem = styled(ListItem)`
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding-top: 10px;
  padding-bottom: 10px;
  &.active {
    background-color: ${(props) => props.theme.palette.primary.dark};
    & .MuiListItemText-primary {
      font-family: ${FONTS.medium};
      color: ${(props) => props.theme.palette.common.white};
    }
  }
` as typeof ListItem
