import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { UserAdminMatchParams } from 'src/models/routeParams'
import UnblockUserView from '../views/UnblockUser'
import { useUnblockUser } from 'src/shared/hooks/users'

interface IUnblockUserContainer {
  closeModal: () => void
}

const UnblockUserContainer: FC<IUnblockUserContainer> = ({ closeModal }) => {
  const { id: userId } = useParams<UserAdminMatchParams>()

  const unblockUser = useUnblockUser(userId, { onSuccess: () => closeModal })

  return (
    <UnblockUserView
      closeModal={closeModal}
      unblockUserMutation={unblockUser}
    />
  )
}

export default UnblockUserContainer
