import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

const CommercialCodeSchema = yup.object().shape({
  description: yup.string().required(),
  userAmountPerUse: yup.number().required()
})

export const useCommercialCodeForm = (options?: any) => {
  const form = useForm({
    resolver: yupResolver(CommercialCodeSchema),
    ...options
  })

  return { ...form }
}
