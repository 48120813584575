import { useParams } from 'react-router-dom'

import { UserAdminMatchParams } from 'src/models/routeParams'
import { useCreateUserNote, useUserNotes } from 'src/shared/hooks/users'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import UserNotesView from '../views/UserNotes'

const UserNotesContainer = () => {
  const { id: userId } = useParams<UserAdminMatchParams>()

  const { notes, isLoading, error } = useUserNotes(userId)

  const createNote = useCreateUserNote(userId)

  if (error) return <p>There was an error fetching notes</p>
  if (!isLoading && notes)
    return <UserNotesView createNoteMutation={createNote} notes={notes} />

  return <LoadingScreen />
}

export default UserNotesContainer
