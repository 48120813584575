import { Avatar, Button } from '@material-ui/core'
import { useMemo } from 'react'

import { Check } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import Table from '../../../shared/views/Table/Table'
import styled from 'styled-components'
import { FONTS } from 'src/shared/theme'

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2em;
`

const EditPermissionsButton = styled(Button)`
  font-family: ${FONTS.medium};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.34px;
  line-height: 24px;
  padding: 8px 22px;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
    color: ${(props) => props.theme.palette.common.white};
  }
`

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.common.white};
  text-decoration: none;
`

const AdminNameContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

interface UserAdmin {
  id: string
  administratorName: string
  position: string
  patientManagement: boolean
  programAdministration: boolean
  adjudicationManagement: boolean
  editBCAUsers: boolean
}

const SettingsView = () => {
  // const [loadedItemsState, setLoadedItemsState] = useState<{
  //     hasNextPage: boolean;
  //     items: UserAdmin[];
  // }>({
  //     hasNextPage: true,
  //     items: [],
  // });

  // const [scrollState, setScrollState] = useState({
  //     rowIndex: 0,
  //     columnIndex: 0,
  // });

  // let loadMoreItems = (): Promise<any> => {
  // return new Promise(() =>
  //     setTimeout(() => {
  //     setLoadedItemsState({
  //         hasNextPage: loadedItemsState.items.length < ITEMS_TO_LOAD_COUNT,
  //         items: [...loadedItemsState.items].concat(
  //         new Array(10).fill(true).map(() => ({
  //             id: Faker.random.uuid(),
  //             administratorName: 'John Doe',
  //             position: 'Customer Service',
  //             patientManagement: true,
  //             programAdministration: true,
  //             adjudicationManagement: true,
  //             editBCAUsers: false
  //         }))
  //         ),
  //     });
  //     }, 1500)
  // );
  // };

  // // the item is loaded if either 1) there are no more pages or 2) there exists an item at that index
  // let isItemLoaded = (index: number) =>
  // !loadedItemsState.hasNextPage || !!loadedItemsState.items[index];

  // const setScrollRowAndColumn = useCallback(
  // (rowIndex: number, columnIndex: number) => {
  //     setScrollState({ rowIndex, columnIndex });
  // },
  // []
  // );

  // const { hasNextPage, items } = loadedItemsState;
  const columns = useMemo(
    () => [
      {
        Header: 'Administrator Name',
        accessor: 'administratorName',
        Cell: ({ row }: any) => (
          <AdminNameContainer>
            <Avatar alt="Admin Avatar" src="../../../../public/logo192.png" />
            {row.original.administratorName}
          </AdminNameContainer>
        )
      },
      {
        Header: 'Position',
        accessor: 'position',
        disableSortBy: true
      },
      {
        Header: 'Patient Management',
        accessor: 'patientManagement',
        Cell: ({ row }: any) => (
          <>{row.original.patientManagement && <Check fontSize="large" />}</>
        ),
        disableSortBy: true
      },
      {
        Header: 'Program Administration',
        accessor: 'programAdministration',
        Cell: ({ row }: any) => (
          <>
            {row.original.programAdministration && <Check fontSize="large" />}
          </>
        ),
        disableSortBy: true
      },
      {
        Header: 'Adjudication Management',
        accessor: 'adjudicationManagement',
        Cell: ({ row }: any) => (
          <>
            {row.original.adjudicationManagement && <Check fontSize="large" />}
          </>
        ),
        disableSortBy: true
      },
      {
        Header: 'Edit BCA Users',
        accessor: 'editBCAUsers',
        Cell: ({ row }: any) => (
          <>{row.original.editBCAUsers && <Check fontSize="large" />}</>
        ),
        disableSortBy: true
      }
    ],
    []
  )

  const userAdmins: UserAdmin[] = []
  userAdmins.push({
    id: '001',
    administratorName: 'Adrian Stefan',
    position: 'Customer Service',
    patientManagement: true,
    programAdministration: false,
    adjudicationManagement: false,
    editBCAUsers: false
  })
  userAdmins.push({
    id: '002',
    administratorName: 'John Smith',
    position: 'Manager',
    patientManagement: false,
    programAdministration: true,
    adjudicationManagement: true,
    editBCAUsers: false
  })
  userAdmins.push({
    id: '003',
    administratorName: 'Adrian Stefan',
    position: 'Customer Service',
    patientManagement: true,
    programAdministration: false,
    adjudicationManagement: false,
    editBCAUsers: false
  })

  return (
    <div style={{ padding: 50 }}>
      <Header>
        <h1>Administrator Settings</h1>
        <div style={{ alignSelf: 'center' }}>
          <EditPermissionsButton variant="contained" color="primary">
            <StyledLink to="/programs/create">EDIT PERMISSIONS</StyledLink>
          </EditPermissionsButton>
        </div>
      </Header>
      <Table
        columns={columns}
        data={userAdmins}
        placeholder="Search Administrators"
      />
      {/* <Table 
                columns={columns} 
                data={items} 
                placeholder='Search Administrators'
                hasNextPage={hasNextPage}
                items={items}
                loadMoreItems={loadMoreItems}
                isItemLoaded={isItemLoaded}
                scrollState={scrollState}
                setScrollRowAndColumn={setScrollRowAndColumn}
            /> */}
    </div>
  )
}

export default SettingsView
