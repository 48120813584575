import React, { FC, useMemo } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { FONTS } from 'src/shared/theme'
import Button from 'src/shared/views/Button'
import { Text } from 'src/shared/styled'
import { UserPermissionsEnum } from 'src/models/user'
import { usePermissions } from 'src/shared/hooks/usePermissions'
import { IProgram } from 'src/models/program'
import truncateString from 'src/utils/truncateString'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { fuzzyFilter } from 'src/utils/table'
import DebouncedInput from 'src/components/DebounceInput'
import {
  FocusedTableCell,
  HoverableTableRow,
  StyledTable,
  TableHeaderCell
} from 'src/shared/styled/Table'
import { TableRow } from '@material-ui/core'

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2em;
`

export const AddProgramButton = styled(Button)`
  font-family: ${FONTS.medium};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.34px;
  line-height: 24px;
  padding: 8px 22px;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
    color: ${(props) => props.theme.palette.common.white};
  }
`

const { PageLevelHeading } = Text

type DashboardProps = {
  data: IProgram[]
}

const Dashboard: FC<DashboardProps> = ({ data }) => {
  /**
   * ----- Hook Initialization -----
   */

  const history = useHistory()
  const permissions = usePermissions()

  const [globalFilter, setGlobalFilter] = React.useState('')

  /**
   * ----- Variables -----
   */

  const columns = useMemo<ColumnDef<IProgram, any>[]>(
    () => [
      {
        header: 'Program Name',
        accessorKey: 'name'
      },
      {
        header: 'DINs',
        cell: ({ row }: any) => {
          return truncateString(
            row.original.coveredDins.map((din: any) => din.value).join(', '),
            98
          )
        },
        disableSortBy: true,
        width: 200
      }
    ],
    []
  )

  /**
   * ----- Render -----
   */

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  })

  return (
    <div style={{ padding: 50 }}>
      <Header>
        <PageLevelHeading>Programs</PageLevelHeading>
        {permissions.includes(UserPermissionsEnum.ProgramWrite) && (
          <div style={{ alignSelf: 'center' }}>
            <AddProgramButton
              variant="contained"
              color="primary"
              onClick={() => history.push('/programs/create')}
            >
              ADD NEW PROGRAM
            </AddProgramButton>
          </div>
        )}
      </Header>
      <DebouncedInput
        value={globalFilter ?? ''}
        onChange={(value) => setGlobalFilter(String(value))}
        placeholder="Search Programs"
      />
      <StyledTable>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeaderCell
                  key={header.id}
                  style={{ width: header.getSize() }}
                >
                  <>
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                    {header.column.getCanFilter() ? (
                      <DebouncedInput
                        type="text"
                        value={(header.column.getFilterValue() ?? '') as string}
                        onChange={(value) =>
                          header.column.setFilterValue(value)
                        }
                        placeholder={`Search...`}
                        list={header.column.id + 'list'}
                      />
                    ) : null}
                  </>
                </TableHeaderCell>
              ))}
            </TableRow>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <HoverableTableRow
                key={row.id}
                onClick={() =>
                  history.push(`/programs/${row.original.id}/details`)
                }
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <FocusedTableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </FocusedTableCell>
                  )
                })}
              </HoverableTableRow>
            )
          })}
        </tbody>
      </StyledTable>
    </div>
  )
}

export default Dashboard
