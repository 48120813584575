import React from 'react'

import { ButtonWithWidth } from 'src/shared/styled/Buttons'

interface IModalActionsProps {
  isSubmitActive: boolean
  handleClose: () => void
  formId: string
  submitLabel: string
  saveLoading: boolean
}

const ModalActions: React.FC<IModalActionsProps> = ({
  isSubmitActive,
  handleClose,
  formId,
  submitLabel,
  saveLoading
}) => {
  return (
    <>
      <ButtonWithWidth
        type="submit"
        form={formId}
        color="secondary"
        disabled={!isSubmitActive}
        isLoading={saveLoading}
      >
        {submitLabel}
      </ButtonWithWidth>
      <ButtonWithWidth variant="outlined" onClick={() => handleClose()}>
        CANCEL
      </ButtonWithWidth>
    </>
  )
}

export default ModalActions
