import { useParams } from 'react-router-dom'

import { useUserActivities } from 'src/shared/hooks/users'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import { UserAdminMatchParams } from '../../../models/routeParams'
import ActivityView from '../views/Activity'

const Activity = () => {
  const { id: userId } = useParams<UserAdminMatchParams>()

  const { data, isLoading, error } = useUserActivities(userId)

  if (error) return <p>There was an error fetching activities</p>
  if (isLoading || !data) return <LoadingScreen />

  return (
    <div style={{ flexDirection: 'column', width: '100%' }}>
      <ActivityView data={data} />
    </div>
  )
}

export default Activity
