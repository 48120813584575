import { ChangeEvent, FC, useState } from 'react'
import { Box } from '@material-ui/core'

import Tab, {
  TabChildren
} from '../../../shared/views/Navigation/TabNavigation'
import { USER_PROGRAM_TAB_OPTIONS } from '../../../constants/user'
import { HeaderName, SubHeaderName } from 'src/shared/styled/Text'
import {
  DetailsContainer,
  DetailsHeaderContainer,
  DetailsHeader
} from 'src/shared/styled/Layout'
import Refills from './Refills'
import Adherence from './Adherence'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'
import Modal from 'src/shared/views/Modal'
import Literacy from './Literacy'
import { useUserFromQueryString } from '../hooks/useUserFromQueryString'
import { FONTS } from 'src/shared/theme'
import {
  IAdherenceTransaction,
  ILiteracyTransaction,
  IRefillTransaction,
  IAllCompletedTransactions
} from 'src/models/transaction'
import Transactions from './Transactions'
import { ITransaction } from 'src/models/program'
import Certificate from './Certificate'
import ClaimTransactionForm from './ClaimTransactionForm'

type UserProgramProps = {
  data: IAllCompletedTransactions[] | undefined
  transactions: ITransaction[]
  programName: string
  programId: string
}

const UserProgram: FC<UserProgramProps> = ({
  data,
  transactions,
  programName,
  programId
}) => {
  const [value, setValue] = useState(0)
  const [isModalOpen, setModalOpen] = useState<'claim'>()
  const user = useUserFromQueryString()

  const handleChange = (
    _event: ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => {
    setValue(newValue)
  }

  const showModal = (value: 'claim') => {
    setModalOpen(value)
  }

  const closeModal = () => {
    setModalOpen(undefined)
  }

  const adherenceData = data?.filter(
    (entry) => entry.type === 'Adherence'
  ) as IAdherenceTransaction[]
  const literacyData = data?.filter(
    (entry) => entry.type === 'Learning'
  ) as ILiteracyTransaction[]
  const refillData = data?.filter(
    (entry) => entry.type === 'Refill'
  ) as IRefillTransaction[]

  const tabPanelOptions = [
    <Adherence data={adherenceData} />,
    <Literacy data={literacyData} programId={programId} />,
    <Refills data={refillData} />,
    <Transactions data={transactions} />,
    <Certificate />
  ]

  return (
    <>
      <Modal isOpen={!!isModalOpen} handleClose={closeModal}>
        <ClaimTransactionForm closeModal={closeModal} />
      </Modal>
      <DetailsContainer>
        <DetailsHeaderContainer>
          <DetailsHeader>
            <div>
              {user?.firstName && (
                <HeaderName>
                  {`USERS / ${user.firstName} ${user.lastName} / `}
                  <strong style={{ fontFamily: FONTS.bold }}>
                    Program Membership
                  </strong>
                </HeaderName>
              )}
              <SubHeaderName>{programName}</SubHeaderName>
            </div>
            <div>
              <ButtonWithMargin onClick={() => showModal('claim')}>
                CLAIM
              </ButtonWithMargin>
            </div>
          </DetailsHeader>
          <Tab
            value={value}
            onChange={handleChange}
            tabOptions={USER_PROGRAM_TAB_OPTIONS}
            aria-label="user program"
          />
        </DetailsHeaderContainer>
        <Box p={3}>
          <TabChildren
            type="userProgram"
            value={value}
            tabPanelOptions={tabPanelOptions}
          />
        </Box>
      </DetailsContainer>
    </>
  )
}

export default UserProgram
