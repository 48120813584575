import CircularProgress from '@material-ui/core/CircularProgress'
import { FC } from 'react'
import styled from 'styled-components'

const LoadingContainer = styled.div`
  flex: 1;
  display: flex;
  margin-top: 50px;
  justify-content: center;
`

const LoadingScreen: FC = () => {
  return (
    <LoadingContainer>
      <CircularProgress size={50} color="primary" />
    </LoadingContainer>
  )
}

export default LoadingScreen
