import { Typography } from '@material-ui/core'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@material-ui/lab'
import moment from 'moment'
import React from 'react'
import { SHORT_DATE_TIME_FORMAT } from 'src/constants/datetimes'
import { IVerificationTask } from 'src/models/verificationTask'
import { useVerificationAuditLog } from 'src/shared/hooks/verifications'
import ImageDisplay from 'src/shared/views/ImageDisplay'
import LoadingScreen from 'src/shared/views/LoadingScreen'

interface IVerificationTaskAuditLog {
  task: IVerificationTask
}

const VerificationTaskAuditLog = ({ task }: IVerificationTaskAuditLog) => {
  /**
   * ----- Hook Initialization -----
   */

  const { logs, isLoading } = useVerificationAuditLog(
    task.userId,
    task.programId
  )

  /**
   * ----- Variables -----
   */

  const sortedLogs = React.useMemo(() => {
    if (!logs) return []

    return logs.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime()
    })
  }, [logs])

  /**
   * ----- Render -----
   */

  if (isLoading || !sortedLogs) return <LoadingScreen />

  return (
    <Timeline align="left">
      {sortedLogs.map((log, index) => (
        <TimelineItem key={log.date}>
          <TimelineOppositeContent>
            <Typography color="textSecondary">
              {moment(log.date).format(SHORT_DATE_TIME_FORMAT)}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            {index !== sortedLogs.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="body1" color="primary">
              {log.desc}
            </Typography>
            {log.imageUrl && (
              <ImageDisplay
                imageKey={log.imageUrl}
                style={{ maxHeight: '10rem' }}
              />
            )}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  )
}

export default VerificationTaskAuditLog
