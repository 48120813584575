import { FC, Fragment } from 'react'
import NumberFormat from 'react-number-format'

interface NumberFormatCustomProps {
  inputRef: (instance: any) => void
  onChange: (event: { target: { value: string } }) => void
}

const NumberFormatCustom: FC<NumberFormatCustomProps> = ({
  inputRef,
  onChange,
  ...other
}) => {
  return (
    <Fragment>
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value
            }
          })
        }}
        isNumericString
      />
    </Fragment>
  )
}

export default NumberFormatCustom
