import React from 'react'
import { Redirect } from 'react-router-dom'

import Login from '../views/Login'
import { signInWithGoogle } from 'src/api/services/amplify'
import { logger } from 'src/utils/logger'

const LoginContainer: React.FC = () => {
  const handleLogin = () => {
    localStorage.setItem('user', 'loggedIn')
  }

  const signIn = async () => {
    try {
      await signInWithGoogle()
      handleLogin()
    } catch (err) {
      logger.error('Error signing in', err)
    }
  }

  // if user already "authenticated", redirect them to the app
  if (localStorage.getItem('user')) {
    return <Redirect to={'/programs'} />
  }

  return <Login signIn={signIn} />
}

export default LoginContainer
