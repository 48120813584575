import React from 'react'
import { IUser } from 'src/models/user'
import Transactions from '../views/Transactions'
import { useUserTransactions } from 'src/shared/hooks/users'
import LoadingScreen from 'src/shared/views/LoadingScreen'

interface IUserTransactions {
  user: IUser
}

const UserTransactions: React.FC<IUserTransactions> = ({ user }) => {
  const { transactions, isLoading, error } = useUserTransactions(user.userId)

  if (error) return <div>Something went wrong...</div>
  if (!isLoading && transactions)
    return (
      <div style={{ flexDirection: 'column', width: '100%', padding: '2em' }}>
        <Transactions data={transactions} />
      </div>
    )

  return <LoadingScreen />
}

export default UserTransactions
