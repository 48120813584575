import moment from 'moment'

type Base = 'minutes' | 'hours'

export const convertToISO8601 = (time: string) => {
  const parameters = time.split(' ')
  return moment
    .duration(Number(parameters[0]), parameters[1] as Base)
    .toISOString()
}

export const convertSecondsToISO8601Duration = (seconds: number) => {
  return moment.duration(seconds, 'seconds').toISOString()
}

export const convertFromISO8601ToSeconds = (duration: string) => {
  return moment.duration(duration).asSeconds()
}

export const getOrdinal = (value: number) => moment.localeData().ordinal(value)

export const momentFormat = (date: Date, format: string) =>
  moment(date).format(format)

/**
 * @returns the timezone offset string
 * eg. -05:00 if you're in Eastern time
 * Used in creating new prescription schedules
 * @deprecated
 */
export const getDateStringOffset = () => {
  const offset = '+00:00'
  return offset
}

// convert am/pm time to military time
export const convertTime12to24 = (time12h: string): string => {
  const [time, modifier] = time12h.split(' ')

  let [hours, minutes] = time.split(':')

  if (hours === '12') {
    hours = '00'
  }

  if (modifier === 'PM') {
    hours = `${parseInt(hours, 10) + 12}`
  }

  // add the 0 to times 1:00 - 9:00
  if (hours.length === 1) {
    hours = `0${hours}`
  }

  return `${hours}:${minutes}`
}

export const convertDateToFormattedString = (
  date: Date | string = new Date(),
  format = 'yyyy-MM-DD'
) => {
  return moment(date).format(format)
}
