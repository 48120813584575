import React from 'react'
import styled from 'styled-components'
import { InputAdornment, TextField } from '@material-ui/core'

import { Search } from '@material-ui/icons'
import { useAsyncDebounce } from 'react-table'

const StyledTextField = styled(TextField)`
  & .MuiInputBase-root {
    height: 35px;
  }
`
const GlobalFilter = ({ globalFilter, setGlobalFilter, placeholder }: any) => {
  const [filterInput, setFilterInput] = React.useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value)
  }, 200)

  return (
    <form noValidate autoComplete="off">
      <StyledTextField
        // id="outlined-basic"
        placeholder={placeholder}
        variant="outlined"
        value={filterInput || ''}
        onChange={(e) => {
          setFilterInput(e.target.value)
          onChange(e.target.value)
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
      />
    </form>
  )
}

export default GlobalFilter
