import { TableRow } from '@material-ui/core'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import React from 'react'
import { useHistory } from 'react-router-dom'
import DebouncedInput from 'src/components/DebounceInput'
import { IModule } from 'src/models/module'
import {
  FocusedTableCell,
  HoverableTableRow,
  StyledTable,
  TableHeaderCell
} from 'src/shared/styled/Table'
import { fuzzyFilter } from 'src/utils/table'

interface IModuleTable {
  modules: IModule[]
}

const ModuleTable = ({ modules }: IModuleTable) => {
  /**
   * ----- Hook Initialization -----
   */

  const history = useHistory()

  /**
   * ----- Variables -----
   */

  const columns = React.useMemo<ColumnDef<IModule, any>[]>(
    () => [
      {
        header: 'Title',
        accessorKey: 'title.en'
      }
    ],
    []
  )

  const table = useReactTable({
    columns,
    data: modules,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    initialState: {
      sorting: [{ id: 'createdAt', desc: true }],
      pagination: {
        pageIndex: 0,
        pageSize: 25
      }
    },
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  })

  /**
   * ----- Render -----
   */
  return (
    <StyledTable>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHeaderCell
                key={header.id}
                style={{ width: header.getSize() }}
              >
                <>
                  <div>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                  {header.column.getCanFilter() ? (
                    <DebouncedInput
                      type="text"
                      value={(header.column.getFilterValue() ?? '') as string}
                      onChange={(value) => header.column.setFilterValue(value)}
                      placeholder={`Search...`}
                      list={header.column.id + 'list'}
                    />
                  ) : null}
                </>
              </TableHeaderCell>
            ))}
          </TableRow>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <HoverableTableRow
              key={row.id}
              onClick={() => history.push(`/module/${row.original.moduleId}`)}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <FocusedTableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </FocusedTableCell>
                )
              })}
            </HoverableTableRow>
          )
        })}
      </tbody>
    </StyledTable>
  )
}

export default ModuleTable
