import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { IProgramEntryCodeData } from 'src/models/program'
import * as yup from 'yup'
import TextField, { TextFieldProps } from 'src/shared/views/TextField'
import styled from 'styled-components'
import {
  FormControlLabel,
  Switch,
  SwitchProps,
  Typography
} from '@material-ui/core'
import { FONTS } from '../theme'

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  align-self: flex-start;
`

const StyledTypography = styled(Typography)`
  font-family: ${FONTS.main};
  color: ${(props) => props.theme.palette.common.black};
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 300;
  line-height: 19px;
`

const EntryCodeSchema = yup.object().shape({
  description: yup.string(),
  singleUse: yup.boolean().required()
})

export const useEntryCodeForm = (options?: any) => {
  const { handleSubmit, control, ...form } = useForm({
    resolver: yupResolver(EntryCodeSchema),
    defaultValues: {
      description: '',
      singleUse: false,
      ...options?.defaultValues
    },
    ...options
  })

  const FormComponents = {
    Form: ({
      children,
      submitHandler
    }: {
      children: React.ReactNode
      submitHandler: SubmitHandler<IProgramEntryCodeData>
    }) => <form onSubmit={handleSubmit(submitHandler)}>{children}</form>,
    Name: ({ ...props }: TextFieldProps) =>
      React.useMemo(
        () => (
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <TextField {...props} {...field} label="Description" />
            )}
          />
        ),
        [props]
      ),
    SingleUse: ({ ...props }: SwitchProps) =>
      React.useMemo(
        () => (
          <Controller
            control={control}
            name="singleUse"
            render={({ field: { onChange, value, ...field } }) => (
              <StyledFormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    {...field}
                    {...props}
                  />
                }
                label={<StyledTypography>Single Use</StyledTypography>}
              />
            )}
          />
        ),
        [props]
      )
  }

  return { FormComponents, ...form }
}
