import { useParams } from 'react-router'
import { UserAdminMatchParams } from 'src/models/routeParams'
import { useFetchClaims } from 'src/shared/hooks/users'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import ClaimsTable from '../views/ClaimsTable'

const Claims = () => {
  const { id: userId } = useParams<UserAdminMatchParams>()

  const { claims, isLoading, error } = useFetchClaims(userId)

  if (error) return <p>Unable to fetch direct deposits</p>
  if (!isLoading && claims) return <ClaimsTable hideName claims={claims} />

  return <LoadingScreen />
}

export default Claims
