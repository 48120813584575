import React, { ChangeEvent, FC, Fragment, ReactNode } from 'react'
import styled from 'styled-components'
import { Tab } from '@material-ui/core'
import Tabs, { TabsProps as MUITabsProps } from '@material-ui/core/Tabs'

import { COLORS, FONTS } from '../../theme'
import { ITabNavigationOptions } from '../../../models/tabNavigation'
import { useHistory } from 'react-router-dom'

export interface TabsProps extends Omit<MUITabsProps, 'onChange'> {
  tabOptions: ITabNavigationOptions[]
  onChange: (
    event: ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => void
}

interface ITabChildren {
  tabPanelOptions: JSX.Element[]
  value: number
  type: string
}

interface TabPanelProps {
  children?: ReactNode
  index: any
  value: any
  type: string
}

const StyledTabs = styled(Tabs)`
  font-family: ${FONTS.medium};
  padding-left: 25px;
  & .MuiTab-textColorInherit.Mui-selected {
    color: ${COLORS.pacificBlue};
  }
`
const StyledTab = styled(Tab)`
  font-family: ${FONTS.medium};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.28px;
  line-height: 24.5px;
  text-align: center;
`

function a11yProps(type: string, index: number) {
  return {
    id: `${type}Tab${index}`,
    'aria-controls': `${type}Tabpanel${index}`
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, type, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${type}Tabpanel${index}`}
      aria-labelledby={`${type}Tab${index}`}
      {...other}
    >
      {<div hidden={value !== index}>{children}</div>}
    </div>
  )
}

const ThemedTab: FC<TabsProps> = ({
  value,
  onChange,
  tabOptions,
  ...props
}: TabsProps) => {
  /**
   * ----- Hook Initialization -----
   */

  const history = useHistory()

  /**
   * ----- Functions -----
   */

  const handleChange = (
    event: ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => {
    if (tabOptions[newValue].urlHash) {
      history.push(`#${tabOptions[newValue].urlHash}`)
    } else {
      history.replace({ hash: '' })
    }

    onChange(event, newValue)
  }

  /**
   * ----- Lifecycle -----
   */

  React.useEffect(() => {
    const hash = history.location.hash.substring(1)
    if (hash) {
      const tabIndex = tabOptions.findIndex((tab) => tab.urlHash === hash)
      if (tabIndex > -1) {
        onChange({} as ChangeEvent<Record<string, unknown>>, tabIndex)
      }
    }
  }, [history.location.hash, onChange, tabOptions])

  /**
   * ----- Render -----
   */

  return (
    <>
      <StyledTabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            background: COLORS.pacificBlue,
            color: COLORS.pacificBlue,
            border: `1.5px solid ${COLORS.pacificBlue}`
          }
        }}
        {...props}
      >
        {tabOptions.map((option, idx) => {
          return (
            <StyledTab
              key={idx}
              label={option.label}
              {...a11yProps(option.type, idx)}
            />
          )
        })}
      </StyledTabs>
    </>
  )
}

export const TabChildren: FC<ITabChildren> = ({
  value,
  tabPanelOptions,
  type
}) => {
  return (
    <>
      {tabPanelOptions.map((panel, idx) => (
        <TabPanel type={type} value={value} key={idx} index={idx}>
          <Fragment>{panel}</Fragment>
        </TabPanel>
      ))}
    </>
  )
}

export default ThemedTab
