import React from 'react'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Controller } from 'react-hook-form'
import { isEqual } from 'lodash'

import { usePrevious } from 'src/utils/usePrevious'
import { useLMSDetailsForm } from '../hooks/forms'
import { Learning, Text } from 'src/shared/styled'
import { useLMS } from 'src/contexts/Learning'
import { ImageUploader } from 'src/shared/views/ImageUploader'
import { downloadImage, uploadImage } from 'src/api/services/amplify'
import { UserPermissionsEnum } from 'src/models/user'
import { usePermissions } from 'src/shared/hooks/usePermissions'

interface IAddLMSDetailsProps {
  title: string
  estimatedDuration: string
  description: string
  coverImageUrl: string
}

const { TextLarge } = Text
const {
  AddLMSDetailsContainer,
  TitleTextField,
  DescriptionTextField,
  TimeInput,
  NewPageBanner
} = Learning

const AddLMSDetails: React.FC<IAddLMSDetailsProps> = ({
  title,
  estimatedDuration,
  description,
  coverImageUrl
}) => {
  const { addPage, saveLMSDetails, isReset, setResetFlag } = useLMS()
  const permissions = usePermissions()
  const canCurrentUserWrite = permissions.includes(
    UserPermissionsEnum.ProgramWrite
  )

  const {
    control,
    watch,
    getValues,
    setError,
    setValue,
    clearErrors,
    trigger,
    reset,
    formState: { errors, isDirty }
  } = useLMSDetailsForm()
  const [imageToDisplay, setImageToDisplay] = React.useState<any>('')

  const fields = watch()

  const oldFields = usePrevious(fields)

  // When uploading a careplan, the lms details component is rendered first underneath the modal
  // so we need to trigger them to set once it's been uploaded
  React.useEffect(() => {
    if (
      title &&
      description &&
      estimatedDuration &&
      !getValues('title') &&
      !getValues('description') &&
      !getValues('duration')
    ) {
      setValue('title', title)
      setValue('description', description)
      setValue('duration', estimatedDuration)
    }
  }, [title, description, estimatedDuration, getValues, setValue])

  React.useEffect(() => {
    if (isReset) {
      reset()
      setImageToDisplay('')
      setResetFlag()
    }
  }, [isReset, reset, setResetFlag])

  React.useEffect(() => {
    if (!isEqual(fields, oldFields) && isDirty) {
      const valuesToSave = {
        ...getValues(),
        estimatedDuration: getValues('duration').toString()
      }
      saveLMSDetails(valuesToSave, isDirty)
      trigger()
    }
  }, [fields, oldFields, getValues, isDirty, saveLMSDetails, trigger])

  React.useEffect(() => {
    const getImage = async () => {
      if (coverImageUrl) {
        const remoteImageUrl = await downloadImage(coverImageUrl)
        setImageToDisplay(remoteImageUrl)
      } else {
        setImageToDisplay('')
      }
    }

    getImage()
  }, [coverImageUrl])

  const onHandleImageChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const imageFile = e?.target?.files && e?.target?.files[0]

    if (!imageFile) return

    if (!['image/png', 'image/jpg', 'image/jpeg'].includes(imageFile.type)) {
      setError('coverImageUrl', { message: 'File must be a png or jpeg' })
    } else {
      clearErrors('coverImageUrl')
      const img = URL.createObjectURL(imageFile)
      const remoteImage = await uploadImage(img, 'learning')
      setValue('coverImageUrl', remoteImage, { shouldDirty: true })
    }
  }

  const onClearImage = () => {
    setValue('coverImageUrl', '', { shouldDirty: true })
  }

  return (
    <div>
      <AddLMSDetailsContainer>
        <div className="form">
          <Controller
            defaultValue={coverImageUrl}
            name="coverImageUrl"
            control={control}
            render={({ fieldState: { error } }) => (
              <ImageUploader
                id="coverImageUrl"
                label="Upload Cover Image"
                disableUploading={!canCurrentUserWrite}
                error={error?.message}
                helperText="For best user experience, cover image should be 16 by 9 aspect ratio, eg. 300x170"
                onHandleImageChange={onHandleImageChange}
                onClearImage={onClearImage}
                imagePath={imageToDisplay}
                width={300}
                height={170}
              />
            )}
          />

          <div>
            <Controller
              defaultValue={title}
              name="title"
              control={control}
              render={({ field: { ref, ...props } }) => (
                <TitleTextField
                  label="Learning Module Title"
                  variant="outlined"
                  inputRef={ref}
                  disabled={!canCurrentUserWrite}
                  helperText={errors?.title?.message}
                  error={!!errors?.title?.message}
                  {...props}
                />
              )}
            />
            <Controller
              defaultValue={parseInt(estimatedDuration, 10)}
              name="duration"
              control={control}
              render={({ field: { ...props } }) => (
                <TimeInput
                  type="number"
                  label="Time to Complete"
                  variant="outlined"
                  disabled={!canCurrentUserWrite}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">min</InputAdornment>
                    ),
                    inputProps: { min: 0 }
                  }}
                  helperText={errors?.duration?.message}
                  error={!!errors?.duration?.message}
                  {...props}
                />
              )}
            />
          </div>
          <Controller
            defaultValue={description}
            name="description"
            control={control}
            render={({ field: { ref, ...props } }) => (
              <DescriptionTextField
                label="Description"
                disabled={!canCurrentUserWrite}
                variant="outlined"
                inputRef={ref}
                helperText={errors?.description?.message}
                error={!!errors?.description?.message}
                {...props}
              />
            )}
          />
        </div>
      </AddLMSDetailsContainer>
      {canCurrentUserWrite && (
        <NewPageBanner onClick={() => addPage()}>
          <AddCircleIcon htmlColor="#505050" />
          <TextLarge>NEW PAGE</TextLarge>
        </NewPageBanner>
      )}
    </div>
  )
}

export default AddLMSDetails
