import React from 'react'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import TextFieldsIcon from '@material-ui/icons/TextFields'
import Delete from '@material-ui/icons/Delete'
import Menu from '@material-ui/core/Menu'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import ImageIcon from '@material-ui/icons/Image'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { UserPermissionsEnum } from 'src/models/user'
import { usePermissions } from 'src/shared/hooks/usePermissions'
import { Learning, Text, Buttons } from 'src/shared/styled'
import AddPageTitle from './AddPageTitle'
import HoverToEdit from './HoverToEdit'
import DisplayTextSection from './DisplayTextSection'
import { ILMSPage, LMSSection } from 'src/models/learning'
import { useLMS } from 'src/contexts/Learning'
import DisplayQuestionSection from './DisplayQuestionSection'
import DisplayImageSection from './DisplayImageSection'

interface INewPageProps {
  page: ILMSPage
  pageIndex: number
}

const { ButtonWithWidth } = Buttons
const {
  TextLarge,
  LightText,
  BoldHeading,
  LMSTextSectionDisplay,
  LMSTitleDisplay
} = Text
const {
  PageContainer,
  NewPageBanner,
  AddSectionButton,
  TitleDisplayContainer
} = Learning

const NewPage: React.FC<INewPageProps> = ({ page, pageIndex }) => {
  const { addPage, addSection, removeTitle, deletePage } = useLMS()
  const { header, description, sections, saved } = page
  const [editingTitle, setEditingTitle] = React.useState(false)
  const [menuAnchorEl, setMenuAnchoEl] = React.useState<null | HTMLElement>(
    null
  )
  const [menuShowing, setMenuShowing] = React.useState(false)

  const handleAddSectionClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuShowing(true)
    setMenuAnchoEl(event.currentTarget)
  }
  const permissions = usePermissions()
  const canCurrentUserWrite = permissions.includes(
    UserPermissionsEnum.ProgramWrite
  )

  let pageHasQuestion = false
  let pageContent: any[] = []
  if (sections.length > 0) {
    const populatedSections = sections as LMSSection[]
    pageContent = populatedSections.map((section, index) => {
      if ('text' in section) {
        return (
          <DisplayTextSection key={index} section={section} pageId={page.id} />
        )
      } else if ('question' in section) {
        pageHasQuestion = true
        return (
          <DisplayQuestionSection
            key={index}
            questionSection={section}
            pageId={page.id}
          />
        )
      } else {
        return (
          <DisplayImageSection key={index} section={section} pageId={page.id} />
        )
      }
    })
  }
  return (
    <div>
      <PageContainer>
        <div className="page-header">
          <LightText>{`Page ${pageIndex + 1}`}</LightText>
          {canCurrentUserWrite && (
            <IconButton onClick={() => deletePage(page.id)} size="small">
              <Delete fontSize="inherit" />
            </IconButton>
          )}
        </div>
        <div className="page-content">
          {editingTitle ? (
            <AddPageTitle
              id={page.id}
              header={header}
              saved={saved}
              description={description}
              cancelEditTitle={() => setEditingTitle(false)}
            />
          ) : header || description ? (
            <>
              <TitleDisplayContainer>
                <LMSTitleDisplay>{header}</LMSTitleDisplay>
                <LMSTextSectionDisplay>{description}</LMSTextSectionDisplay>
                <HoverToEdit
                  onEditClick={() => setEditingTitle(true)}
                  onDeleteClick={() => removeTitle(page.id)}
                />
              </TitleDisplayContainer>
            </>
          ) : (
            <AddSectionButton
              className="add-title"
              onClick={() => setEditingTitle(true)}
            >
              <TextFieldsIcon />
              <BoldHeading>ADD PAGE TITLE</BoldHeading>
            </AddSectionButton>
          )}
          {pageContent}
        </div>
        <div className="button-container">
          {canCurrentUserWrite && (
            <ButtonWithWidth
              className="new-section"
              color="secondary"
              startIcon={<AddCircleIcon />}
              onClick={handleAddSectionClick}
            >
              ADD NEW
            </ButtonWithWidth>
          )}
          <Menu
            open={menuShowing}
            anchorEl={menuAnchorEl}
            getContentAnchorEl={null}
            onClose={() => setMenuShowing(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            {!pageHasQuestion ? (
              <MenuItem
                onClick={() => {
                  addSection('question', page.id)
                  setMenuShowing(false)
                }}
              >
                <ListItemIcon>
                  <CheckBoxIcon />
                </ListItemIcon>
                <ListItemText>Question</ListItemText>
              </MenuItem>
            ) : null}
            <MenuItem
              onClick={() => {
                addSection('text', page.id)
                setMenuShowing(false)
              }}
            >
              <ListItemIcon>
                <TextFieldsIcon />
              </ListItemIcon>
              <ListItemText>Text</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                addSection('image', page.id)
                setMenuShowing(false)
              }}
            >
              <ListItemIcon>
                <ImageIcon />
              </ListItemIcon>
              <ListItemText>Image</ListItemText>
            </MenuItem>
          </Menu>
        </div>
      </PageContainer>
      {canCurrentUserWrite && (
        <NewPageBanner onClick={() => addPage(page.id)}>
          <AddCircleIcon htmlColor="#505050" />
          <TextLarge>NEW PAGE</TextLarge>
        </NewPageBanner>
      )}
    </div>
  )
}

export default NewPage
