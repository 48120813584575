import { Delete } from '@material-ui/icons'
import React from 'react'
import { useParams } from 'react-router'
import {
  ProgramMatchParams,
  UserAdminMatchParams
} from 'src/models/routeParams'
import {
  useUpdateUserProgramCertificate,
  useUserProgramCertificate,
  useDeleteUserProgramCertificate
} from 'src/shared/hooks/users'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'
import {
  ErrorHeading,
  Heading,
  LightText,
  TextLarge
} from 'src/shared/styled/Text'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import styled from 'styled-components'
import Modal from 'src/shared/views/Modal'
import useProgramCertificateForm, {
  IPutUserProgramCertificateData
} from '../hooks/useProgramCertificateForm'
import { toast } from 'react-toastify'

const Container = styled.div`
  padding: 1em;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const CertificateContainer = styled.div`
  padding: 1em;
`

const FormFieldsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
`

const Certificate = () => {
  /**
   * ----- Hook Initialization -----
   */

  const { id: userId } = useParams<UserAdminMatchParams>()
  const { programId } = useParams<ProgramMatchParams>()

  const [modalOpen, setModalOpen] = React.useState(false)

  const {
    certificate,
    isLoading: certificateIsLoading,
    error: certificateError
  } = useUserProgramCertificate(userId, programId)

  const { updateCertificate, isLoading: updateLoading } =
    useUpdateUserProgramCertificate(userId, programId, {
      onSuccess: () => setModalOpen(false)
    })

  const { mutateAsync: deleteCertificate, isLoading: deleteLoading } =
    useDeleteUserProgramCertificate(userId, programId)

  const { FormComponents } = useProgramCertificateForm({
    defaultValues: certificate
  })

  /**
   * ----- Functions -----
   */

  const handleSubmit = (value: IPutUserProgramCertificateData) => {
    if (window.confirm('Are you sure?')) {
      updateCertificate(value)
    }
  }

  const handleDelete = () => {
    if (window.confirm('Are you sure?')) {
      deleteCertificate().then(() =>
        toast.success('Successfully deleted, please refresh to see change')
      )
    }
  }

  /**
   * ----- Rendering -----
   */

  const noCertificate = React.useMemo(
    () => !certificateIsLoading && !certificate,
    [certificate, certificateIsLoading]
  )

  const isLoading = React.useMemo(
    () => certificateIsLoading || updateLoading || deleteLoading,
    [certificateIsLoading, deleteLoading, updateLoading]
  )

  const content = React.useMemo(() => {
    if (!certificateIsLoading && certificate) {
      return (
        <CertificateContainer>
          <p>
            <TextLarge>Certificate Number: </TextLarge>
            <LightText>{certificate.certificateNumber}</LightText>
          </p>
          <p>
            <TextLarge>Policy Number: </TextLarge>
            <LightText>{certificate.policyNumber}</LightText>
          </p>
          <p>
            <TextLarge>Plan Number: </TextLarge>
            <LightText>{certificate.planNumber}</LightText>
          </p>
        </CertificateContainer>
      )
    } else if (noCertificate) {
      return <Heading>No certificate found</Heading>
    } else if (certificateError) {
      return <ErrorHeading>Unable to fetch certificate</ErrorHeading>
    } else {
      return <LoadingScreen />
    }
  }, [certificate, certificateError, certificateIsLoading, noCertificate])

  return (
    <Container>
      <Row>
        {content}
        <div>
          {certificate && (
            <ButtonWithMargin
              startIcon={<Delete />}
              onClick={() => handleDelete()}
              variant="outlined"
              isLoading={isLoading}
            >
              DELETE
            </ButtonWithMargin>
          )}

          <ButtonWithMargin
            onClick={() => setModalOpen(true)}
            isLoading={isLoading}
          >
            {noCertificate ? 'ADD' : 'UPDATE'}
          </ButtonWithMargin>
        </div>
      </Row>
      <Modal isOpen={modalOpen} handleClose={() => setModalOpen(false)}>
        <Heading>Update Certificate</Heading>
        <FormComponents.Form submitHandler={handleSubmit}>
          <FormFieldsRow>
            <FormComponents.CertificateNumber />
            <FormComponents.PolicyNumber />
            <FormComponents.PlanNumber />
          </FormFieldsRow>
          <ButtonWithMargin type="submit" isLoading={isLoading}>
            Submit
          </ButtonWithMargin>
        </FormComponents.Form>
      </Modal>
    </Container>
  )
}

export default Certificate
