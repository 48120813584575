import {
  AppBar,
  Avatar,
  Box,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  Hidden,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography
} from '@material-ui/core'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import {
  FC,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
  useContext
} from 'react'
import { withTheme } from '@material-ui/core/styles'
import { KeyboardArrowDown } from '@material-ui/icons'
import styled from 'styled-components'

import { COLORS, FONTS } from '../../theme'
import LogoImg from '../../../assets/images/logoWhite.png'
import { Navigation } from '../../styled'
import { signOut } from 'src/api/services/amplify'
import { UserContext } from 'src/contexts/User'
import { UserPermissionsEnum } from 'src/models/user'
import { usePermissions } from 'src/shared/hooks/usePermissions'

const { StyledToolbar } = Navigation

const Logo = styled.img`
  height: 120px;
`

const HeaderBox = withTheme(styled(Box)`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.main};
`)

const HeaderAppBar = withTheme(styled(AppBar)`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.main};
  z-index: ${(props) => props.theme.zIndex.drawer + 1};
`)

const NavLinks = styled(NavLink)`
  font-size: 18px;
  font-family: ${FONTS.main};
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.13px;
  margin-right: 60px;
  text-decoration: none;
  color: ${(props) => props.theme.palette.common.white};
  &.active {
    text-decoration: none;
    color: ${COLORS.cornFlower};
  }
`

const UserInfo = styled(Typography)`
  margin-left: 0.5em;
`

const StyledMenuList = withTheme(styled(MenuList)`
  background-color: ${(props) => props.theme.palette.primary.dark};
  color: ${(props) => props.theme.palette.common.white};
  margin-top: 0.89em;
`)

const StyledMenuItem = withTheme(styled(MenuItem)`
  border-bottom: 1px solid ${COLORS.cornFlower};
  justify-content: center;
  font-family: ${FONTS.main};
  font-size: '16px';
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.11px;
  &:last-child {
    font-family: ${FONTS.medium};
    border-bottom: none;
    font-weight: 300;
  }
  padding: 22px 22px;
`)

export interface MatchParams {
  id: string
}

const Header: FC = () => {
  const { currentUser, setUser } = useContext(UserContext)
  const [open, setOpen] = useState(false)
  const [buttonColor, setButtonColor] = useState(false)
  const permissions = usePermissions()
  const anchorRef = useRef<HTMLButtonElement>(null)
  const history = useHistory()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  const handleLogOut = () => {
    signOut()
    setUser(null)
    localStorage.removeItem('user')
    history.push('/login')
  }

  const handleListKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const location = useLocation()
  const path = location && location.pathname

  useEffect(() => {
    if (path === '/settings') {
      setButtonColor(true)
    } else {
      setButtonColor(false)
    }
  }, [location, path])

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus()
    }

    prevOpen.current = open
  }, [open])

  const popperId = open ? 'settings-popper' : undefined

  return (
    <Grid item>
      <HeaderBox component="nav">
        <HeaderAppBar position="fixed">
          <StyledToolbar>
            <div style={{ flex: 1 }}>
              <IconButton
                style={{
                  height: '64px',
                  padding: 0,
                  overflow: 'hidden'
                }}
                edge="start"
                color="inherit"
                aria-label="menu"
              >
                <Logo src={LogoImg} alt="logo" />
              </IconButton>
            </div>
            <Hidden smDown>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Typography>
                  <NavLinks
                    className="option"
                    to="/programs"
                    activeClassName="active"
                    style={{ fontFamily: FONTS.medium }}
                  >
                    Programs
                  </NavLinks>
                </Typography>
                {(permissions.includes(UserPermissionsEnum.UserRead) ||
                  permissions.includes(UserPermissionsEnum.UserWrite)) && (
                  <Typography>
                    <NavLinks
                      className="option"
                      to="/users"
                      activeClassName="active"
                      style={{ fontFamily: FONTS.medium }}
                    >
                      Users
                    </NavLinks>
                  </Typography>
                )}
                <Typography>
                  <NavLinks
                    className="option"
                    to="/learning"
                    activeClassName="active"
                    style={{ fontFamily: FONTS.medium }}
                  >
                    Learning
                  </NavLinks>
                </Typography>
                <Typography>
                  <NavLinks
                    className="option"
                    to="/modules"
                    activeClassName="active"
                    style={{ fontFamily: FONTS.medium }}
                  >
                    Modules
                  </NavLinks>
                </Typography>
                <Typography>
                  <NavLinks
                    className="option"
                    to="/tools/verification"
                    activeClassName="active"
                    style={{ fontFamily: FONTS.medium }}
                    isActive={(_match, location) =>
                      location.pathname.includes('tools')
                    }
                  >
                    Tools
                  </NavLinks>
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ background: '#E0E0E0' }}
                />
                <IconButton
                  aria-label="Account of current user"
                  aria-controls={popperId}
                  aria-haspopup="true"
                  color="default"
                  onClick={handleToggle}
                  ref={anchorRef}
                >
                  <Avatar alt="Admin Avatar">
                    {currentUser?.fullName.charAt(0) ?? 'A'}
                  </Avatar>
                  <UserInfo
                    style={{
                      color: buttonColor ? COLORS.cornFlower : COLORS.white
                    }}
                  >
                    {currentUser?.fullName || 'Admin User'}
                  </UserInfo>
                  <KeyboardArrowDown
                    style={{
                      color: buttonColor ? COLORS.cornFlower : COLORS.white
                    }}
                  />
                </IconButton>
                <Popper
                  id={popperId}
                  placement="bottom"
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom'
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <StyledMenuList
                            autoFocusItem={open}
                            id="settings-menu"
                            onKeyDown={handleListKeyDown}
                          >
                            <StyledMenuItem onClick={handleLogOut}>
                              LOG OUT
                            </StyledMenuItem>
                          </StyledMenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </Hidden>
          </StyledToolbar>
        </HeaderAppBar>
        <StyledToolbar />
      </HeaderBox>
    </Grid>
  )
}

export default Header
