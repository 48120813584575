import React from 'react'
import { Controller } from 'react-hook-form'

import { useDrugLibSearchForm } from '../hooks/forms'
import { useDrugSearch } from '../../../shared/hooks/drugLibrary'
import TextField from '../../../shared/views/TextField'
import { Tools, Buttons } from '../../../shared/styled'
import { DropDown } from 'src/shared/views/DropDown'
import { SEARCH_TYPES } from '../../../constants/tools'
import Table from '../../../shared/views/Table/Table'
import { COLORS } from '../../../shared/theme'
import { BoldHeading, SubHeaderName } from 'src/shared/styled/Text'
import { combineDrugIngredientsIntoValue } from 'src/utils/drugLib'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import { Create } from '@material-ui/icons'

const { HeaderContainer, SearchContainer, Form, ViewLink } = Tools

const { ButtonWithWidth } = Buttons

const formFieldStyles = {
  marginBottom: '30px',
  marginRight: '20px',
  minWidth: '200px',
  width: '50%',
  maxWidth: '250px'
}

const Dins: React.FC = () => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitted, isDirty },
    setValue,
    getValues
  } = useDrugLibSearchForm()

  const { search, dins, isLoading, isFetching } = useDrugSearch(getValues())

  const columns = React.useMemo(
    () => [
      {
        Header: 'DIN',
        accessor: 'din.value'
      },
      {
        Header: 'Brand Name',
        accessor: 'brandName'
      },
      {
        Header: 'Company',
        accessor: 'manufacturerName'
      },
      {
        Header: 'Active Ingredients',
        accessor: 'activeIngredients',
        Cell: ({ row }: any) => {
          return row.original.activeIngredients
            .map((ingredient: any) => ingredient.name)
            .join(', ')
        }
      },
      {
        Header: 'Dose Strength',
        accessor: 'doseStrength',
        Cell: ({ row }: any) => {
          return combineDrugIngredientsIntoValue(row.original.activeIngredients)
        }
      },
      {
        Header: 'Dose Unit Type',
        accessor: 'pharmaceuticalForm'
      },
      {
        Header: 'Details',
        accessor: 'details',
        Cell: ({ row }: any) => (
          <ViewLink to={`/tools/dins/${row.original.din.value}`}>
            <Create />
          </ViewLink>
        ),
        disableSortBy: true,
        width: 60
      }
    ],
    []
  )

  watch()
  const dinSearch = watch('searchType', 'din')

  return (
    <div>
      <HeaderContainer>
        <SubHeaderName>DINS</SubHeaderName>
      </HeaderContainer>
      <SearchContainer>
        <BoldHeading>DIN Search</BoldHeading>
        <Form onSubmit={handleSubmit(search)}>
          <Controller
            defaultValue={SEARCH_TYPES[0].value}
            render={({ field: { ref, onChange, ...props } }) => (
              <DropDown
                style={formFieldStyles}
                label="Search By"
                items={SEARCH_TYPES}
                inputRef={ref}
                error={!!errors?.searchType?.message}
                helperText={errors?.searchType?.message}
                onChange={(e) => {
                  setValue('din', '')
                  setValue('brandName', '')
                  setValue('manufacturer', '')
                  setValue('activeIngredient', '')
                  onChange(e)
                }}
                {...props}
              />
            )}
            name="searchType"
            control={control}
          />
          {dinSearch === 'din' ? (
            <Controller
              defaultValue={''}
              render={({ field: { ref, ...props } }) => (
                <TextField
                  style={formFieldStyles}
                  id="din"
                  label="DIN"
                  placeholder="Enter a din"
                  inputRef={ref}
                  error={!!errors?.din?.message}
                  helperText={errors?.din?.message}
                  {...props}
                />
              )}
              name="din"
              control={control}
            />
          ) : (
            <div>
              <Controller
                defaultValue={''}
                render={({ field: { ref, ...props } }) => (
                  <TextField
                    style={formFieldStyles}
                    id="brand-name"
                    label="Brand Name"
                    placeholder="Brand name"
                    inputRef={ref}
                    error={!!errors?.brandName?.message}
                    helperText={errors?.brandName?.message}
                    {...props}
                  />
                )}
                name="brandName"
                control={control}
              />
              <Controller
                defaultValue={''}
                render={({ field: { ref, ...props } }) => (
                  <TextField
                    style={formFieldStyles}
                    id="manufacturer"
                    label="Manufacturer"
                    placeholder="Manufacturer"
                    inputRef={ref}
                    error={!!errors?.manufacturer?.message}
                    helperText={errors?.manufacturer?.message}
                    {...props}
                  />
                )}
                name="manufacturer"
                control={control}
              />
              <Controller
                defaultValue={''}
                render={({ field: { ref, ...props } }) => (
                  <TextField
                    style={formFieldStyles}
                    id="active-ingredient"
                    label="Active Ingredient"
                    placeholder="Active Ingredient"
                    inputRef={ref}
                    error={!!errors?.activeIngredient?.message}
                    helperText={errors?.activeIngredient?.message}
                    {...props}
                  />
                )}
                name="activeIngredient"
                control={control}
              />
            </div>
          )}

          <div>
            <ButtonWithWidth
              isLoading={isLoading || isFetching}
              disabled={!isDirty}
              type="submit"
              color="secondary"
            >
              SEARCH
            </ButtonWithWidth>
            <ButtonWithWidth
              type="button"
              variant="outlined"
              onClick={() => reset()}
            >
              RESET
            </ButtonWithWidth>
          </div>
        </Form>
      </SearchContainer>
      {isLoading || isFetching ? (
        <LoadingScreen />
      ) : dins && isSubmitted ? (
        <Table
          columns={columns}
          data={dins}
          emptyMessage="No medications match your search"
          placeholder="Search"
          getHeaderProps={() => ({
            style: {
              background: COLORS.white
            }
          })}
        />
      ) : null}
    </div>
  )
}

export default Dins
