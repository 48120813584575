import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

const LMSTitleSchema = yup.object().shape({
  title: yup.string().max(150, 'Only 150 characters permitted'),
  description: yup.string()
})

const LMSTextShcema = yup.object().shape({
  text: yup.string().required('Text is required')
})

const LMSDetailsSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
  duration: yup
    .number()
    .typeError('Time to complete is required')
    .required('Time to complete is required')
    .min(0, 'Must be above 0'),
  description: yup
    .string()
    .required('Description is required')
    .typeError('Description must be string type and can not be null'),
  coverImageUrl: yup
    .string()
    .typeError('Cover image URL must be of string type and can not be null')
})

const LMSImageSchema = yup.object().shape({
  imageUrl: yup.string().required('Image URL is required'),
  imageDescription: yup.string()
})

const AnswerOptionSchema = yup.object().shape({
  text: yup.string().required('Answer is required'),
  weight: yup.number().required('Weight is required').min(1, 'Must be above 1'),
  correct: yup.boolean()
})

const LMSQuestionSchema = yup.object().shape({
  question: yup.string().required('Question is required'),
  answerOptions: yup
    .array()
    .of(AnswerOptionSchema)
    .min(2, 'Must have at least 2 answer options')
    .test(
      'oneCorrect',
      'At least one answer must be marked correct',
      (answerOptions) =>
        answerOptions?.some((option) => option.correct) || false
    ),
  correctFeedback: yup.string().required('Correct answer feedback is required'),
  incorrectFeedback: yup
    .string()
    .required('Incorrect answer feedback is required')
})

const useLMSDetailsForm = (options?: any) => {
  return useForm({
    resolver: yupResolver(LMSDetailsSchema),
    ...options
  })
}
const useLMSTextForm = (options?: any) => {
  return useForm({
    resolver: yupResolver(LMSTextShcema),
    ...options
  })
}

const useLMSTitleForm = (options?: any) => {
  return useForm({
    resolver: yupResolver(LMSTitleSchema),
    ...options
  })
}

const useLMSQuestionForm = (options?: any) => {
  return useForm({
    resolver: yupResolver(LMSQuestionSchema),
    defaultValues: {
      answerOptions: [
        { weight: 1 },
        { weight: 1 },
        { weight: 1 },
        { weight: 1 }
      ]
    },
    ...options
  })
}

const useLMSImageForm = (options?: any) => {
  return useForm({
    resolver: yupResolver(LMSImageSchema),
    ...options
  })
}

export {
  useLMSTitleForm,
  useLMSTextForm,
  useLMSQuestionForm,
  useLMSDetailsForm,
  useLMSImageForm
}
