import { FC } from 'react'
import styled from 'styled-components'
import { UseMutationResult } from 'react-query'
import { Delete } from '@material-ui/icons'

import { Heading } from 'src/shared/styled/Text'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`
interface IAnonymizeUserView {
  anonymizeUserMutation: UseMutationResult<void, unknown, void, unknown>
  closeModal: () => void
}

const AnonymizeUserView: FC<IAnonymizeUserView> = ({
  closeModal,
  anonymizeUserMutation
}) => {
  const { isLoading, isError, mutate } = anonymizeUserMutation
  const onSubmit = () => {
    mutate()
  }

  return (
    <div>
      <Heading>Anonymize User</Heading>
      <p>
        Are you sure you want to anonymize this user? This action cannot be
        undone.
      </p>
      <p>
        This will retain all of the users survey and activity data, but will
        wipe out all identifying personal info
      </p>
      {isError && <p>An error occurred anonymizing this user</p>}

      <ButtonsWrapper>
        <ButtonWithMargin
          color="primary"
          variant="outlined"
          onClick={closeModal}
        >
          Cancel
        </ButtonWithMargin>
        <ButtonWithMargin
          startIcon={<Delete />}
          color="primary"
          onClick={onSubmit}
          isLoading={isLoading}
        >
          Anonymize
        </ButtonWithMargin>
      </ButtonsWrapper>
    </div>
  )
}

export default AnonymizeUserView
