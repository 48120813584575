import { useParams } from 'react-router-dom'
import { ProgramMatchParams } from 'src/models/routeParams'
import { useProgram } from 'src/shared/hooks/program'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import styled from 'styled-components'
import WaitList from '../views/WaitList'

const Container = styled.div`
  width: 100%;
`

const WaitListContainer = () => {
  /**
   * ----- Hook Initialization -----
   */

  const { id: programId } = useParams<ProgramMatchParams>()
  const { isLoading, program } = useProgram(programId)

  /**
   * ----- Render -----
   */

  if (isLoading || !program) {
    return <LoadingScreen />
  }
  return (
    <Container>
      <WaitList program={program} />
    </Container>
  )
}

export default WaitListContainer
