import styled from 'styled-components'
import TextField from 'src/shared/views/TextField'
import { COLORS, FONTS } from '../theme'
import { Link } from 'react-router-dom'
import { DetailsContainer } from './Layout'

export const HiddenInput = styled.input`
  display: none;
`
export const PageBanner = styled.div`
  height: 25px;
  background-color: ${COLORS.borderGray};
  width: 100%;
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
`
export const StyledHeading = styled.h1`
  font-family: ${FONTS.bold};
  font-size: 30px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  letter-spacing: 0.06px;
  line-height: 39px;
`

export const AddSectionButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: max-content;
  padding: 8px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${COLORS.borderGray};
  border-radius: 4px;
`
export const PageContainer = styled.div`
  padding: 40px;
  & .page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  & .page-content {
    padding-top: 40px;
    & .add-title {
      & p {
        padding-left: 8px;
      }
    }
  }
  & .button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    & .new-section {
      margin-top: 10px;
    }
  }
  & .hover-to-edit-anchor {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 5px;
  }
`
export const AddTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 40px 30px;
  background-color: ${COLORS.borderGray};
  & .fields-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    & p:first-child {
      margin-right: 35px;
    }
    & .MuiFormControl-root:last-child {
      margin-right: 0;
    }
  }
  & .button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 35px;
    & :last-child {
      margin-right: 0;
    }
  }
`
export const NewPageBanner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.borderGray};
  width: 100%;
  padding: 15px;
  transition: background-color 100ms ease-in;
  & p {
    padding-left: 5px;
  }
  &:hover {
    cursor: pointer;
    background-color: ${COLORS.darkGray};
  }
`

export const TitleTextField = styled(TextField)`
  background-color: white;
  height: 56px;
  width: 30%;
  border-radius: 4px;
  & input {
    border-radius: 4px;
  }
  & .MuiInputBase-root {
    background-color: white;
  }
`
export const DescriptionTextField = styled(TextField)`
  background-color: white;
  height: 56px;
  width: 70%;
  border-radius: 4px;
  & input {
    border-radius: 4px;
  }
  & .MuiInputBase-root {
    background-color: white;
  }
`
export const QuestionWarning = styled.div`
  background-color: ${COLORS.orange200};
  width: 100%;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid ${COLORS.orange800};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const QuestionContainer = styled.div`
  background-color: ${COLORS.borderGray};
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 40px 30px;
  & .question-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;
    width: 100%;
    & .MuiTextField-root {
      width: 70%;
    }
    & .label {
      width: 10%;
    }
  }
  & .options-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 40px;
    align-items: center;
    & .option {
      margin-bottom: 40px;
      width: 100%;
      display: flex;
      flex-direction: row;
      & .is-correct {
        width: 10%;
      }
      & .answer {
        width: 70%;
      }
      & .weight {
        display: flex;
        flex-direction: column;
        width: 10%;
        margin-left: 20px;
        & .MuiTextField-root {
          width: 50px;
        }
      }
    }
    & .headers {
      display: flex;
      flex-direction: row;
      width: 100%;
      & .is-correct-header {
        width: 10%;
      }
      & .answers-header {
        width: 70%;
      }
      & .weight-header {
        width: 10%;
        margin-left: 20px;
      }
    }
    & :last-child {
      margin-bottom: 0;
    }
  }

  & .response-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;
    & .spacer {
      width: 10%;
    }
    & .content {
      display: flex;
      flex-direction: column;
      width: 90%;
      & .MuiTextField-root {
        width: 100%;
      }
    }
  }
  & .button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 35px;
    & .buttons {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      & :last-child {
        margin-right: 0;
      }
    }
  }
`

export const QuestionTextField = styled(TextField)`
  background-color: white;
  height: 56px;
  margin-right: 0;
  border-radius: 4px;
  & input {
    border-radius: 4px;
  }
  & .MuiInputBase-root {
    background-color: white;
  }
`

export const TimeInput = styled(QuestionTextField)`
  width: 250px;
`

export const AddLMSDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  & div.form {
    & > div:nth-of-type(2) {
      display: flex;
      flex-direction: row;
    }
    & > div:not(:last-child) {
      margin-bottom: 30px;
    }
    & .button-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
    }
  }
`
export const LearningModulesContainer = styled.div`
  padding: 50px;
  & .table-container {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
  }
`
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const ViewLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 16px;
  font-weight: 500;
  font-family: 'Gotham Medium';
  letter-spacing: 0.37px;
  line-height: 22px;
  text-align: center;
`
export const TitleDisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 5px;
  & p:first-child {
    margin-bottom: 30px;
  }
  & p {
    margin-top: 0;
  }
`
export const HoverToEditContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  & .button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 5px;
    position: absolute;
    top: 0;
    z-index: 1200;
    & button:first-child {
      margin-right: 5px;
    }
  }
  & .background {
    background-color: black;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 100ms ease-in;
  }
  &:hover {
    opacity: 1;
    & .background {
      opacity: 0.1;
    }
  }
`

export const QuestionDisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  & .options {
    & .option {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .circle {
        border-radius: 50%;
        border: 1px solid ${COLORS.borderGray};
        height: 20px;
        width: 20px;
        margin-right: 15px;
      }
      & p {
        margin-top: 0;
      }
    }
  }
`

export const LearningImage = styled.img`
  max-width: 300px;
  height: auto;
  margin-top: 60px;
  margin-bottom: 10px;
`

export const AddImageContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 40px 30px;
  background-color: ${COLORS.borderGray};
  & form > p {
    margin-bottom: 10px;
  }
  & .button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-top: 35px;
  }
  & .image-label {
    background-color: white;
  }
`
export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
`
export const ProgressBar = styled.div<{ pages: number; currentPage: number }>`
  background-color: ${COLORS.borderGray};
  height: 10px;
  margin: 10px 25px 20px 25px;
  border-radius: 5px;
  &::after {
    display: block;
    content: '';
    background-color: ${COLORS.cerulean};
    width: ${(props) => (props.currentPage * 100) / props.pages}%;
    height: 10px;
    border-radius: 5px;
  }
`
export const LearningHeader = styled(DetailsContainer)`
  flex-direction: column;
  background-color: #f5f5f5;
  display: flex;
  padding: 35px 34px 25px 36px;
  & .back-button {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    & .MuiTypography-root {
      color: black;
      margin-left: 5px;
    }
  }
  & .title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`
export const ActionItems = styled.div`
  display: flex;
  flex-direction: row;
  & .download-link {
    color: ${(props) => props.theme.palette.primary.main};
  }
  & :first-child {
    margin-right: 10px;
  }
  & svg {
    cursor: pointer;
  }
`
export const ModalButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & button {
    margin: 20px 50px;
  }
`

export const LearningButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const LearningStatusContainer = styled.div`
  display: flex;
  padding: 25px 20px;
  flex-direction: row;
  justify-content: flex-end;
`
