import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import styled from 'styled-components'
import * as yup from 'yup'

import { IBlockUserBody } from 'src/models/user'
import { Heading } from 'src/shared/styled/Text'
import { DropDown } from 'src/shared/views/DropDown'
import TextField from 'src/shared/views/TextField'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'
import { UseMutationResult } from 'react-query'

interface IBlockUserView {
  blockUserMutation: UseMutationResult<void, unknown, IBlockUserBody, unknown>
  closeModal: () => void
}

const REASON_DROPDOWN_ITEMS = [
  {
    value: 'Account abuse',
    label: 'Account abuse'
  },
  {
    value: 'Inappropriate Behaviour',
    label: 'Inappropriate Behaviour'
  }
]

const inOneMonth = new Date()
inOneMonth.setMonth(inOneMonth.getMonth() + 1)
const schema = yup.object().shape({
  reason: yup.string().required(),
  notes: yup.string()
})

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`

const formFieldStyles = {
  marginTop: '40px',
  marginBottom: '40px',
  width: '270px'
}

const BlockUserView: FC<IBlockUserView> = ({
  blockUserMutation,
  closeModal
}) => {
  const { control, handleSubmit } = useForm<IBlockUserBody>({
    resolver: yupResolver(schema)
  })

  const { isLoading, isError, mutate } = blockUserMutation

  const onSubmit = (values: IBlockUserBody) => {
    mutate(values)
  }

  return (
    <div>
      <Heading>Block User</Heading>
      <p>
        Blocking a user temporarily removes their access to the Blue Charm app
        and associated activities. You may unblock this person at any time to
        restore access. Please specify the reason for blocking.
      </p>
      <div>
        <Controller
          defaultValue={REASON_DROPDOWN_ITEMS[0].value}
          render={({ field: { ref, ...props } }) => (
            <DropDown
              style={formFieldStyles}
              items={REASON_DROPDOWN_ITEMS}
              label="Reason for block"
              inputRef={ref}
              {...props}
            />
          )}
          control={control}
          name="reason"
        />
      </div>
      <Controller
        defaultValue={''}
        render={({ field: { ref, ...props }, fieldState: { error } }) => (
          <TextField
            style={{ width: '100%' }}
            rows={2}
            label="Additional notes"
            size="medium"
            multiline
            error={!!error?.message}
            helperText={error?.message}
            inputRef={ref}
            {...props}
          />
        )}
        name="notes"
        control={control}
      />
      {isError && <p>An error occurred blocking this user</p>}
      <ButtonsWrapper>
        <ButtonWithMargin
          color="primary"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        >
          Block
        </ButtonWithMargin>
        <ButtonWithMargin
          color="primary"
          variant="outlined"
          onClick={closeModal}
        >
          Cancel
        </ButtonWithMargin>
      </ButtonsWrapper>
    </div>
  )
}

export default BlockUserView
