import Select, {
  ActionMeta,
  GroupBase,
  MultiValue,
  SingleValue,
  StylesConfig
} from 'react-select'

export interface ISelectItem {
  label: string
  value: string
}

interface ISearchSelectBase {
  options: ISelectItem[]
  label?: string
  select?: boolean
  SelectProps?: any
  disabled?: boolean
  styles?: StylesConfig<
    ISelectItem | MultiValue<ISelectItem>,
    false,
    GroupBase<ISelectItem>
  >
}

interface ISearchSelectSingle extends ISearchSelectBase {
  onChange: (
    newValue: SingleValue<ISelectItem> | null,
    actionMeta: ActionMeta<ISelectItem>
  ) => void
  value: SingleValue<ISelectItem>
  isMulti?: false
}

interface ISearchSelectMulti extends ISearchSelectBase {
  isMulti: true
  value: MultiValue<ISelectItem>
  onChange: (
    newValue: MultiValue<ISelectItem> | null,
    actionMeta: ActionMeta<ISelectItem>
  ) => void
}

type ISearchSelect = ISearchSelectSingle | ISearchSelectMulti

const SearchSelect = ({
  options,
  onChange,
  label,
  styles,
  value,
  disabled,
  isMulti
}: ISearchSelect) => {
  return (
    <div>
      <span>{label}</span>
      <Select
        options={options}
        onChange={onChange}
        isDisabled={disabled}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          ...styles
        }}
        isClearable
        isMulti={isMulti}
        value={value}
      />
    </div>
  )
}

export default SearchSelect
