import React from 'react'
import { useHistory } from 'react-router-dom'
import { IModuleFormData } from 'src/models/module'
import { useCreateModule } from 'src/shared/hooks/modules'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import { Container, HeaderContainer } from 'src/shared/styled/Module'
import { PageLevelHeading } from 'src/shared/styled/Text'
import ModuleFormContainer from '../containers/ModuleForm'

const ModuleCreate = () => {
  /**
   * ----- Hook Initialization -----
   */

  const history = useHistory()

  const [data, setData] = React.useState<IModuleFormData | undefined>()

  const { isLoading, createModule } = useCreateModule({
    onSuccess: (moduleId) => {
      // Redirect to the module page
      history.push(`/module/${moduleId}`)
    }
  })

  /**
   * ----- Functions -----
   */

  const onChange = React.useCallback((formData: IModuleFormData) => {
    setData(formData)
  }, [])

  const onSave = React.useCallback(() => {
    if (!data) return
    createModule(data)
  }, [createModule, data])

  /**
   * ----- Render -----
   */

  return (
    <Container>
      <HeaderContainer>
        <PageLevelHeading>Create New Module</PageLevelHeading>
        <ButtonWithWidth disabled={isLoading} onClick={onSave}>
          Create
        </ButtonWithWidth>
      </HeaderContainer>
      <ModuleFormContainer onChange={onChange} />
    </Container>
  )
}

export default ModuleCreate
