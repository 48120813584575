import { ChangeEventHandler, FC } from 'react'
import styled from 'styled-components'
import { Delete, Image } from '@material-ui/icons'

import { COLORS, FONTS } from '../theme'
import { Text } from 'src/shared/styled'

const ImageWrapper = styled.div``

const ImageContainer = styled.div<{ width: number }>`
  position: relative;
  width: ${(props) => props.width}px;
  margin-bottom: 5px;
`

const ImageError = styled.p`
  color: red;
  margin-bottom: 20px;
`

const ClearImageButton = styled.button`
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
`

const ImageUploadLabel = styled.label`
  align-items: center;
  border: 1px solid ${COLORS.borderGray};
  border-radius: 4px;
  font-size: 14px;
  font-family: ${FONTS.medium};
  margin-bottom: 30px;
  justify-content: space-between;
  display: inline-flex;
  padding: 10px;
  cursor: pointer;
  span {
    text-transform: uppercase;
    padding: 0 5px;
  }
  &:hover {
    background-color: ${COLORS.borderGray};
  }
`
const { LightText } = Text

export interface IImageUploaderProps {
  disableUploading?: boolean
  error?: string
  imagePath?: string
  id: string
  label: string
  onHandleImageChange: ChangeEventHandler<HTMLInputElement>
  onClearImage: () => void
  helperText?: string
  width?: number
  height?: number
}

const ImageUploader: FC<IImageUploaderProps> = ({
  disableUploading,
  error,
  imagePath,
  id,
  onHandleImageChange,
  onClearImage,
  helperText,
  width,
  height,
  label
}) => (
  <ImageWrapper>
    {!disableUploading && (
      <>
        <ImageUploadLabel className="image-label" htmlFor={id}>
          <Image /> <span>{label}</span>
        </ImageUploadLabel>
        <input onChange={onHandleImageChange} id={id} type="file" hidden />
      </>
    )}
    <ImageContainer width={width || 220}>
      {!!imagePath && (
        <>
          {!disableUploading && (
            <ClearImageButton onClick={onClearImage}>
              <Delete style={{ color: COLORS.doveGray }} />
            </ClearImageButton>
          )}
          <img src={imagePath} width={width || 220} height={height || 220} />
        </>
      )}
      {error && <ImageError>{error}</ImageError>}
    </ImageContainer>
    {!!helperText && <LightText>{helperText}</LightText>}
  </ImageWrapper>
)

export { ImageUploader }
