import { IProgram, IProgramEntryCode } from 'src/models/program'
import { useEntryCodeForm } from 'src/shared/forms/EntryCode'
import { useUpdateEntryCode } from 'src/shared/hooks/program'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import Modal from 'src/shared/views/Modal'
import styled from 'styled-components'

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
`

interface IUpdateEntryCodeModal {
  program: IProgram
  entryCode: IProgramEntryCode
  isOpen: boolean
  handleClose: () => void
}

const UpdateEntryCodeModal = ({
  program,
  entryCode,
  isOpen,
  handleClose
}: IUpdateEntryCodeModal) => {
  /**
   * ----- Hook Initialization -----
   */

  const { FormComponents } = useEntryCodeForm({
    defaultValues: {
      description: entryCode.description,
      singleUse: entryCode.singleUse
    }
  })

  const { updateEntryCode } = useUpdateEntryCode(program.id)

  /**
   * ----- Render -----
   */

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Create Entry Code">
      <FormComponents.Form
        submitHandler={(data) =>
          updateEntryCode({ codeId: entryCode.codeId, data })
        }
      >
        <div>
          <FormComponents.Name />
          <FormComponents.SingleUse />
        </div>
        <ButtonContainer>
          <div></div>
          <div>
            <ButtonWithWidth variant="outlined" onClick={handleClose}>
              Cancel
            </ButtonWithWidth>
            <ButtonWithWidth type="submit">Submit</ButtonWithWidth>
          </div>
        </ButtonContainer>
      </FormComponents.Form>
    </Modal>
  )
}

export default UpdateEntryCodeModal
