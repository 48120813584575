import { FormLabel } from '@material-ui/core'
import React from 'react'
import { LanguageMap } from 'src/models/misc'
import styled from 'styled-components'
import TextField, { TextFieldProps } from './TextField'

const FormControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
`

const StyledTextField = styled(TextField)`
  margin: 10px 0;
  width: 100%;
`

interface ILanguageMap {
  value: LanguageMap
  onChange: (value: LanguageMap) => void
  label?: string
  supportedLanguages: ('en' | 'fr')[]
  textFieldProps?: Omit<TextFieldProps, 'ref'>
}

const LanguageMapForm = ({
  value,
  onChange,
  label,
  supportedLanguages,
  textFieldProps
}: ILanguageMap) => {
  /**
   * ----- Hook Initialization -----
   */

  const [localValue, setLocalValue] = React.useState<LanguageMap>(value)

  /**
   * ----- Functions -----
   */

  const getTextFieldLabel = React.useCallback((lang: string) => {
    switch (lang) {
      case 'en':
        return 'English'
      case 'fr':
        return 'French'
      default:
        return lang
    }
  }, [])

  /**
   * ----- Render -----
   */

  return (
    <FormControlContainer>
      {label && <FormLabel>{label}</FormLabel>}
      {supportedLanguages.map((lang) => (
        <StyledTextField
          key={lang}
          label={getTextFieldLabel(lang)}
          value={localValue[lang]}
          onChange={(e) => {
            setLocalValue({ ...localValue, [lang]: e.target.value })
            onChange({ ...value, [lang]: e.target.value })
          }}
          {...textFieldProps}
        />
      ))}
    </FormControlContainer>
  )
}

export default LanguageMapForm
