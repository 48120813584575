import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from 'react-query'
import { UsersApi } from 'src/api/client'
import {
  FETCH_ALL_USERS,
  FETCH_TASKS_USER_PROGRAM,
  FETCH_USER,
  FETCH_USER_ACTIVITIES,
  FETCH_USER_BALANCES,
  FETCH_USER_CLAIMS,
  FETCH_USER_DIRECT_DEPOSITS,
  FETCH_USER_LEARNING_MODULE_RESULTS,
  FETCH_USER_NOTES,
  FETCH_USER_PRESCRIPTIONS,
  FETCH_USER_PROGRAMS,
  FETCH_USER_PROGRAM_CERTIFICATE,
  FETCH_USER_TRANSACTIONS,
  FETCH_USER_TRANSACTIONS_FOR_PROGRAM
} from 'src/constants/queries'
import { IPutUserProgramCertificateData } from 'src/features/userAdmin/hooks/useProgramCertificateForm'
import { IProgramMembershipClaimData, ITransaction } from 'src/models/program'
import { IAllCompletedTransactions } from 'src/models/transaction'
import {
  IBlockUserBody,
  IDirectDeposit,
  IDirectDepositData,
  IUser,
  IUserActivities,
  IUserAddress,
  IUserBalances,
  IUserClaim,
  IUserLearningModuleResponses,
  IUserNoteBody,
  IUserNoteGet,
  IUserProgramCertificate,
  IUserProgramProgramMemberships,
  UserClaimStatus
} from 'src/models/user'
import { logger } from 'src/utils/logger'

/**
 * ------ Queries -----
 */

export const useUser = (userId: string) => {
  const query = useQuery<IUser>(FETCH_USER(userId), () =>
    UsersApi.getUser(userId)
  )

  return { ...query, user: query.data }
}

export const useUserBalances = (userId: string) => {
  const query = useQuery<IUserBalances>(FETCH_USER_BALANCES(userId), () =>
    UsersApi.getUserBalances(userId)
  )

  return { ...query, balance: query.data }
}

export const useUsers = () => {
  const query = useQuery<IUser[]>(FETCH_ALL_USERS, () => UsersApi.listUsers())

  return { ...query, users: query.data }
}

export const useUserActivities = (
  userId: string,
  options?: UseQueryOptions<IUserActivities[]>
) => {
  const query = useQuery<IUserActivities[]>(
    FETCH_USER_ACTIVITIES(userId),
    () => UsersApi.listActivities(userId),
    {
      ...options
    }
  )

  return { ...query }
}

export const useUserPrograms = (userId: string) => {
  const query = useQuery<IUserProgramProgramMemberships[]>(
    FETCH_USER_PROGRAMS(userId),
    () => UsersApi.listPrograms(userId)
  )

  return { ...query, programMemberships: query.data }
}

export const useUserNotes = (userId: string) => {
  const query = useQuery<IUserNoteGet[]>(FETCH_USER_NOTES(userId), () =>
    UsersApi.listUserNotes(userId)
  )

  return { ...query, notes: query.data }
}

export const useUserCompletedTasks = (userId: string, programId: string) => {
  const query = useQuery<IAllCompletedTransactions[]>(
    FETCH_TASKS_USER_PROGRAM(userId, programId),
    () => UsersApi.listCompletedTasksForProgram(userId, programId)
  )

  return { ...query, completedTasks: query.data }
}

export const useUserTransactionsForProgram = (
  userId: string,
  programId: string
) => {
  const query = useQuery<ITransaction[]>(
    FETCH_USER_TRANSACTIONS_FOR_PROGRAM(userId, programId),
    () => UsersApi.listTransactionsForProgram(userId, programId)
  )

  return { ...query, transactions: query.data }
}

export const useUserTransactions = (userId: string) => {
  const query = useQuery<ITransaction[]>(FETCH_USER_TRANSACTIONS(userId), () =>
    UsersApi.listTransactions(userId)
  )

  return { ...query, transactions: query.data }
}

export const useUserProgramCertificate = (
  userId: string,
  programId: string
) => {
  const query = useQuery<IUserProgramCertificate>(
    FETCH_USER_PROGRAM_CERTIFICATE(userId, programId),
    () => UsersApi.fetchCertificateForProgram(userId, programId)
  )

  return { ...query, certificate: query.data }
}

export const useUserDirectDeposits = (userId: string) => {
  const query = useQuery<IDirectDeposit[]>(
    FETCH_USER_DIRECT_DEPOSITS(userId),
    () => UsersApi.fetchDirectDeposits(userId)
  )

  return { ...query, directDeposits: query.data }
}

export const useFetchClaims = (userId: string) => {
  const query = useQuery<IUserClaim[]>(FETCH_USER_CLAIMS(userId), () =>
    UsersApi.fetchClaims(userId)
  )

  return { ...query, claims: query.data }
}

export const useLearningModuleResults = (
  userId: string,
  programId: string,
  learningModuleId: string
) => {
  const query = useQuery<IUserLearningModuleResponses[]>(
    FETCH_USER_LEARNING_MODULE_RESULTS(userId, programId, learningModuleId),
    () => UsersApi.getLearningModuleResults(userId, programId, learningModuleId)
  )

  return { ...query, learningModuleResults: query.data }
}

/**
 * ----- Mutations -----
 */

export const useUpdateUser = (
  userId: string,
  { onSuccess }: { onSuccess?: () => void }
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (data: IUser) => UsersApi.updateUser(userId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_USER(userId))
        queryClient.invalidateQueries(FETCH_ALL_USERS)
        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('There was an error updating this user', err)
      }
    }
  )

  return { ...mutation, updateUser: mutation.mutate }
}

export const useDeleteUserProgramMembership = (userId: string) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (programId: string) =>
      UsersApi.deleteProgramMembership(
        programId,
        userId,
        'PlatformAdmin deleted membership (likely for testing)',
        false
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_USER_PROGRAMS(userId))
      },
      onError: (err) => {
        logger.error('An error occured when deleting users membership', err)
      }
    }
  )

  return { ...mutation, deleteMembership: mutation.mutate }
}

export const useCreateUserNote = (userId: string) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (data: IUserNoteBody) => UsersApi.createUserNote(userId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_USER_NOTES(userId))
      },
      onError: (err) => {
        logger.error('Error creating this note', err)
      }
    }
  )

  return { ...mutation, createNote: mutation.mutate }
}

export const useAnonymizeUser = (
  userId: string,
  { onSuccess }: { onSuccess?: () => void }
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(() => UsersApi.anonymizeUser(userId), {
    onSuccess: () => {
      queryClient.invalidateQueries(FETCH_USER(userId))
      queryClient.invalidateQueries(FETCH_ALL_USERS)
      queryClient.invalidateQueries(FETCH_USER_PRESCRIPTIONS(userId))

      if (onSuccess) onSuccess()
    },
    onError: (err) => {
      logger.error('There was an error anonymizing this user', err)
    }
  })

  return { ...mutation, anonymizeUser: mutation.mutate }
}

export const usePurgeUser = (
  userId: string,
  { onSuccess }: { onSuccess?: () => void }
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(() => UsersApi.purgeUser(userId), {
    onSuccess: () => {
      queryClient.invalidateQueries(FETCH_USER(userId))
      queryClient.invalidateQueries(FETCH_ALL_USERS)
      queryClient.invalidateQueries(FETCH_USER_PRESCRIPTIONS(userId))

      if (onSuccess) onSuccess()
    },
    onError: (err) => {
      logger.error('There was an error purgin this user', err)
    }
  })

  return { ...mutation, purgeUser: mutation.mutate }
}

export const usePurgeAllUserTraces = (
  userId: string,
  { onSuccess }: { onSuccess?: () => void }
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(() => UsersApi.purgeAllUserTraces(userId), {
    onSuccess: () => {
      queryClient.invalidateQueries(FETCH_USER(userId))
      queryClient.invalidateQueries(FETCH_ALL_USERS)
      queryClient.invalidateQueries(FETCH_USER_PRESCRIPTIONS(userId))

      if (onSuccess) onSuccess()
    },
    onError: (err) => {
      logger.error('There was an error erasing this user', err)
    }
  })

  return { ...mutation, purgeAllUserTraces: mutation.mutate }
}

export const useBlockUser = (
  userId: string,
  { onSuccess }: { onSuccess?: () => void }
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (data: IBlockUserBody) => UsersApi.blockUser(userId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_USER(userId))
        queryClient.invalidateQueries(FETCH_ALL_USERS)
        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('An error occured when blocking this user', err)
      }
    }
  )

  return { ...mutation, blockUser: mutation.mutate }
}

export const useUnblockUser = (
  userId: string,
  { onSuccess }: { onSuccess?: () => void }
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(() => UsersApi.unblockUser(userId), {
    onSuccess: () => {
      queryClient.invalidateQueries(FETCH_USER(userId))
      queryClient.invalidateQueries(FETCH_ALL_USERS)

      if (onSuccess) onSuccess()
    },
    onError: (err) => {
      logger.error('There was an error unblocking this user', err)
    }
  })

  return { ...mutation, unblockUser: mutation.mutate }
}

export const useRequestPhysicalCard = (
  userId: string,
  { onSuccess }: { onSuccess?: () => void }
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (data: IUserAddress) => UsersApi.requestPhysicalCard(userId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_USER(userId))

        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('An error occured when requesting a physical card', err)
      }
    }
  )

  return { ...mutation, requestPhysicalCard: mutation.mutate }
}

export const useUpdateUserProgramCertificate = (
  userId: string,
  programId: string,
  { onSuccess }: { onSuccess?: () => void }
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (data: IPutUserProgramCertificateData) =>
      UsersApi.updateCertificateForProgram(userId, programId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          FETCH_USER_PROGRAM_CERTIFICATE(userId, programId)
        )

        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('An error occured when trying to update certificate', err)
      }
    }
  )

  return { ...mutation, updateCertificate: mutation.mutate }
}

export const useDeleteUserProgramCertificate = (
  userId: string,
  programId: string
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    () => UsersApi.deleteCertificateForProgram(userId, programId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          FETCH_USER_PROGRAM_CERTIFICATE(userId, programId),
          {}
        )
      },
      onError: (err) => {
        logger.error('An error occured while trying to delete certificate', err)
      }
    }
  )

  return { ...mutation, deleteCertificate: mutation.mutate }
}

export const useAddClaimToProgramMembership = (
  userId: string,
  programId: string,
  options?: { onSuccess?: () => void; onError?: (err: unknown) => void }
) => {
  const mutation = useMutation(
    (data: IProgramMembershipClaimData) =>
      UsersApi.addClaimToProgramMembership(userId, programId, data),
    {
      onSuccess: () => {
        if (options?.onSuccess) options.onSuccess()
      },
      onError: (err) => {
        logger.error('An error occured while trying to add claim', err)
        if (options?.onError) options.onError(err)
      }
    }
  )

  return { ...mutation, addClaim: mutation.mutate }
}

export const useAddDirectDeposit = (
  userId: string,
  options?: { onSuccess?: () => void }
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (data: IDirectDepositData) => UsersApi.addDirectDeposit(userId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_USER_DIRECT_DEPOSITS(userId))

        if (options?.onSuccess) options.onSuccess()
      },
      onError: (err) => {
        logger.error('An error occured while trying to add direct deposit', err)
      }
    }
  )

  return { ...mutation, addDirectDeposit: mutation.mutate }
}

export const useUpdateDirectDeposit = (
  userId: string,
  directDepositId: string,
  options?: { onSuccess?: () => void }
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (data: IDirectDepositData) =>
      UsersApi.updateDirectDeposit(userId, directDepositId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_USER_DIRECT_DEPOSITS(userId))

        if (options?.onSuccess) options.onSuccess()
      },
      onError: (err) => {
        logger.error(
          'An error occured while trying to update direct deposit',
          err
        )
      }
    }
  )

  return { ...mutation, updateDirectDeposit: mutation.mutate }
}

export const useDeleteDirectDeposit = (userId: string) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (directDepositId: string) =>
      UsersApi.deleteDirectDeposit(userId, directDepositId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_USER_DIRECT_DEPOSITS(userId))
      },
      onError: (err) => {
        logger.error(
          'An error occured while trying to delete direct deposit',
          err
        )
      }
    }
  )

  return { ...mutation, deleteDirectDeposit: mutation.mutate }
}

export const useUpdateClaimStatus = (
  userId: string,
  claimId: string,
  options?: { onSuccess?: () => void; onError?: () => void }
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (status: UserClaimStatus) =>
      UsersApi.updateClaimStatus(userId, claimId, status),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_USER_CLAIMS(userId))
        if (options?.onSuccess) options.onSuccess()
      },
      onError: (err) => {
        logger.error(
          'An error occured while trying to update claim status',
          err
        )

        if (options?.onError) options.onError()
      }
    }
  )

  return { ...mutation, updateClaimStatus: mutation.mutate }
}
