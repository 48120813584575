import { ModuleTask, ModuleTaskType } from 'src/models/module'

export const createEmptyModuleTask = (
  type: ModuleTaskType,
  taskId: string,
  supportedLanguages: ('en' | 'fr')[]
): ModuleTask => {
  const text = supportedLanguages.reduce((acc, lang) => {
    acc[lang] = ''
    return acc
  }, {} as Record<'en' | 'fr', string>)
  const baseTask = {
    taskId,
    position: { x: 0, y: 0 },
    text
  }

  switch (type) {
    case ModuleTaskType.FreeFormResponse:
    case ModuleTaskType.Message: {
      return {
        ...baseTask,
        type
      }
    }
    case ModuleTaskType.SingleSelectQuestion: {
      return {
        ...baseTask,
        type,
        navigationMap: {},
        selectableOptions: []
      }
    }
    case ModuleTaskType.MultiSelectQuestion: {
      return {
        ...baseTask,
        type,
        selectableOptions: []
      }
    }
  }
}

export const parseEdgeId = (
  edgeId: string
): { taskId: string; followupTaskId: string; selectableOptionId?: string } => {
  const split = edgeId.split('_')
  if (split.length === 3) {
    return {
      taskId: split[0],
      selectableOptionId: split[1],
      followupTaskId: split[2]
    }
  } else {
    return {
      taskId: split[0],
      followupTaskId: split[1],
      selectableOptionId: undefined
    }
  }
}
