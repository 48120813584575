import { TableRow } from '@material-ui/core'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'
import {
  CellContext,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { FC, PropsWithChildren, useMemo } from 'react'
import { TRANSACTION_DATE_TIME_FORMAT } from 'src/constants/datetimes'

import { ITransaction } from 'src/models/program'
import {
  FocusedTableCell,
  StyledTable,
  TableHeaderCell
} from 'src/shared/styled/Table'
import { momentFormat } from 'src/utils/dates'
import { fuzzyFilter } from 'src/utils/table'

interface Transaction extends Omit<ITransaction, 'payerId'> {
  amount: {
    amount: number
    currencyCode: string
  }
}

type TransactionsProps = {
  data: Transaction[]
}

const Transactions: FC<TransactionsProps> = ({ data }) => {
  /**
   * ----- Variables -----
   */

  const columns: ColumnDef<Transaction, any>[] = useMemo(
    () => [
      {
        header: 'Date & Time',
        accessorKey: 'createdAt',
        cell: ({ row }: PropsWithChildren<CellContext<Transaction, any>>) => (
          <span>
            {momentFormat(
              new Date(row.original.createdAt),
              TRANSACTION_DATE_TIME_FORMAT
            )}
          </span>
        )
      },
      {
        header: 'Transaction Type',
        accessorKey: 'transactionType'
      },
      {
        header: 'Activity Type',
        accessorKey: 'relatedActivityType',
        disableSortBy: true
      },
      {
        header: 'Amount',
        accessorKey: 'amount.amount',
        disableSortBy: true,
        cell: ({ row }: PropsWithChildren<CellContext<Transaction, any>>) => {
          const { amount, currencyCode } = row.original.amount
          return `$${amount.toFixed(2)} ${currencyCode}`
        }
      },
      {
        header: 'Status',
        accessorKey: 'status'
      },
      {
        header: 'Payee Ref',
        accessorKey: 'payeeRef'
      },
      {
        header: 'Notes',
        accessorKey: 'notes',
        disableSortBy: true,
        cell: ({ row }) => {
          return (
            <div
              dangerouslySetInnerHTML={{ __html: row.original.notes || '' }}
            />
          )
        }
      }
    ],
    []
  )

  /**
   * ----- Render -----
   */

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  return (
    <StyledTable>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHeaderCell
                key={header.id}
                style={{ width: header.getSize() }}
              >
                <>
                  <div
                    {...{
                      style: {
                        cursor: header.column.getCanSort()
                          ? 'pointer'
                          : undefined
                      },
                      onClick: header.column.getToggleSortingHandler()
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {header.column.getIsSorted() === 'asc' ? (
                      <ArrowUpward />
                    ) : header.column.getIsSorted() === 'desc' ? (
                      <ArrowDownward />
                    ) : null}
                  </div>
                </>
              </TableHeaderCell>
            ))}
          </TableRow>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <FocusedTableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </FocusedTableCell>
                )
              })}
            </TableRow>
          )
        })}
      </tbody>
    </StyledTable>
  )
}

export default Transactions
