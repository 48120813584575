import { FC } from 'react'
import styled from 'styled-components'
import { UseMutationResult } from 'react-query'
import { Delete } from '@material-ui/icons'

import { Heading } from 'src/shared/styled/Text'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`
interface IEraseUserView {
  eraseUserMutation: UseMutationResult<void, unknown, void, unknown>
  closeModal: () => void
}

const EraseUserView: FC<IEraseUserView> = ({
  closeModal,
  eraseUserMutation
}) => {
  const { isLoading, isError, mutate } = eraseUserMutation
  const onSubmit = () => {
    mutate()
  }

  return (
    <div>
      <Heading>Erase User</Heading>
      <p>
        Are you sure you want to erase this user? This action cannot be undone.
      </p>
      <p>
        This will remove ALL traces of this user from our system. This should
        only be used as a tool for developers to remove test data.
      </p>
      {isError && <p>An error occurred erasing this user</p>}

      <ButtonsWrapper>
        <ButtonWithMargin
          color="primary"
          variant="outlined"
          onClick={closeModal}
        >
          Cancel
        </ButtonWithMargin>
        <ButtonWithMargin
          startIcon={<Delete />}
          color="primary"
          onClick={onSubmit}
          isLoading={isLoading}
        >
          Erase
        </ButtonWithMargin>
      </ButtonsWrapper>
    </div>
  )
}

export default EraseUserView
