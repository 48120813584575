import { ReactFlowProvider } from '@xyflow/react'
import { ModuleFormProvider } from 'src/contexts/ModuleForm'
import { IModule, IModuleFormData } from 'src/models/module'
import ModuleForm from '../views/Form'

interface IModuleFormContainer {
  module?: IModule
  onChange: (formData: IModuleFormData) => void
}

const ModuleFormContainer = ({ module, onChange }: IModuleFormContainer) => {
  /**
   * ----- Render -----
   */

  return (
    <ReactFlowProvider>
      <ModuleFormProvider module={module} onChange={onChange}>
        <ModuleForm />
      </ModuleFormProvider>
    </ReactFlowProvider>
  )
}

export default ModuleFormContainer
