import { FC } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { UserAdminMatchParams } from 'src/models/routeParams'
import { usePurgeUser } from 'src/shared/hooks/users'
import PurgeUserView from '../views/PurgeUser'

interface IPurgeUserContainer {
  closeModal: () => void
}

const PurgeUserContainer: FC<IPurgeUserContainer> = ({ closeModal }) => {
  const { id: userId } = useParams<UserAdminMatchParams>()
  const history = useHistory()

  const purgeUser = usePurgeUser(userId, {
    onSuccess: () => {
      history.push('/users')
      closeModal()
    }
  })

  return <PurgeUserView closeModal={closeModal} purgeUserMutation={purgeUser} />
}

export default PurgeUserContainer
