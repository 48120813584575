export type LMSSection =
  | ILMSTextSection
  | ILMSQuestionSection
  | ILMSImageSection

export interface ILMSProvider {
  children: React.ReactNode
}

export interface ILMSOption {
  indexLabel?: string
  correct: boolean
  text: string
  weight: number
}

export interface ILMSQuestionSection {
  id: string
  type: string
  question: ILMSQuestion
  saved: boolean
}
export interface ILMSQuestion {
  type: string
  questionText: string
  answerOptions: ILMSOption[]
  correctAnswerFeedback: string
  incorrectAnswerFeedback: string
  weight?: number
  id?: string
}
export interface ILMSImageSection {
  id: string
  imageUrl: string
  imageDescription?: string
  saved: boolean
  type: string
}

export interface ILMSTextSection {
  id: string
  text: string
  saved: boolean
  type: string
}
export interface ILMSPage {
  id: string
  header?: string
  description?: string
  sections: LMSSection[]
  saved: boolean
}

export enum LMSStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  DELETED = 'DELETED'
}

export interface ILMS {
  learningModuleId?: string
  id: string
  title: string
  description: string
  estimatedDuration: string
  isActive: boolean
  pages: ILMSPage[]
  saved: boolean
  coverImageUrl: string
  status: LMSStatus
}

export interface IFetchedLMS {
  learningModuleId: string
  title: string
  description: string
  status: LMSStatus
  estimatedDuration: string
  coverImageUrl: string
  availableToPrograms: boolean
  pages: (Omit<ILMSPage, 'id'> & { pageId: string })[]
}
