import React, { ChangeEvent } from 'react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table'

import {
  FocusedTableCell,
  StyledTable,
  TableHeaderCell
} from 'src/shared/styled/Table'
import { TableRow, TextField } from '@material-ui/core'
import styled from 'styled-components'
import { COLORS } from 'src/shared/theme'
import { fuzzyFilter } from 'src/utils/table'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import Modal from 'src/shared/views/Modal'
import { useVerifications } from 'src/shared/hooks/verifications'
import Tab from 'src/shared/views/Navigation/TabNavigation'

import {
  IVerificationTask,
  VerificationTaskStatus
} from 'src/models/verificationTask'
import moment from 'moment'
import useStorage from 'src/shared/hooks/useStorage'
import VerificationModal from 'src/components/VerificationModal'

const HoverableTableRow = styled(TableRow)`
  &:hover {
    background-color: ${COLORS.blizzardBlue};
    cursor: pointer;
  }
`

const RightPageControl = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const pageLimit = 25

const storageKey = 'verificationTableEndDate'

interface IVerificationTable {
  userId?: string
  disableEndDate?: boolean
  noTitle?: boolean
}

const VerificationTable = ({
  userId,
  disableEndDate,
  noTitle
}: IVerificationTable) => {
  /**
   * ----- Hook Initialization -----
   */

  const { getItem, setItem } = useStorage()

  const [currentTab, setCurrentTab] = React.useState(0)
  const [modalTask, setModalTask] = React.useState<IVerificationTask>()
  const [pageIndex, setPageIndex] = React.useState(0)
  const [endDate, setEndDate] = React.useState<string>(
    getItem(storageKey) || moment().subtract(2, 'weeks').format('YYYY-MM-DD')
  )

  const status = React.useMemo(() => {
    switch (currentTab) {
      case 0:
        return VerificationTaskStatus.Actionable
      case 1:
        return VerificationTaskStatus.Rejected
      case 2:
        return VerificationTaskStatus.NotActionable
      case 3:
        return VerificationTaskStatus.Verified
      default:
        return VerificationTaskStatus.Actionable
    }
  }, [currentTab])

  const { isLoading, verificationTasks, totals, refetch } = useVerifications(
    status,
    pageIndex * pageLimit,
    pageLimit,
    disableEndDate ? undefined : new Date(endDate),
    userId
  )

  /**
   * ----- Functions -----
   */

  const handleTabChange = (
    _event: ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => {
    setPageIndex(0)
    setCurrentTab(newValue)
  }

  const handleModalClose = () => {
    setModalTask(undefined)
    refetch()
  }

  /**
   * ----- Variables -----
   */

  const pageTotal = React.useMemo(() => {
    return totals ? totals[status] : 0
  }, [status, totals])

  const columns: ColumnDef<IVerificationTask, any>[] = React.useMemo(
    () => [
      {
        header: 'User Name',
        accessorKey: 'userName'
      },
      {
        header: 'Program Name',
        accessorKey: 'programName'
      },
      {
        header: 'Membership Status',
        accessorKey: 'programVerificationStatus'
      },
      {
        header: 'Prescription Statuses',
        cell: ({ row }) => {
          return (
            <div>
              {row.original.prescriptions.map((prescription) => {
                return (
                  <div key={prescription.prescriptionId}>
                    <p>
                      <b>{prescription.drugName}</b>:{' '}
                      {prescription.verification.status}
                    </p>
                  </div>
                )
              })}
            </div>
          )
        }
      }
    ],
    []
  )

  const tasks = React.useMemo(() => {
    return verificationTasks ?? []
  }, [verificationTasks])

  /**
   * ----- Lifecycle -----
   */

  React.useEffect(() => {
    setItem(storageKey, endDate)
  }, [endDate, setItem])

  /**
   * ----- Render -----
   */

  const table = useReactTable({
    columns,
    data: tasks,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  })

  const pageControl = React.useMemo(() => {
    if (!isLoading) {
      return (
        <div>
          <button onClick={() => setPageIndex(0)} disabled={pageIndex === 0}>
            {'<<'}
          </button>
          <button
            onClick={() => setPageIndex(pageIndex - 1)}
            disabled={pageIndex === 0}
          >
            {'<'}
          </button>
          <button
            onClick={() => setPageIndex(pageIndex + 1)}
            disabled={pageIndex * pageLimit + pageLimit >= pageTotal}
          >
            {'>'}
          </button>
          <button
            onClick={() => setPageIndex(Math.floor(pageTotal / pageLimit))}
            disabled={pageIndex * pageLimit + pageLimit >= pageTotal}
          >
            {'>>'}
          </button>
          <span style={{ marginLeft: 5 }}>
            {pageIndex + 1} / {Math.floor(pageTotal / pageLimit) + 1}
          </span>
        </div>
      )
    }
  }, [isLoading, pageIndex, pageTotal])

  const tableContent = React.useMemo(() => {
    if (isLoading) {
      return <LoadingScreen />
    } else {
      return (
        <div>
          <RightPageControl>{pageControl}</RightPageControl>
          <StyledTable>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHeaderCell
                      key={header.id}
                      style={{ width: header.getSize() }}
                    >
                      <>
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      </>
                    </TableHeaderCell>
                  ))}
                </TableRow>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <HoverableTableRow
                    key={row.id}
                    onClick={() => setModalTask(row.original)}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <FocusedTableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </FocusedTableCell>
                      )
                    })}
                  </HoverableTableRow>
                )
              })}
            </tbody>
          </StyledTable>
          {pageControl}
        </div>
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, pageControl, table, tasks])

  return (
    <div style={{ padding: 50 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        {!noTitle && <h1>Verification</h1>}
        {!disableEndDate && (
          <TextField
            id="endDate"
            name="endDate"
            type="date"
            label="End Date"
            value={endDate}
            onChange={(e) => {
              // Check for valid date
              if (moment(e.target.value).isValid()) setEndDate(e.target.value)
            }}
          />
        )}
      </div>
      <Tab
        value={currentTab}
        onChange={handleTabChange}
        tabOptions={[
          {
            label: `Actionable ${
              totals ? `(${totals[VerificationTaskStatus.Actionable]})` : ''
            }`,
            type: VerificationTaskStatus.Actionable
          },
          {
            label: `Rejected ${
              totals ? `(${totals[VerificationTaskStatus.Rejected]})` : ''
            }`,
            type: VerificationTaskStatus.Rejected
          },
          {
            label: `Unactionable ${
              totals ? `(${totals[VerificationTaskStatus.NotActionable]})` : ''
            }`,
            type: VerificationTaskStatus.NotActionable
          },
          {
            label: `Verified ${
              totals ? `(${totals[VerificationTaskStatus.Verified]})` : ''
            }`,
            type: VerificationTaskStatus.Verified
          }
        ]}
      />
      {tableContent}

      <Modal isOpen={!!modalTask} handleClose={handleModalClose}>
        {modalTask && (
          <VerificationModal
            task={modalTask}
            updateTaskState={setModalTask}
            closeModal={handleModalClose}
          />
        )}
      </Modal>
    </div>
  )
}

export default VerificationTable
