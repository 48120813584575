import { IModule } from 'src/models/module'

import ModuleTable from 'src/shared/views/ModuleTable'
import styled from 'styled-components'

const Container = styled.div`
  padding: 50px;
`

interface IModules {
  modules: IModule[]
}

const Modules = ({ modules }: IModules) => {
  /**
   * ----- Render -----
   */
  return (
    <Container>
      <ModuleTable modules={modules} />
    </Container>
  )
}

export default Modules
