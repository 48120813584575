import { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { COLORS, FONTS } from 'src/shared/theme'
import Table from 'src/shared/views/Table/Table'
import {
  IUserProgramProgramMemberships,
  UserPermissionsEnum
} from 'src/models/user'
import { Check, Close } from '@material-ui/icons'
import { useDeleteUserProgramMembership } from 'src/shared/hooks/users'
import { Button } from '@material-ui/core'
import { usePermissions } from 'src/shared/hooks/usePermissions'

const DetailsLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 16px;
  font-weight: 500;
  font-family: ${FONTS.medium};
  letter-spacing: 0.37px;
  line-height: 22px;
  text-align: center;
`

const DeleteButton = styled(Button)`
  color: ${COLORS.warningRed};
  font-size: 16px;
  font-weight: 500;
  font-family: ${FONTS.medium};
  letter-spacing: 0.37px;
  line-height: 22px;
  text-align: center;
  margin-left: 10px;
`

type UserProgramOverviewProps = {
  userId: string
  programs: IUserProgramProgramMemberships[]
}

const UserProgramOverview: FC<UserProgramOverviewProps> = ({
  userId,
  programs
}) => {
  /**
   * ----- Hook Initialization -----
   */

  const { deleteMembership, isLoading } = useDeleteUserProgramMembership(userId)

  const permissions = usePermissions()

  /**
   * ----- Variables -----
   */

  const columns = useMemo(
    () => [
      {
        Header: 'Program Name',
        accessor: 'programName'
      },
      {
        Header: 'Paid Status',
        accessor: 'paidStatus'
      },
      {
        Header: 'Verification Status',
        accessor: 'verification.status'
      },
      {
        Header: 'Completed',
        accessor: 'completed',
        Cell: ({ value }: any) =>
          value ? (
            <Check style={{ color: 'green' }} />
          ) : (
            <Close style={{ color: 'red' }} />
          )
      },
      {
        Header: 'Details',
        accessor: 'details',
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <>
            <DetailsLink
              to={`/users/${userId}/programs/${row.original.programId}/prescription/${row.original.prescriptionId}`}
            >
              VIEW
            </DetailsLink>
            {permissions.includes(UserPermissionsEnum.PLATFORM_ADMIN) && (
              <DeleteButton
                onClick={() => deleteMembership(row.original.programId)}
                variant="text"
                color="primary"
                disabled={isLoading}
              >
                Delete
              </DeleteButton>
            )}
          </>
        )
      }
    ],
    [deleteMembership, isLoading, permissions, userId]
  )

  /**
   * ----- Render -----
   */

  return (
    <Table
      columns={columns}
      data={programs}
      emptyMessage="There are no programs that the user is currently enrolled in."
      getHeaderProps={() => ({
        style: {
          background: COLORS.white,
          textAlign: 'center'
        }
      })}
      getCellProps={() => ({
        style: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: FONTS.main,
          fontSize: '16px',
          textAlign: 'center'
        }
      })}
    />
  )
}

export default UserProgramOverview
