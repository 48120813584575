import React from 'react'
import moment from 'moment'
import { BatchPaymentFileApi } from 'src/api/client'
import { IBatchPaymentFile } from 'src/models/tools'
import { useBatchPaymentFileMarkPaid } from 'src/shared/hooks/tools'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'
import { COLORS } from 'src/shared/theme'
import styled from 'styled-components'

const BatchPaymentTitle = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
`

const BatchPaymentLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const BatchPaymentRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`

const BatchPaymentContainer = styled.div`
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  padding: 1rem;
  background-color: ${COLORS.prussianBlueOpaque};
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const DetailContainer = styled.div`
  border: 1px solid #ccc;
  margin-top: 1rem;
  padding: 1rem;
`

const TransactionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
`

const DetailsButton = styled.div`
  cursor: pointer;
  margin-top: 1rem;
  width: 100%;
  text-align: center;
`

interface IBatchPayment {
  batchPayment: IBatchPaymentFile
}

const BatchPayment = ({ batchPayment }: IBatchPayment) => {
  /**
   * ----- Hook Initialization -----
   */

  const { markFilePaid, isLoading } = useBatchPaymentFileMarkPaid()

  const [showDetails, setShowDetails] = React.useState(false)

  /**
   * ----- Functions -----
   */

  const downloadFile = async (batchFile: IBatchPaymentFile) => {
    try {
      const blob = await BatchPaymentFileApi.getFileBinary(batchFile.fileId)
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${batchFile.fileBasename}.${batchFile.fileExtension}`
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    } catch (error) {
      console.error(`Error downloading file: ${error}`)
    }
  }

  /**
   * ----- Render -----
   */

  return (
    <BatchPaymentContainer>
      <HeaderContainer>
        <BatchPaymentLeftContainer>
          <BatchPaymentTitle>
            <b>
              {moment(batchPayment.generatedAt).format('MMMM Do YYYY, h:mma')}
            </b>{' '}
            Payment File
          </BatchPaymentTitle>
          <span>
            Total Claims: <b>{batchPayment.filePreview.length}</b>
          </span>
        </BatchPaymentLeftContainer>
        <BatchPaymentRightContainer>
          <span>{batchPayment.fileStatus}</span>
          <div>
            {batchPayment.fileStatus === 'PENDING' && (
              <ButtonWithMargin
                isLoading={isLoading}
                onClick={() => markFilePaid(batchPayment.fileId)}
              >
                Mark Paid
              </ButtonWithMargin>
            )}
            <ButtonWithMargin onClick={() => downloadFile(batchPayment)}>
              Download
            </ButtonWithMargin>
          </div>
        </BatchPaymentRightContainer>
      </HeaderContainer>
      <DetailsButton onClick={() => setShowDetails(!showDetails)}>
        {showDetails ? 'Hide Details' : 'Show Details'}
      </DetailsButton>
      {showDetails ? (
        <DetailContainer>
          <BatchPaymentTitle>Details</BatchPaymentTitle>
          <div>
            {batchPayment.filePreview.map((preview, index) => (
              <TransactionContainer key={index}>
                <span>
                  {preview.payeeName} - {preview.payeeEmail}
                </span>
                <span>
                  <b>${preview.amount}</b>
                </span>
              </TransactionContainer>
            ))}
          </div>
        </DetailContainer>
      ) : null}
    </BatchPaymentContainer>
  )
}

export default BatchPayment
