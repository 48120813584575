import { FC } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { UserAdminMatchParams } from 'src/models/routeParams'
import { useAnonymizeUser } from 'src/shared/hooks/users'
import AnonymizeUserView from '../views/AnonymizeUser'

interface IAnonymizeUserContainer {
  closeModal: () => void
}

const AnonymizeUserContainer: FC<IAnonymizeUserContainer> = ({
  closeModal
}) => {
  const { id: userId } = useParams<UserAdminMatchParams>()
  const history = useHistory()

  const anonymizeUser = useAnonymizeUser(userId, {
    onSuccess: () => {
      history.push('/users')
      closeModal()
    }
  })

  return (
    <AnonymizeUserView
      closeModal={closeModal}
      anonymizeUserMutation={anonymizeUser}
    />
  )
}

export default AnonymizeUserContainer
