import React from 'react'

import { Text, Layout, Buttons } from 'src/shared/styled'
import { IProgram } from 'src/models/program'
import { IModule } from 'src/models/module'
import ModuleTable from 'src/shared/views/ModuleTable'
import styled from 'styled-components'
import SelectModuleModal from './SelectModule'
import { useGetModules } from 'src/shared/hooks/modules'
import { ISelectItem } from 'src/shared/views/SearchSelect'

const Container = styled.div`
  padding: 50px;
`

interface IModulesProps {
  modules: IModule[]
  program: IProgram
}

const { DetailsContainer, DetailsHeader, DetailsHeaderContainer } = Layout
const { HeaderName, SubHeaderName } = Text
const { ButtonWithWidth } = Buttons

const Modules: React.FC<IModulesProps> = ({ program, modules }) => {
  const { name: programName } = program

  /**
   * ----- Hook Initialization -----
   */

  const [moduleAddModal, setModuleAddModal] = React.useState(false)
  const [newModules, setNewModules] = React.useState<ISelectItem[]>(
    modules.map((module) => ({
      value: module.moduleId,
      label: module.title.en || 'No English Title'
    }))
  )

  const { modules: allModules, isLoading: modulesLoading } = useGetModules()

  /**
   * ----- Variables -----
   */

  const selectableModules = React.useMemo(() => {
    let selectableModules = modules

    if (allModules) selectableModules = selectableModules.concat(allModules)

    return selectableModules
  }, [allModules, modules])

  /**
   * ----- Render -----
   */

  return (
    <DetailsContainer>
      <DetailsHeaderContainer>
        <DetailsHeader>
          <div>
            <HeaderName>{programName}</HeaderName>
            <SubHeaderName>Modules</SubHeaderName>
          </div>
          <ButtonWithWidth onClick={() => setModuleAddModal(true)}>
            ADD MODULE
          </ButtonWithWidth>
        </DetailsHeader>
      </DetailsHeaderContainer>
      <Container>
        <ModuleTable modules={modules} />
      </Container>
      <SelectModuleModal
        loading={modulesLoading}
        isOpen={moduleAddModal}
        handleClose={() => setModuleAddModal(false)}
        selectableModules={selectableModules}
        selectedModules={newModules}
        programId={program.id}
        onChange={(newModules) => {
          setNewModules(newModules)
        }}
      />
    </DetailsContainer>
  )
}

export default Modules
