import { FC } from 'react'
import styled from 'styled-components'
import { UseMutationResult } from 'react-query'
import { Delete } from '@material-ui/icons'

import { Heading } from 'src/shared/styled/Text'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`
interface IPurgeUserView {
  purgeUserMutation: UseMutationResult<void, unknown, void, unknown>
  closeModal: () => void
}

const PurgeUserView: FC<IPurgeUserView> = ({
  closeModal,
  purgeUserMutation
}) => {
  const { isLoading, isError, mutate } = purgeUserMutation
  const onSubmit = () => {
    mutate()
  }

  return (
    <div>
      <Heading>Purge User</Heading>
      <p>
        Are you sure you want to purge this user? This action cannot be undone.
      </p>
      <p>
        Used when a fraudulent user is detected. This will wipe all of their
        data from our system. However we will retain copies of any e-transfer
        emails and the users first and last names to help prevent future fraud.
      </p>
      {isError && <p>An error occurred purging this user</p>}

      <ButtonsWrapper>
        <ButtonWithMargin
          color="primary"
          variant="outlined"
          onClick={closeModal}
        >
          Cancel
        </ButtonWithMargin>
        <ButtonWithMargin
          startIcon={<Delete />}
          color="primary"
          onClick={onSubmit}
          isLoading={isLoading}
        >
          Purge
        </ButtonWithMargin>
      </ButtonsWrapper>
    </div>
  )
}

export default PurgeUserView
