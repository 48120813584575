import {
  ListAlt,
  PermIdentity,
  Restore,
  VerifiedUserOutlined
} from '@material-ui/icons'

import { IBackButton, INavigationDrawerStep } from '../models/navigationDrawer'
import { ITabNavigationOptions } from '../models/tabNavigation'

export const USER_NAV_STEPS: INavigationDrawerStep[] = [
  {
    Icon: PermIdentity,
    text: 'User Information',
    linkStart: '/users',
    linkEnd: ''
  },
  {
    Icon: ListAlt,
    text: 'Program Memberships',
    linkStart: '/users',
    linkEnd: 'programs'
  },
  {
    Icon: VerifiedUserOutlined,
    text: 'Verification',
    linkStart: '/users',
    linkEnd: 'verification'
  },
  {
    Icon: Restore,
    text: 'Activity Feed',
    linkStart: '/users',
    linkEnd: 'activity'
  }
]

export const USER_BACK_BUTTON: IBackButton = {
  text: 'Back to Users',
  route: '/users'
}

export const USER_PROGRAMS_TAB_OPTIONS: ITabNavigationOptions[] = [
  {
    label: 'PROGRAM OVERVIEW',
    type: 'userPrograms'
  }
]

export const USER_PROGRAM_TAB_OPTIONS: ITabNavigationOptions[] = [
  {
    label: 'ADHERENCE',
    type: 'userProgram'
  },
  {
    label: 'LITERACY',
    type: 'userProgram'
  },
  {
    label: 'REFILLS',
    type: 'userProgram'
  },
  {
    label: 'TRANSACTIONS',
    type: 'userProgram'
  },
  {
    label: 'CERTIFICATE',
    type: 'userCertificate'
  }
]

export const USER_INFO_TAB_OPTIONS: ITabNavigationOptions[] = [
  {
    label: 'ACCOUNT',
    type: 'userInfoAccount'
  },
  {
    label: 'MEDICATION HISTORY',
    type: 'userMedicationHistory',
    urlHash: 'medication-history'
  },
  {
    label: 'TRANSACTIONS',
    type: 'userTransactions',
    urlHash: 'transactions'
  },
  {
    label: 'PAYMENT METHOD',
    type: 'userDirectDeposit',
    urlHash: 'payment-method'
  },
  {
    label: 'CLAIMS',
    type: 'userClaims',
    urlHash: 'claims'
  }
]

export const TIMEZONE_DROPDOWN_ITEMS = [
  {
    value: 'America/St_Johns',
    label: 'Newfoundland (NT)'
  },
  {
    value: 'America/Halifax',
    label: 'Atlantic (AT)'
  },
  {
    value: 'America/Toronto',
    label: 'Eastern (ET)'
  },
  {
    value: 'America/Winnipeg',
    label: 'Central (CT)'
  },
  {
    value: 'America/Edmonton',
    label: 'Mountain (MT)'
  },
  {
    value: 'America/Vancouver',
    label: 'Pacific (PT)'
  }
]

export const GENDER_DROPDOWN_ITEMS = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'NonBinary', label: 'Non Binary' }
]

export const PROVINCES_MAP = [
  { name: 'Alberta', abbr: 'AB', postalMapping: ['T'] },
  { name: 'British Columbia', abbr: 'BC', postalMapping: ['V'] },
  { name: 'Manitoba', abbr: 'MB', postalMapping: ['R'] },
  { name: 'New Brunswick', abbr: 'NB', postalMapping: ['E'] },
  { name: 'Newfoundland', abbr: 'NL', postalMapping: ['A'] },
  { name: 'Nova Scotia', abbr: 'NS', postalMapping: ['B'] },
  { name: 'Northwest Territories', abbr: 'NT', postalMapping: ['X'] },
  { name: 'Nunavut', abbr: 'NU', postalMapping: ['X'] },
  { name: 'Ontario', abbr: 'ON', postalMapping: ['K', 'L', 'M', 'N', 'P'] },
  { name: 'Prince Edward Island', abbr: 'PE', postalMapping: ['C'] },
  { name: 'Quebec', abbr: 'QC', postalMapping: ['G', 'H', 'J'] },
  { name: 'Saskatchewan', abbr: 'SK', postalMapping: ['S'] },
  { name: 'Yukon Territory', abbr: 'YT', postalMapping: ['Y'] }
]

export const PROVINCES_DROPDOWN_VALUES = PROVINCES_MAP.map((p) => ({
  value: p.abbr,
  label: p.name
}))
