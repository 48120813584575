import React from 'react'

import { Text, Learning } from 'src/shared/styled'
import HoverToEdit from './HoverToEdit'
import { useLMS } from 'src/contexts/Learning'
import { ILMSImageSection } from 'src/models/learning'
import AddImageSection from './AddImageSection'
import { downloadImage } from 'src/api/services/amplify'

interface IDisplayImageSection {
  section: ILMSImageSection
  pageId: string
}

const { LightText } = Text
const { LearningImage } = Learning

const DisplayImageSection: React.FC<IDisplayImageSection> = ({
  section,
  pageId
}) => {
  const { removeSection } = useLMS()
  const [imageToDisplay, setImageToDisplay] = React.useState<any>('')
  const [isEditing, setIsEditing] = React.useState(() => {
    if (section.imageUrl) {
      return false
    } else {
      return true
    }
  })

  React.useEffect(() => {
    const getImage = async () => {
      if (section.imageUrl) {
        const remoteImageUrl = await downloadImage(section.imageUrl)
        setImageToDisplay(remoteImageUrl)
      }
    }

    getImage()
  }, [section.imageUrl])
  return (
    <div>
      {isEditing ? (
        <AddImageSection
          cancelEditText={() => setIsEditing(false)}
          section={section}
          pageId={pageId}
        />
      ) : (
        <div className="hover-to-edit-anchor">
          <LearningImage src={imageToDisplay} />
          <LightText>{section.imageDescription}</LightText>
          <HoverToEdit
            onEditClick={() => setIsEditing(true)}
            onDeleteClick={() => removeSection(pageId, section.id)}
          />
        </div>
      )}
    </div>
  )
}

export default DisplayImageSection
