import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { Controller, useFieldArray } from 'react-hook-form'
import IconButton from '@material-ui/core/IconButton'
import Delete from '@material-ui/icons/Delete'

import { useLMSQuestionForm } from '../hooks/forms'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import { Learning, Text } from 'src/shared/styled'
import { ILMSQuestionSection } from 'src/models/learning'
import { formatQuestionFormValues, useLMS } from 'src/contexts/Learning'
import { UserPermissionsEnum } from 'src/models/user'
import { usePermissions } from 'src/shared/hooks/usePermissions'
import styled from 'styled-components'
import { FormControlLabel, Typography } from '@material-ui/core'
import Warning from '@material-ui/icons/Warning'

const StyledFormControlLabel = styled(FormControlLabel)``

const IsSurveyContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
`

interface IAddQuestionSection {
  questionSection: ILMSQuestionSection
  pageId: string
  cancelEdit: () => void
}

const { BoldHeading, HeaderName, ErrorText } = Text
const { QuestionContainer, QuestionTextField, QuestionWarning } = Learning

const AddQuestionsSection: React.FC<IAddQuestionSection> = ({
  questionSection,
  pageId,
  cancelEdit
}) => {
  /**
   * ----- Hook Initialization -----
   */

  const {
    saveSection,
    setSavedFalse,
    isQuestionEmpty,
    removeSection,
    isCreate
  } = useLMS()

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty }
  } = useLMSQuestionForm({
    defaultValues: {
      ...questionSection.question
    }
  })

  const {
    fields: answerOptions,
    append,
    remove
  } = useFieldArray({
    control,
    name: 'answerOptions'
  })

  // True if all answer options are marked as correct
  const [isSurvey, setIsSurvey] = React.useState(
    answerOptions.every((option) => option)
  )

  const [type, setType] = React.useState(questionSection.question.type)

  /**
   * ----- Variables -----
   */

  const { question } = questionSection
  const { questionText, correctAnswerFeedback, incorrectAnswerFeedback } =
    question

  const permissions = usePermissions()
  const canCurrentUserWrite = permissions.includes(
    UserPermissionsEnum.ProgramWrite
  )

  /**
   * ----- Functions -----
   */

  const resetForm = () => {
    if (isQuestionEmpty(questionSection)) {
      removeSection(pageId, questionSection.id)
    } else {
      cancelEdit()
      reset()
    }
  }

  const handleSave = (formValues: any) => {
    saveSection(
      pageId,
      questionSection.id,
      formatQuestionFormValues(
        formValues,
        questionSection.id,
        question.id,
        isSurvey ? type : undefined
      )
    )
    cancelEdit()
  }

  const addAnswerOption = () => {
    append({ text: '', correct: false, weight: 1 })
  }

  const removeAnswerOption = (index: number) => {
    remove(index)
  }

  const toggleType = () => {
    if (type === 'SingleChoice') {
      setType('MultiChoice')
    } else if (type === 'MultipleChoice') {
      setType('SingleChoice')
    }
  }

  /**
   * ----- Effects -----
   */

  React.useEffect(() => {
    if (isDirty && questionSection.saved) {
      setSavedFalse(pageId, questionSection.id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, pageId])

  React.useEffect(() => {
    if (isSurvey) {
      // If the question is a survey, default the answer feedback
      // + default the weight to 1
      // + default all options to be "correct"
      answerOptions.forEach((_, index) => {
        setValue(`answerOptions.${index}.correct`, true)
        setValue(`answerOptions.${index}.weight`, 1)
      })
      setValue('correctFeedback', 'Thanks!')
      setValue('incorrectFeedback', 'Incorrect Placeholder')
    } else {
      // If the question is not a survey, default all options to be "incorrect"
      answerOptions.forEach((_, index) => {
        setValue(`answerOptions.${index}.correct`, false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSurvey])

  /**
   * ----- Render -----
   */

  return (
    <QuestionContainer>
      {!isCreate && (
        <QuestionWarning>
          <div>
            <h3>If editing a Question that has been released</h3>
            <p>
              To avoid survey reporting issues it is important that you refrain
              from the following:
            </p>
            <ul>
              <li>Changing the meaning of a question</li>
              <li>Changing the meaning or order of the answers</li>
            </ul>
          </div>
          <Warning />
        </QuestionWarning>
      )}

      <form onSubmit={handleSubmit(handleSave)}>
        <div className="question-title-container">
          <BoldHeading className="label">Question</BoldHeading>
          <Controller
            defaultValue={questionText}
            name="question"
            control={control}
            render={({ field: { ref, ...props } }) => (
              <QuestionTextField
                label="Question"
                variant="filled"
                inputRef={ref}
                error={!!errors?.question?.message}
                helperText={errors?.question?.message}
                {...props}
              />
            )}
          />
        </div>
        <div className="options-container">
          <div className="headers">
            <HeaderName className="is-correct-header">Correct</HeaderName>
            <HeaderName className="answers-header">Answers</HeaderName>
            <HeaderName className="weight-header">Weight</HeaderName>
          </div>
          {answerOptions.map((option: any, index: number) => (
            <div className="option" key={option.id}>
              <div className="is-correct">
                <Controller
                  defaultValue={option.correct}
                  name={`answerOptions.${index}.correct`}
                  control={control}
                  render={({ field: { onChange, value, ...props } }) => (
                    <Checkbox
                      onChange={(e) => onChange(e.target.checked)}
                      checked={value}
                      disabled={isSurvey}
                      {...props}
                    />
                  )}
                />
              </div>
              <Controller
                defaultValue={option.text}
                name={`answerOptions.${index}.text`}
                control={control}
                render={({
                  field: { ref, ...props },
                  fieldState: { error }
                }) => {
                  let errorMessage
                  if (errors?.answerOptions && error) {
                    errorMessage = error?.message
                  }

                  return (
                    <QuestionTextField
                      className="answer"
                      label="Enter an answer"
                      variant="filled"
                      inputRef={ref}
                      error={!!errorMessage}
                      helperText={errorMessage}
                      {...props}
                    />
                  )
                }}
              />
              <Controller
                defaultValue={option.weight}
                name={`answerOptions[${index}].weight`}
                control={control}
                render={({
                  field: { ref, ...props },
                  fieldState: { error }
                }) => {
                  let errorMessage
                  if (errors?.answerOptions && error) {
                    errorMessage = error?.message
                  }

                  return (
                    <QuestionTextField
                      className="weight"
                      type="number"
                      variant="filled"
                      disabled={isSurvey}
                      inputRef={ref}
                      error={!!errorMessage}
                      helperText={errorMessage}
                      {...props}
                      InputProps={{ inputProps: { min: 1 } }}
                    />
                  )
                }}
              />
              {canCurrentUserWrite && (
                <IconButton
                  onClick={() => removeAnswerOption(index)}
                  size="small"
                >
                  <Delete fontSize="inherit" />
                </IconButton>
              )}
            </div>
          ))}
        </div>
        <ButtonWithWidth color="secondary" onClick={addAnswerOption}>
          ADD OPTION
        </ButtonWithWidth>
        <IsSurveyContainer>
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={isSurvey}
                onChange={(e) => setIsSurvey(e.target.checked)}
              />
            }
            label={<Typography>Survey</Typography>}
          />
          {isSurvey && (
            <ButtonWithWidth color="secondary" onClick={toggleType}>
              {type}
            </ButtonWithWidth>
          )}
        </IsSurveyContainer>

        <div className="response-container">
          <div className="spacer" />
          <div className="content">
            <HeaderName>Correct Answer Feedback</HeaderName>
            <Controller
              defaultValue={correctAnswerFeedback}
              name="correctFeedback"
              control={control}
              render={({ field: { ref, ...props } }) => (
                <QuestionTextField
                  label="Enter feedback response"
                  variant="filled"
                  disabled={isSurvey}
                  inputRef={ref}
                  error={!!errors?.correctFeedback?.message}
                  helperText={errors?.correctFeedback?.message}
                  {...props}
                />
              )}
            />
          </div>
        </div>
        <div className="response-container">
          <div className="spacer" />
          <div className="content">
            <HeaderName>Incorrect Answer Feedback</HeaderName>
            <Controller
              defaultValue={incorrectAnswerFeedback}
              name="incorrectFeedback"
              control={control}
              render={({ field: { ref, ...props } }) => (
                <QuestionTextField
                  label="Enter feedback response"
                  variant="filled"
                  disabled={isSurvey}
                  inputRef={ref}
                  error={!!errors?.incorrectFeedback?.message}
                  helperText={errors?.incorrectFeedback?.message}
                  {...props}
                />
              )}
            />
          </div>
        </div>
        <div className="button-container">
          <div className="buttons">
            <ButtonWithWidth
              type="submit"
              color="secondary"
              disabled={!isDirty}
            >
              SAVE
            </ButtonWithWidth>
            <ButtonWithWidth variant="outlined" onClick={resetForm}>
              CANCEL
            </ButtonWithWidth>
          </div>

          {errors?.answerOptions?.message ? (
            <ErrorText>
              Please mark at least one of the options as correct
            </ErrorText>
          ) : null}
        </div>
      </form>
    </QuestionContainer>
  )
}

export default AddQuestionsSection
