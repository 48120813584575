import { IFetchedLMS, ILMS } from 'src/models/learning'

export const convertFetchedToLMS = (fetchedLMS: IFetchedLMS): ILMS => {
  const newLMS: ILMS = {
    ...fetchedLMS,
    id: fetchedLMS.learningModuleId,
    pages: [],
    isActive: true,
    saved: true
  }

  fetchedLMS.pages.forEach((page) => {
    newLMS.pages.push({
      ...page,
      id: page.pageId
    })
  })

  return newLMS
}
