import React from 'react'
import { Tools } from '../../../shared/styled'
import Indications from '../views/Indications'

const { ToolsViewContainer } = Tools

const IndicationsContainer: React.FC = () => {
  return (
    <ToolsViewContainer>
      <Indications />
    </ToolsViewContainer>
  )
}

export default IndicationsContainer
