import { FC } from 'react'
import styled from 'styled-components'

import { Heading } from 'src/shared/styled/Text'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'
import { UseMutationResult } from 'react-query'

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`

interface IUnblockUserView {
  unblockUserMutation: UseMutationResult<void, unknown, void, unknown>
  closeModal: () => void
}

const UnblockUserView: FC<IUnblockUserView> = ({
  closeModal,
  unblockUserMutation
}) => {
  const { isLoading, isError, mutate } = unblockUserMutation
  const onSubmit = () => {
    mutate()
  }

  return (
    <div>
      <Heading>Unblock User</Heading>
      <p>Are you sure you want to unblock this user?</p>
      {isError && <p>An error occurred unblocking this user</p>}
      <ButtonsWrapper>
        <ButtonWithMargin
          color="primary"
          onClick={onSubmit}
          isLoading={isLoading}
        >
          Unblock
        </ButtonWithMargin>
        <ButtonWithMargin
          color="primary"
          variant="outlined"
          onClick={closeModal}
        >
          Cancel
        </ButtonWithMargin>
      </ButtonsWrapper>
    </div>
  )
}

export default UnblockUserView
