import { FC } from 'react'
import styled from 'styled-components'
import { Check } from '@material-ui/icons'

import { combineDrugIngredientsIntoValue } from 'src/utils/drugLibrary'
import { IDrugLibraryItem } from 'src/models/drugLibrary'
import { COLORS } from 'src/shared/theme'

interface IUserPrescriptionForm {
  isSelected: boolean
  isExisting: boolean
  drug: IDrugLibraryItem
  onClick: () => void
}

const SearchResult = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid ${COLORS.slate[200]};
  cursor: pointer;
  &:hover {
    background-color: ${(props: { isExisting: boolean }) =>
      props.isExisting ? COLORS.slate[300] : COLORS.slate[100]};
  }
  background-color: ${(props: { isExisting: boolean }) =>
    props.isExisting ? COLORS.slate[300] : 'white'};
  cursor: ${(props: { isExisting: boolean }) =>
    props.isExisting ? 'default' : 'pointer'};
  p {
    margin: 0;
    padding: 0;
  }
`

const SearchResultText = styled.div``

const DrugSearchResult: FC<IUserPrescriptionForm> = ({
  isSelected,
  isExisting,
  drug,
  onClick
}) => {
  return (
    <SearchResult onClick={onClick} isExisting={isExisting}>
      <SearchResultText>
        <strong>{drug.brandName}</strong>
        <p>{drug.din.value}</p>
        <p>{drug.pharmaceuticalForm}</p>
        <p>{combineDrugIngredientsIntoValue(drug.activeIngredients)}</p>
      </SearchResultText>
      {isSelected || isExisting ? <Check /> : null}
    </SearchResult>
  )
}

export { DrugSearchResult }
