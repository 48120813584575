import {
  IDrugLibraryActiveIngredient,
  IDrugLibraryItem
} from 'src/models/drugLibrary'
import { capitalize } from 'lodash'
/**
 * These functions are important in formatting precisely this way
 * They are formatted the same way in the mobile app, so deviating from this pattern may cause issues in the app
 */
const formatWord = (word: string) => {
  const delimiter = word.indexOf('-') !== -1 ? '-' : ' '
  return word.split(delimiter).map(capitalize).join(delimiter)
}
export const formatDrugFromLibrary = (
  item: IDrugLibraryItem
): IDrugLibraryItem => {
  const newItem: IDrugLibraryItem = Object.assign({}, item, {
    brandName: capitalize(item.brandName),
    manufacturerName: formatWord(item.manufacturerName),
    pharmaceuticalForm: capitalize(item.pharmaceuticalForm),
    activeIngredients: item.activeIngredients.map((ai) =>
      Object.assign(
        {},
        {
          name: formatWord(ai.name),
          strength: ai.strength,
          strengthUnit: ai.strengthUnit.toLowerCase()
        }
      )
    )
  })
  return newItem
}

export const combineDrugIngredientsIntoValue = (
  activeIngredients: IDrugLibraryActiveIngredient[]
): string => {
  let value = 'loading...'
  if (activeIngredients?.length > 1) {
    value = activeIngredients.reduce(
      (name: string, ingredient: IDrugLibraryActiveIngredient) => {
        let currentIngredient = name.length > 0 ? ' / ' : ''
        currentIngredient += `${ingredient.name} ${ingredient.strength} ${ingredient.strengthUnit}`
        name += currentIngredient
        return name
      },
      ''
    )
  } else if (activeIngredients?.length === 1) {
    value = `${activeIngredients[0].strength} ${activeIngredients[0].strengthUnit}`
  }
  return value
}
