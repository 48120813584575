import { Chip, Typography } from '@material-ui/core'
import React from 'react'
import { useQueryClient } from 'react-query'
import { ProgramMembershipVerificationStatuses } from 'src/models/user'
import {
  IPrescriptionVerificationTask,
  IVerificationTask,
  PrescriptionVerificationStatuses
} from 'src/models/verificationTask'
import { useUpdateProgramVerification } from 'src/shared/hooks/verifications'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'
import { COLORS } from 'src/shared/theme'
import styled from 'styled-components'
import PrescriptionVerification from './PrescriptionVerification'
import VerificationTaskAuditLog from './AuditLog'

const IssuesContainer = styled.div`
  display: flex;
  align-items: center;
`

const Issue = styled.div`
  margin: 10px;
`

const VerificationFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`

const HistoryButton = styled.button`
  width: 100%;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  margin: 20px 0 0 0;
  padding: 10px;
  cursor: pointer;
  :hover {
    background-color: #f0f0f0;
  }
`

interface IVerificationModal {
  task: IVerificationTask
  updateTaskState: React.Dispatch<React.SetStateAction<IVerificationTask>>
  closeModal: () => void
}

const VerificationModal = ({
  task,
  updateTaskState,
  closeModal
}: IVerificationModal) => {
  /**
   * ----- Hook Initialization -----
   */

  const queryClient = useQueryClient()

  const [newStatus, setNewStatus] = React.useState(
    task.programVerificationStatus
  )
  const [sharedEmailView, setSharedEmailView] = React.useState(false)
  const [showHistory, setShowHistory] = React.useState(false)

  const { updateProgramVerification, isLoading } = useUpdateProgramVerification(
    task.userId,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('/verification-tasks')

        updateTaskState({
          ...task,
          programVerificationStatus: newStatus
        })

        closeModal()
      }
    }
  )

  /**
   * ----- Variables -----
   */

  // Disable the Verify button if some prescriptions are not verified
  const verifyDisabled = React.useMemo(() => {
    return (
      task.programVerificationStatus ===
        ProgramMembershipVerificationStatuses.Verified ||
      (task.prescriptions.length > 0 &&
        !task.prescriptions.some(
          (prescription) =>
            prescription.verification.status ===
            PrescriptionVerificationStatuses.Verified
        ))
    )
  }, [task.prescriptions, task.programVerificationStatus])

  // Disable the on hold button if there are no rejected prescriptions
  const holdDisabled = React.useMemo(() => {
    return (
      task.prescriptions.every(
        (prescription) =>
          prescription.verification.status !==
          PrescriptionVerificationStatuses.Rejected
      ) ||
      task.programVerificationStatus ===
        ProgramMembershipVerificationStatuses.OnHold
    )
  }, [task.prescriptions, task.programVerificationStatus])

  const isRejected = React.useMemo(() => {
    return (
      task.programVerificationStatus ===
      ProgramMembershipVerificationStatuses.Rejected
    )
  }, [task.programVerificationStatus])

  /**
   * ----- Functions -----
   */

  const handleProgramStatusUpdate = (
    status: ProgramMembershipVerificationStatuses
  ) => {
    setNewStatus(status)
    updateProgramVerification({
      status,
      programId: task.programId
    })
  }

  const updatePrescriptionState = (
    prescription: IPrescriptionVerificationTask
  ) => {
    const prescriptionsCopy: IPrescriptionVerificationTask[] = JSON.parse(
      JSON.stringify(task.prescriptions)
    )

    const index = prescriptionsCopy.findIndex(
      (p: IPrescriptionVerificationTask) =>
        p.prescriptionId === prescription.prescriptionId
    )

    prescriptionsCopy[index] = prescription

    const updatedTask = {
      ...task,
      prescriptions: prescriptionsCopy
    }

    updateTaskState(updatedTask)
  }

  /**
   * ----- Render -----
   */

  const issues = React.useMemo(() => {
    const issues = []
    if (task.usersUsingSameEmail && task.usersUsingSameEmail.length > 0) {
      issues.push(
        <Issue>
          <Chip
            label={`${task.usersUsingSameEmail.length} matching email(s) found`}
            color="secondary"
            style={{ backgroundColor: COLORS.warningRed }}
            size="small"
            onClick={() => setSharedEmailView(!sharedEmailView)}
          />
          {sharedEmailView && (
            <ul>
              {task.usersUsingSameEmail.map((shared) => (
                <li>
                  <a
                    href={`/users/${shared.userId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {shared.lastName}, {shared.firstName}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </Issue>
      )
    }

    if (task.under18) {
      issues.push(
        <Issue>
          <Chip
            label="User is under 18"
            color="secondary"
            style={{ backgroundColor: COLORS.warningRed }}
            size="small"
          />
        </Issue>
      )
    }

    if (issues.length > 0) {
      return <IssuesContainer>{issues}</IssuesContainer>
    } else {
      return null
    }
  }, [sharedEmailView, task.under18, task.usersUsingSameEmail])

  return (
    <div>
      <h1>
        <a href={`/users/${task.userId}`} target="_blank" rel="noreferrer">
          {task.userName}
        </a>
      </h1>
      {issues}
      <h2>
        <a
          href={`/programs/${task.programId}/details`}
          target="_blank"
          rel="noreferrer"
        >
          {task.programName}
        </a>
      </h2>
      {task.prescriptions.map((prescription) => (
        <PrescriptionVerification
          isRejected={isRejected}
          key={prescription.prescriptionId}
          prescription={prescription}
          userId={task.userId}
          updatePrescriptionState={updatePrescriptionState}
        />
      ))}
      {!isRejected && (
        <VerificationFooter>
          <ButtonWithMargin
            style={{ backgroundColor: COLORS.warningRed }}
            color="secondary"
            isLoading={isLoading}
            disabled={
              task.programVerificationStatus ===
              ProgramMembershipVerificationStatuses.Rejected
            }
            onClick={() =>
              handleProgramStatusUpdate(
                ProgramMembershipVerificationStatuses.Rejected
              )
            }
          >
            Reject
          </ButtonWithMargin>
          <div>
            <ButtonWithMargin
              disabled={holdDisabled}
              isLoading={isLoading}
              onClick={() =>
                handleProgramStatusUpdate(
                  ProgramMembershipVerificationStatuses.OnHold
                )
              }
              tooltip="Place on hold, awaiting user input"
            >
              On Hold
            </ButtonWithMargin>
            <ButtonWithMargin
              disabled={verifyDisabled}
              isLoading={isLoading}
              onClick={() =>
                handleProgramStatusUpdate(
                  ProgramMembershipVerificationStatuses.Verified
                )
              }
              tooltip={
                verifyDisabled
                  ? 'Please verify at least one prescription before verifying the user'
                  : ''
              }
            >
              Verify and Pay User
            </ButtonWithMargin>
          </div>
        </VerificationFooter>
      )}

      <div>
        <HistoryButton onClick={() => setShowHistory(!showHistory)}>
          <Typography>{showHistory ? 'Hide' : 'View'} History</Typography>
        </HistoryButton>
        {showHistory && <VerificationTaskAuditLog task={task} />}
      </div>
    </div>
  )
}

export default VerificationModal
