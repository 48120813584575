import React from 'react'
import WaitListTable from 'src/components/WaitListTable'
import { IProgram } from 'src/models/program'
import { useProgramWaitList } from 'src/shared/hooks/program'
import {
  DetailsContainer,
  DetailsHeader,
  DetailsHeaderContainer
} from 'src/shared/styled/Layout'
import { HeaderName, SubHeaderName } from 'src/shared/styled/Text'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import styled from 'styled-components'

const TableContainer = styled.div`
  margin: 2%;
`

interface IWaitList {
  program: IProgram
}

const WaitList = ({ program }: IWaitList) => {
  /**
   * ----- Hook Initialization -----
   */

  const { waitList } = useProgramWaitList(program.id)

  /**
   * ----- Render -----
   */

  const content = React.useMemo(() => {
    if (!waitList) {
      return <LoadingScreen />
    }

    return <WaitListTable waitList={waitList} program={program} />
  }, [waitList, program])

  return (
    <DetailsContainer>
      <DetailsHeaderContainer>
        <DetailsHeader>
          <div>
            <HeaderName>{program.name}</HeaderName>
            <SubHeaderName>Wait List</SubHeaderName>
          </div>
        </DetailsHeader>
      </DetailsHeaderContainer>
      <TableContainer>{content}</TableContainer>
    </DetailsContainer>
  )
}

export default WaitList
