import { FC } from 'react'
import styled from 'styled-components'
import Button, { ButtonProps as MUIButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import { FONTS } from '../theme'
import { Tooltip } from '@material-ui/core'

export interface ButtonProps extends MUIButtonProps {
  isLoading?: boolean
  backgroundcolor?: string
  tooltip?: string
}

const StyledButton = styled(Button)<ButtonProps>`
  font-family: ${FONTS.medium};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.34px;
  line-height: 24px;
  padding: 8px 22px;
  border-radius: 4px;
  background-color: ${(props) => props.backgroundcolor};
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
`

const ThemedButton: FC<ButtonProps> = ({
  variant = 'contained',
  classes,
  children,
  isLoading,
  color = 'primary',
  tooltip = '',
  disabled,
  style,
  ...props
}) => {
  return (
    <Tooltip title={tooltip}>
      <span>
        <StyledButton
          variant={variant}
          disabled={disabled || isLoading}
          color={color}
          classes={classes}
          style={{ ...style }}
          {...props}
        >
          <span style={{ opacity: isLoading ? 0 : 1 }}>{children}</span>
          {isLoading && (
            <CircularProgress
              size={24}
              style={{
                position: 'absolute'
              }}
            />
          )}
        </StyledButton>
      </span>
    </Tooltip>
  )
}

export default ThemedButton
