import { ChangeEvent, useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'
import styled from 'styled-components'

import { COLORS } from 'src/shared/theme'
import { EditableCellProps } from 'src/models/program'

const StyledTextField = styled(TextField)`
  & .MuiInput-root {
    height: 60px;
  }
  && :hover {
    background: ${COLORS.turquoisePale};
  }
`

// Create an editable cell renderer
export const ActionsCell = ({
  value: initialValue,
  row: Row,
  column: { id },
  isEditing,
  updateMyData // This is a custom function that we supplied to our table instance
}: EditableCellProps) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue)
  const { index } = Row

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  let retObj = null
  if (isEditing) {
    switch (id) {
      default:
        retObj = (
          <StyledTextField
            style={{ width: '100%', border: 'none' }}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        )
        break
    }
  } else {
    retObj = value
  }
  return retObj
}

// export const defaultColumn = {
//   Cell: EditableCell
// }
