import React, { FC, PropsWithChildren, useMemo } from 'react'
import { CellProps, Column } from 'react-table'
import { ILiteracyTransaction } from 'src/models/transaction'
import { useAttachedLearningModules } from 'src/shared/hooks/learningModules'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'

import { COLORS } from 'src/shared/theme'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import Modal from 'src/shared/views/Modal'
import Table from 'src/shared/views/Table/Table'
import { useUserFromQueryString } from '../hooks/useUserFromQueryString'
import LiteracyResults from './LiteracyResults'

type LiteracyProps = {
  data: ILiteracyTransaction[]
  programId: string
}

interface TableData extends ILiteracyTransaction {
  releaseWeek?: number
  learningModule?: string
}

const Literacy: FC<LiteracyProps> = ({ data, programId }) => {
  const [literacyModal, setLiteracyModal] = React.useState<string>()

  const user = useUserFromQueryString()

  const { attachedModules, isLoading: learningModulesLoading } =
    useAttachedLearningModules(programId)

  const dataCopy = React.useMemo(() => {
    const copy: TableData[] = [...data]

    for (let i = 0; i < data.length; i++) {
      const learningModule = attachedModules?.find(
        (learningModule) =>
          learningModule?.learningModuleId === data[i].learningModuleId
      )

      copy[i].learningModule = learningModule?.title
    }

    return copy
  }, [attachedModules, data])

  const renderValueCell = ({ row }: CellProps<ILiteracyTransaction>) => (
    <p>
      $${row.original?.value?.amount.toFixed(2)} $
      {row.original.value?.currencyCode}
    </p>
  )

  const columns = useMemo<Column<TableData>[]>(
    () => [
      {
        Header: 'Release Week',
        accessor: 'releaseWeek'
      },
      {
        Header: 'Learning Module',
        accessor: 'learningModule',
        disableSortBy: true
      },
      {
        Header: ' Status',
        accessor: 'status'
      },
      {
        Header: 'Policy Applied',
        accessor: 'policyApplied',
        disableSortBy: true
      },
      {
        Header: 'Discount',
        accessor: 'discount',
        disableSortBy: true,
        width: 80,
        Cell: ({ row }: CellProps<ILiteracyTransaction, any>) => (
          <p>{row.original.discount}%</p>
        )
      },
      {
        Header: 'Points',
        accessor: 'points',
        disableSortBy: true,
        width: 80
      },
      {
        Header: 'Conversion Rate',
        accessor: 'conversionRate',
        disableSortBy: true,
        Cell: ({
          row
        }: PropsWithChildren<CellProps<ILiteracyTransaction, any>>) => (
          <p>${row.original.conversionRate?.toFixed(2)}/pt</p>
        )
      },
      {
        Header: 'Value',
        accessor: 'value',
        width: 80,
        disableSortBy: true,
        Cell: renderValueCell
      },
      {
        Header: 'Results',
        disableSortBy: true,
        Cell: ({
          row
        }: PropsWithChildren<CellProps<ILiteracyTransaction, any>>) => (
          <ButtonWithMargin
            onClick={() => setLiteracyModal(row.original.learningModuleId)}
          >
            View
          </ButtonWithMargin>
        )
      }
    ],
    []
  )

  return learningModulesLoading ? (
    <LoadingScreen />
  ) : (
    <>
      <Table
        columns={columns}
        data={dataCopy}
        placeholder="Filter"
        emptyMessage="There are no learning modules for this program."
        getHeaderProps={() => ({
          style: {
            background: COLORS.white
          }
        })}
      />
      <Modal
        isOpen={!!literacyModal}
        handleClose={() => setLiteracyModal(undefined)}
      >
        {literacyModal && user ? (
          <LiteracyResults
            programId={programId}
            learningModuleId={literacyModal}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            userId={user.userId!}
          />
        ) : (
          <p>Error</p>
        )}
      </Modal>
    </>
  )
}

export default Literacy
