import { IProgram } from 'src/models/program'
import { useEntryCodeForm } from 'src/shared/forms/EntryCode'
import { useCreateEntryCode } from 'src/shared/hooks/program'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import Modal from 'src/shared/views/Modal'
import styled from 'styled-components'

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
`

interface ICreateEntryCodeModal {
  program: IProgram
  isOpen: boolean
  handleClose: () => void
}

const CreateEntryCodeModal = ({
  program,
  isOpen,
  handleClose
}: ICreateEntryCodeModal) => {
  /**
   * ----- Hook Initialization -----
   */

  const { FormComponents } = useEntryCodeForm()

  const { createEntryCode } = useCreateEntryCode(program.id)

  /**
   * ----- Render -----
   */

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Create Entry Code">
      <FormComponents.Form submitHandler={(data) => createEntryCode(data)}>
        <div>
          <FormComponents.Name />
          <FormComponents.SingleUse />
        </div>
        <ButtonContainer>
          <div></div>
          <div>
            <ButtonWithWidth variant="outlined" onClick={handleClose}>
              Cancel
            </ButtonWithWidth>
            <ButtonWithWidth type="submit">Submit</ButtonWithWidth>
          </div>
        </ButtonContainer>
      </FormComponents.Form>
    </Modal>
  )
}

export default CreateEntryCodeModal
