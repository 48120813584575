import { useMutation, useQuery, useQueryClient } from 'react-query'
import { BatchPaymentFileApi, CommercialCodesApi } from 'src/api/client'
import {
  FETCH_ALL_CLAIMS,
  FETCH_BATCH_PAYMENT_FILES,
  FETCH_COMMERCIAL_CODES
} from 'src/constants/queries'
import {
  IBatchPaymentFile,
  ICommercialCode,
  ICommercialCodeData
} from 'src/models/tools'
import { logger } from 'src/utils/logger'

/**
 * ----- Queries -----
 */

export const useGetCommercialCodes = () => {
  const query = useQuery<ICommercialCode[]>(FETCH_COMMERCIAL_CODES, () =>
    CommercialCodesApi.getCommercialCodes()
  )

  return { ...query, commercialCodes: query.data }
}

export const useGetBatchPaymentFileList = () => {
  const query = useQuery<IBatchPaymentFile[]>(FETCH_BATCH_PAYMENT_FILES, () =>
    BatchPaymentFileApi.getBatchPaymentFiles()
  )

  return { ...query, batchPaymentFiles: query.data }
}

/**
 * ----- Mutations -----
 */

export const useCreateCommercialCode = ({
  onSuccess
}: {
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (code: ICommercialCodeData) =>
      CommercialCodesApi.createCommercialCode(code),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_COMMERCIAL_CODES)

        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('Error creating commercial code', err)
      }
    }
  )

  return { ...mutation, createCommercialCode: mutation.mutate }
}

export const useUpdateCommercialCode = ({
  onSuccess
}: {
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    ({ code, data }: { code: string; data: ICommercialCodeData }) =>
      CommercialCodesApi.updateCommercialCode(code, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_COMMERCIAL_CODES)

        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('Error updating commercial code', err)
      }
    }
  )

  return { ...mutation, updateCommercialCode: mutation.mutate }
}

export const useDeleteCommercialCode = ({
  onSuccess
}: {
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (code: string) => CommercialCodesApi.deleteCommercialCode(code),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_COMMERCIAL_CODES)

        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('Error deleting commercial code', err)
      }
    }
  )

  return { ...mutation, deleteCommercialCode: mutation.mutate }
}

export const useBatchPaymentFileMarkPaid = () => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (fileId: string) => BatchPaymentFileApi.markPaid(fileId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_BATCH_PAYMENT_FILES)
      },
      onError: (err) => {
        logger.error('Error marking file as paid', err)
      }
    }
  )

  return { ...mutation, markFilePaid: mutation.mutate }
}

export const useBatchPaymentGeneration = ({
  onSuccess
}: {
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (after: string) => BatchPaymentFileApi.generate(after),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_ALL_CLAIMS)
        queryClient.invalidateQueries(FETCH_BATCH_PAYMENT_FILES)
        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('Error generating batch payments', err)
      }
    }
  )

  return { ...mutation, generateBatchPayment: mutation.mutate }
}

export const useBatchPaymentFailedTransactions = ({
  onSuccess
}: {
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (file: File) => BatchPaymentFileApi.uploadFailedTransactions(file),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_ALL_CLAIMS)
        queryClient.invalidateQueries(FETCH_BATCH_PAYMENT_FILES)

        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('Error fetching failed transactions', err)
      }
    }
  )

  return { ...mutation, uploadFailedTransactions: mutation.mutate }
}
