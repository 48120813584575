import LearningModules from '../views/LearningModules'

const LearningModulesContainer = () => {
  return (
    <div style={{ height: '100%' }}>
      <LearningModules />
    </div>
  )
}

export default LearningModulesContainer
