import { TableRow } from '@material-ui/core'
import {
  CellContext,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { DateTime } from 'luxon'
import React from 'react'
import { Controller } from 'react-hook-form'
import DebouncedInput from 'src/components/DebounceInput'
import { LONG_DATE_TIME_FORMAT } from 'src/constants/datetimes'
import { ICommercialCode, ICommercialCodeData } from 'src/models/tools'
import { useCommercialCodeForm } from 'src/shared/forms/CommercialCodes'
import {
  useCreateCommercialCode,
  useUpdateCommercialCode
} from 'src/shared/hooks/tools'
import { ButtonWithMargin, ButtonWithWidth } from 'src/shared/styled/Buttons'
import {
  FocusedTableCell,
  HoverableTableRow,
  StyledTable,
  TableHeaderCell
} from 'src/shared/styled/Table'
import Modal from 'src/shared/views/Modal'
import TextField from 'src/shared/views/TextField'
import { fuzzyFilter } from 'src/utils/table'
import styled from 'styled-components'

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

interface ICommercialCodesView {
  commercialCodes: ICommercialCode[]
}

const CommercialCodesView = ({ commercialCodes }: ICommercialCodesView) => {
  /**
   * ----- Hook Initialization -----
   */

  const [createModal, setCreateModal] = React.useState(false)
  const [updateModal, setUpdateModal] = React.useState<
    ICommercialCode | undefined
  >()

  const { control, handleSubmit } = useCommercialCodeForm()

  const { isLoading: createLoading, createCommercialCode } =
    useCreateCommercialCode({
      onSuccess: () => setCreateModal(false)
    })

  const { isLoading: updateLoading, updateCommercialCode } =
    useUpdateCommercialCode({
      onSuccess: () => setUpdateModal(undefined)
    })

  /**
   * ----- Variables -----
   */

  const columns: ColumnDef<ICommercialCode, any>[] = React.useMemo(
    () => [
      {
        header: 'Code',
        accessorKey: 'code'
      },
      {
        header: 'Description',
        accessorKey: 'description'
      },
      {
        header: 'Payout',
        accessorKey: 'userAmountPerUse',
        cell: ({
          row
        }: React.PropsWithChildren<CellContext<ICommercialCode, any>>) => {
          return <span>${row.original.userAmountPerUse.toFixed(2)}</span>
        }
      },
      {
        header: 'Created At',
        accessorKey: 'createdAt',
        cell: ({ row }: any) => {
          const dateISO = DateTime.fromJSDate(
            new Date(row.original.createdAt)
          ).toISO()
          const formattedDate = DateTime.fromISO(dateISO).toFormat(
            LONG_DATE_TIME_FORMAT
          )
          return `${formattedDate}`
        }
      }
    ],
    []
  )

  /**
   * ----- Functions -----
   */

  const handleCodeCreation = (data: ICommercialCodeData) => {
    createCommercialCode(data)
  }

  const handleCodeUpdate = (data: ICommercialCodeData) => {
    updateCommercialCode({ code: updateModal?.code || 'invalid', data })
  }

  /**
   * ----- Render -----
   */

  const table = useReactTable({
    columns,
    data: commercialCodes,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  })

  return (
    <div>
      <TitleContainer>
        <h1>Commercial Codes</h1>
        <ButtonWithMargin onClick={() => setCreateModal(true)}>
          Create
        </ButtonWithMargin>
      </TitleContainer>
      <StyledTable>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeaderCell
                  key={header.id}
                  style={{ width: header.getSize() }}
                >
                  <>
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                    {header.column.getCanFilter() ? (
                      <DebouncedInput
                        type="text"
                        value={(header.column.getFilterValue() ?? '') as string}
                        onChange={(value) =>
                          header.column.setFilterValue(value)
                        }
                        placeholder={`Search...`}
                        list={header.column.id + 'list'}
                      />
                    ) : null}
                  </>
                </TableHeaderCell>
              ))}
            </TableRow>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <HoverableTableRow
                key={row.id}
                onClick={() => setUpdateModal(row.original)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <FocusedTableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </FocusedTableCell>
                  )
                })}
              </HoverableTableRow>
            )
          })}
        </tbody>
      </StyledTable>
      {/* Creation Modal */}
      <Modal
        isOpen={createModal}
        handleClose={() => setCreateModal(false)}
        title="Create Commercial Code"
      >
        <FormContainer onSubmit={handleSubmit(handleCodeCreation)}>
          <Controller
            name="description"
            control={control}
            render={({ field: { ref, ...props }, fieldState: { error } }) => (
              <TextField
                label="Description"
                multiline
                minRows={2}
                placeholder="Name, email, etc."
                inputRef={ref}
                error={!!error?.message}
                helperText={error?.message}
                {...props}
              />
            )}
          />
          <Controller
            name="userAmountPerUse"
            control={control}
            render={({ field: { ref, ...props }, fieldState: { error } }) => (
              <TextField
                label="Payout ($)"
                placeholder="5.00"
                inputRef={ref}
                type="number"
                error={!!error?.message}
                helperText={error?.message}
                {...props}
              />
            )}
          />
          <ButtonWithWidth isLoading={createLoading} type="submit">
            Submit
          </ButtonWithWidth>
        </FormContainer>
      </Modal>
      {/* Update Modal */}
      <Modal
        isOpen={!!updateModal}
        handleClose={() => setUpdateModal(undefined)}
        title="Update Commercial Code"
      >
        <FormContainer onSubmit={handleSubmit(handleCodeUpdate)}>
          <Controller
            name="description"
            control={control}
            defaultValue={updateModal?.description}
            render={({ field: { ref, ...props }, fieldState: { error } }) => (
              <TextField
                label="Description"
                multiline
                minRows={2}
                placeholder="Name, email, etc."
                inputRef={ref}
                error={!!error?.message}
                helperText={error?.message}
                {...props}
              />
            )}
          />
          <Controller
            name="userAmountPerUse"
            control={control}
            defaultValue={updateModal?.userAmountPerUse}
            render={({ field: { ref, ...props }, fieldState: { error } }) => (
              <TextField
                label="Payout ($)"
                placeholder="5.00"
                inputRef={ref}
                type="number"
                error={!!error?.message}
                helperText={error?.message}
                {...props}
              />
            )}
          />
          <ButtonWithWidth type="submit" isLoading={updateLoading}>
            Submit
          </ButtonWithWidth>
        </FormContainer>
      </Modal>
    </div>
  )
}

export default CommercialCodesView
