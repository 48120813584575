import React from 'react'
import { MenuItem, TextField, TextFieldProps } from '@material-ui/core'
import styled from 'styled-components'
import { FONTS } from '../theme'

export interface IDropDownItem {
  label: string
  value: string
}

type IDropDownItemProps = {
  items: IDropDownItem[]
  select?: boolean
  SelectProps?: any
}

export type IDropDownProps = IDropDownItemProps & TextFieldProps

const StyledTextField = styled(TextField)`
  font-family: ${FONTS.main};
  & .MuiFormLabel-root.Mui-focused,
  .MuiOutlinedInput-root.Mui-focused {
    color: ${(props) => props.theme.palette.secondary.main};
  }
  && .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: ${(props) => `1px solid ${props.theme.palette.secondary.main}`};
  }
  && .MuiOutlinedInput-root {
    &:hover .MuiOutlinedInput-notchedOutline {
      border: ${(props) => `1px solid ${props.theme.palette.secondary.main}`};
      border-color: ${(props) => props.theme.palette.secondary.main};
    }
  }
`

const DropDown = React.forwardRef<HTMLInputElement, IDropDownProps>(
  (
    { variant = 'outlined', label, items, color, disabled, style, ...props },
    ref
  ) => {
    return (
      <StyledTextField
        select
        variant={variant}
        label={label}
        style={style}
        disabled={disabled}
        color={color}
        ref={ref}
        {...props}
      >
        {items.map((d, i) => (
          <MenuItem key={`${i}-${d.value}`} id={d.value} value={d.value}>
            {d.label}
          </MenuItem>
        ))}
      </StyledTextField>
    )
  }
)

export { DropDown }
