import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 50px 64px;
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const DetailsHeader = styled.div`
  background-color: #f5f5f5;
  display: flex;
  padding: 57px 34px 25px 36px;
  justify-content: space-between;
`

export const DetailsContent = styled.div`
  padding: 27px 34px 25px 36px;
`

export const SummaryContainer = styled.div`
  margin-bottom: 50px;
`

export const DetailsHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
`
