import React from 'react'
import { ILMSOption } from 'src/models/learning'
import { useLearningModuleResults } from 'src/shared/hooks/users'
import moment from 'moment'
import { BoldHeading, ErrorHeading, LightText } from 'src/shared/styled/Text'
import LoadingScreen from 'src/shared/views/LoadingScreen'

interface ILiteracyResults {
  programId: string
  learningModuleId: string
  userId: string
}

const LiteracyResults = ({
  programId,
  learningModuleId,
  userId
}: ILiteracyResults) => {
  const { learningModuleResults, isLoading } = useLearningModuleResults(
    userId,
    programId,
    learningModuleId
  )

  /**
   * ----- Rendering -----
   */

  const items = React.useMemo(() => {
    if (
      learningModuleResults &&
      learningModuleResults.length > 0 &&
      !isLoading
    ) {
      return learningModuleResults?.map((question) => {
        const answers: ILMSOption[] = []

        question.chosenAnswer.indexesSelected.forEach((index) => {
          // Ensure index is a valid integer
          const answer = question.question.answerOptions.find(
            (option) => option.indexLabel === index
          )
          if (answer) answers.push(answer)
        })

        const answersJSX = question.question.answerOptions.map((option) => {
          const selected = question.chosenAnswer.indexesSelected.includes(
            option.indexLabel || ''
          )
          const correct = option.correct

          let color = 'black'
          if (correct && selected) color = 'green'
          else if (correct && !selected) color = 'red'

          return (
            <div style={{ marginLeft: '1em' }}>
              <p style={{ fontWeight: selected ? 'bold' : 'normal', color }}>
                {option.indexLabel} {option.text}
              </p>
            </div>
          )
        })

        return (
          <div key={question.questionId}>
            <BoldHeading>
              <i>Question:</i> {question.question.questionText}
            </BoldHeading>
            <LightText>
              {moment(question.recordedAt).format('YYYY, MMMM DD, h:mm a')}
            </LightText>
            {answersJSX}
          </div>
        )
      })
    } else if (isLoading) {
      return <LoadingScreen />
    } else {
      return <ErrorHeading>No results found</ErrorHeading>
    }
  }, [learningModuleResults, isLoading])

  return <div>{items}</div>
}

export default LiteracyResults
