import { useParams } from 'react-router'
import { UserAdminMatchParams } from 'src/models/routeParams'
import { useUserDirectDeposits } from 'src/shared/hooks/users'
import DirectDepositView from '../views/DirectDeposit'
import LoadingScreen from 'src/shared/views/LoadingScreen'

const DirectDeposit = () => {
  const { id: userId } = useParams<UserAdminMatchParams>()

  const { directDeposits, isLoading, error } = useUserDirectDeposits(userId)

  if (error) return <p>Unable to fetch direct deposits</p>
  if (!isLoading && directDeposits)
    return <DirectDepositView directDeposits={directDeposits} />

  return <LoadingScreen />
}

export default DirectDeposit
