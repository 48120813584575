import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import ThemedTextField from 'src/shared/views/TextField'

const UserProgramCertificateSchema = yup.object().shape({
  certificateNumber: yup.string().required(),
  policyNumber: yup.string().required(),
  planNumber: yup.string().required()
})

export interface IPutUserProgramCertificateData {
  certificateNumber: string
  policyNumber: string
  planNumber: string
}

const useProgramCertificateForm = (options?: any) => {
  const form = useForm({
    resolver: yupResolver(UserProgramCertificateSchema),
    ...options
  })

  const { control, handleSubmit } = form

  const FormComponents = {
    Form: ({
      children,
      submitHandler
    }: {
      children: React.ReactNode
      submitHandler: SubmitHandler<IPutUserProgramCertificateData>
    }) => <form onSubmit={handleSubmit(submitHandler)}>{children}</form>,
    CertificateNumber: () =>
      React.useMemo(
        () => (
          <Controller
            control={control}
            name="certificateNumber"
            defaultValue={options?.defaultValues?.certificateNumber}
            render={(field) => (
              <ThemedTextField label="Certification Number" {...field} />
            )}
          />
        ),
        []
      ),
    PolicyNumber: () =>
      React.useMemo(
        () => (
          <Controller
            control={control}
            name="policyNumber"
            defaultValue={options?.defaultValues?.policyNumber}
            render={(field) => (
              <ThemedTextField label="Policy Number" {...field} />
            )}
          />
        ),
        []
      ),
    PlanNumber: () =>
      React.useMemo(
        () => (
          <Controller
            control={control}
            name="planNumber"
            defaultValue={options?.defaultValues?.planNumber}
            render={(field) => (
              <ThemedTextField label="Plan Number" {...field} />
            )}
          />
        ),
        []
      )
  }

  return { ...form, FormComponents }
}

export default useProgramCertificateForm
