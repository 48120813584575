import React from 'react'
import styled from 'styled-components'
import TextField, {
  TextFieldProps as MUITextFieldProps
} from '@material-ui/core/TextField'
import { FONTS } from '../theme'

export type TextFieldProps = MUITextFieldProps & {
  noNegative?: boolean
}

const StyledTextField = styled(TextField)`
  font-family: ${FONTS.main};
  margin-right: 40px;
  & .MuiFormLabel-root.Mui-focused,
  .MuiOutlinedInput-root.Mui-focused {
    color: ${(props) => props.theme.palette.secondary.main};
  }
  && .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: ${(props) => `1px solid ${props.theme.palette.secondary.main}`};
  }
  && .MuiOutlinedInput-root {
    &:hover .MuiOutlinedInput-notchedOutline {
      border: ${(props) => `1px solid ${props.theme.palette.secondary.main}`};
      border-color: ${(props) => props.theme.palette.secondary.main};
    }
  }
`

const ThemedTextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      id,
      name,
      label,
      type = 'text',
      variant = 'outlined',
      value,
      onChange,
      disabled,
      classes,
      children,
      noNegative,
      ...props
    },
    ref
  ) => {
    return (
      <StyledTextField
        id={id}
        name={name}
        label={label}
        type={type}
        variant={variant}
        value={value}
        disabled={disabled}
        classes={classes}
        ref={ref}
        onChange={(e) => {
          if (type === 'number' && noNegative && parseInt(e.target.value) < 0) {
            e.preventDefault()
            return
          }
          if (onChange) onChange(e)
        }}
        {...props}
      >
        {children}
      </StyledTextField>
    )
  }
)

export default ThemedTextField
