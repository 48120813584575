import { PROVINCES_MAP } from 'src/constants/user'
import * as yup from 'yup'

export const POSTAL_REGEX =
  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i

export const NAME_REGEX = /^[a-zA-Z- ]+$/

export const checkPostalCodeAgainstProvince = (ref: any, msg: string) => {
  return yup.string().test({
    name: 'checkPostalCodeAgainstProvince',
    exclusive: false,
    message: msg || 'Postal code needs to match province',
    params: {
      reference: ref.path
    },
    test(value) {
      const desiredPostalMap = PROVINCES_MAP.find(
        (pm) => pm.abbr === this.resolve(ref)
      )
      const firstCharacter = value?.charAt(0).toUpperCase()
      const isMappingValid =
        desiredPostalMap && firstCharacter
          ? desiredPostalMap.postalMapping.includes(firstCharacter)
          : true
      return isMappingValid
    }
  })
}

export const hasTimeDuplication = (
  times: string[],
  dosesPerDay: number
): boolean => {
  const slicedTimes = times.slice(0, dosesPerDay).filter((t) => t !== '')

  return new Set(slicedTimes).size !== slicedTimes.length
}
