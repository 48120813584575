import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  checkPostalCodeAgainstProvince,
  NAME_REGEX,
  POSTAL_REGEX
} from 'src/utils/validation'
import { IUser } from 'src/models/user'

yup.addMethod<yup.StringSchema>(
  yup.string,
  'checkPostalCodeAgainstProvince',
  checkPostalCodeAgainstProvince
)

const oneFiftyYears = new Date()
oneFiftyYears.setFullYear(oneFiftyYears.getFullYear() - 150)

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required')
    .matches(
      NAME_REGEX,
      'First name can only contain hyphens, letters, and spaces.'
    ),
  lastName: yup
    .string()
    .required('Last name is required')
    .matches(
      NAME_REGEX,
      'Last name can only contain hyphens, letters, and spaces.'
    ),
  dob: yup
    .date()
    .min(oneFiftyYears, 'Date must be at least within last 150 years')
    .max(new Date(), 'Date cannot be in the future')
    .required('Date of birth is required'),
  city: yup.string(),
  province: yup.string().required(),
  emailAddress: yup.string().email('Must be a valid email address'),
  postalCode: yup
    .string()
    .checkPostalCodeAgainstProvince(
      yup.ref('province'),
      'Postal code must match province'
    )
    .matches(POSTAL_REGEX, 'Please enter a valid postal code.')
    .required('Postal code is required'),
  skipDelay: yup.boolean().default(false).required()
})

export const useUserInfoForm = (options?: any) => {
  const methods = useForm<IUser>({
    resolver: yupResolver(schema),
    ...options
  })

  return methods
}
