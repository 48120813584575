import React, { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { Checkbox, FormControlLabel, Switch } from '@material-ui/core'
import styled from 'styled-components'

import { DetailsContent } from 'src/shared/styled/Layout'
import { IUser, IUserAddress } from 'src/models/user'
import TextField from 'src/shared/views/TextField'
import {
  GENDER_DROPDOWN_ITEMS,
  PROVINCES_DROPDOWN_VALUES,
  TIMEZONE_DROPDOWN_ITEMS
} from 'src/constants/user'
import { DropDown, IDropDownItem } from 'src/shared/views/DropDown'
import { COLORS, FONTS } from 'src/shared/theme'
import UserNotesContainer from '../containers/UserNotes'
import { Heading } from 'src/shared/styled/Text'
import { ExitToApp, FileCopyOutlined } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { useUserBalances } from 'src/shared/hooks/users'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import { FieldErrors } from 'react-hook-form/dist/types'

interface IUserInfoViewAccount {
  addressDefaultValues: Partial<IUserAddress>
  errors: FieldErrors<IUser>
  control: Control<IUser>
  phoneNumberDropDownValues: IDropDownItem[]
  user: IUser
  setValue: any
}

const formFieldStyles = {
  marginBottom: '30px',
  marginRight: '20px',
  minWidth: '200px',
  width: '50%',
  maxWidth: '250px'
}

const StyledDivider = styled.hr`
  border-width: 0;
  border-bottom-width: 1px;
  border-bottom-color: ${COLORS.alto};
`

const BoldText = styled.span`
  font-weight: bold;
  font-family: ${FONTS.medium};
  font-size: 16px;
`

const EarningsContainer = styled.div`
  margin: 30px;
`

const EarningsHeaderButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const EarningsHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const FormFieldsRow = styled.span``

const FormDropDown = styled(DropDown)`
  margin-right: 40px;
`

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

const UserInfoAccount: FC<IUserInfoViewAccount> = ({
  errors,
  control,
  phoneNumberDropDownValues,
  user
}) => {
  const { data, isLoading } = useUserBalances(user.userId)

  const userBalances = React.useMemo(() => {
    if (!data || isLoading) {
      return <LoadingScreen />
    }

    return (
      <div>
        <p>
          <BoldText>Lifetime Earnings:</BoldText> {data.lifetimeEarnings.amount}{' '}
          {data.lifetimeEarnings.currencyCode}
        </p>
        <p>
          <BoldText>Lifetime Payouts:</BoldText> {data.lifetimePayouts.amount}{' '}
          {data.lifetimePayouts.currencyCode}
        </p>
        <p>
          <BoldText>Total Liability:</BoldText> {data.totalLiability.amount}{' '}
          {data.totalLiability.currencyCode}
        </p>
      </div>
    )
  }, [data, isLoading])

  return (
    <>
      <DetailsContent>
        <div style={{ marginBottom: '30px' }}>
          <Controller
            render={({ field: { value, ...props } }) => (
              <Checkbox
                disabled
                id="physicalCardRequested"
                checked={value}
                color="primary"
                {...props}
              />
            )}
            control={control}
            name="physicalCardRequested"
          />
          <label htmlFor="physicalCardRequested">
            Physical Blue Charm card requested
          </label>
        </div>
        <FormFieldsRow>
          <Controller
            render={({ field: { ref, ...props } }) => (
              <TextField
                style={formFieldStyles}
                label="First Name"
                placeholder="Ex. John"
                inputRef={ref}
                error={!!errors?.firstName?.message}
                helperText={errors?.firstName?.message}
                {...props}
              />
            )}
            name="firstName"
            control={control}
          />
          <Controller
            defaultValue={''}
            render={({ field: { ref, ...props } }) => (
              <TextField
                style={formFieldStyles}
                placeholder="Ex. Smith"
                label="Last Name"
                error={!!errors?.lastName?.message}
                helperText={errors?.lastName?.message}
                inputRef={ref}
                {...props}
              />
            )}
            name="lastName"
            control={control}
          />
          <Controller
            render={({ field: { ref, ...props } }) => (
              <TextField
                style={formFieldStyles}
                type="date"
                InputLabelProps={{ shrink: true }}
                placeholder="Ex. 10/10/1950"
                label="Date of Birth"
                inputRef={ref}
                error={!!errors?.dob?.message}
                helperText={errors?.dob?.message}
                {...props}
              />
            )}
            name="dob"
            control={control}
          />
          <Controller
            render={({ field: { ref, ...props } }) => (
              <FormDropDown
                items={GENDER_DROPDOWN_ITEMS}
                label="Sex"
                style={formFieldStyles}
                inputRef={ref}
                {...props}
              />
            )}
            control={control}
            name="gender"
          />
          <Controller
            render={({ field: { ref, ...props } }) => (
              <FormDropDown
                items={TIMEZONE_DROPDOWN_ITEMS}
                label="Time Zone"
                style={formFieldStyles}
                inputRef={ref}
                {...props}
              />
            )}
            control={control}
            name="preferredTimeZoneId"
          />
        </FormFieldsRow>
        <FormFieldsRow>
          <Controller
            render={({ field: { ref, ...props } }) => (
              <TextField
                style={formFieldStyles}
                placeholder="Ex. 123 Fleet St"
                label="Address"
                error={!!errors?.address?.street?.message}
                helperText={errors?.address?.street?.message}
                inputRef={ref}
                disabled
                {...props}
              />
            )}
            defaultValue={''}
            name="address.street"
            control={control}
          />
          <Controller
            defaultValue={''}
            render={({ field: { ref, ...props } }) => (
              <TextField
                style={formFieldStyles}
                placeholder="Ex. 123"
                label="Unit"
                error={!!errors?.address?.unit?.message}
                helperText={errors?.address?.unit?.message}
                inputRef={ref}
                disabled
                {...props}
              />
            )}
            name="address.unit"
            control={control}
          />
          <Controller
            defaultValue={''}
            render={({ field: { ref, ...props } }) => (
              <TextField
                style={formFieldStyles}
                placeholder="Ex. Halifax"
                label="City"
                error={!!errors?.address?.city?.message}
                helperText={errors?.address?.city?.message}
                inputRef={ref}
                disabled
                {...props}
              />
            )}
            name="address.city"
            control={control}
          />
          <Controller
            render={({ field: { ref, ...props } }) => (
              <FormDropDown
                items={PROVINCES_DROPDOWN_VALUES}
                label="Province"
                style={formFieldStyles}
                inputRef={ref}
                error={!!errors?.address?.province?.message}
                helperText={errors?.address?.province?.message}
                {...props}
              />
            )}
            control={control}
            name="address.province"
          />
          <Controller
            render={({ field: { ref, ...props } }) => (
              <TextField
                style={formFieldStyles}
                placeholder="Ex. B5V 3S2"
                label="Postal Code"
                inputRef={ref}
                error={!!errors?.address?.postalCode?.message}
                helperText={errors?.address?.postalCode?.message}
                {...props}
              />
            )}
            name="address.postalCode"
            control={control}
          />
        </FormFieldsRow>
        <FormFieldsRow>
          <Controller
            defaultValue={''}
            render={({ field: { ref, ...props } }) => (
              <TextField
                error={!!errors?.email?.message}
                style={formFieldStyles}
                helperText={errors?.email?.message}
                variant="outlined"
                placeholder="Ex. john_smith@gmail.com"
                label="Email Address"
                inputRef={ref}
                {...props}
              />
            )}
            name="email"
            control={control}
          />
          <Controller
            defaultValue={''}
            render={({ field: { ref, ...props } }) => (
              <FormDropDown
                items={phoneNumberDropDownValues}
                label={`Phone Numbers (${phoneNumberDropDownValues.length})`}
                style={formFieldStyles}
                inputRef={ref}
                helperText={
                  <span
                    style={{ cursor: 'copy' }}
                    onClick={() =>
                      navigator.clipboard.writeText(props.value || '')
                    }
                  >
                    Copy
                  </span>
                }
                {...props}
              />
            )}
            name="phoneNumber"
            control={control}
          />
          <Controller
            control={control}
            name="skipDelay"
            defaultValue={false}
            render={({ field: { value, onChange } }) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                }
                label="Skip LM Countdown"
              />
            )}
          />
        </FormFieldsRow>
      </DetailsContent>
      <StyledDivider />
      <Columns>
        <DetailsContent style={{ width: '50%' }}>
          <Heading>Misc Details</Heading>
          <div>
            <BoldText>Referral Code: </BoldText>
            {user.referralCode ? (
              <>
                <span>{user.referralCode}</span>
                <FileCopyOutlined
                  color="primary"
                  style={{ height: '1rem', cursor: 'pointer' }}
                  onClick={() =>
                    navigator.clipboard.writeText(user.referralCode || '')
                  }
                />
              </>
            ) : (
              <span>None</span>
            )}
          </div>
          <div>
            <BoldText>SMS Notifications: </BoldText>
            <span>{user.notifications.sms ? 'Enabled' : 'Disabled'}</span>
          </div>
        </DetailsContent>
        <EarningsContainer style={{ width: '50%' }}>
          <EarningsHeaderButtonContainer>
            <EarningsHeaderContainer>
              <Heading>Earnings</Heading>

              <Link
                target="_blank"
                to={`/tools/balance-reports?userId=${user.userId}`}
              >
                <ExitToApp color="primary" />
              </Link>
            </EarningsHeaderContainer>
          </EarningsHeaderButtonContainer>

          {userBalances}
        </EarningsContainer>
      </Columns>
      <StyledDivider />
      <UserNotesContainer />
    </>
  )
}

export default UserInfoAccount
