import React from 'react'
import Button from '@material-ui/core/Button'
import { Edit, Delete } from '@material-ui/icons'

import { Learning } from 'src/shared/styled'
import { UserPermissionsEnum } from 'src/models/user'
import { usePermissions } from 'src/shared/hooks/usePermissions'

interface IHoverToEditProps {
  onEditClick: () => void
  onDeleteClick: () => void
}

const { HoverToEditContainer } = Learning

const HoverToEdit: React.FC<IHoverToEditProps> = ({
  onEditClick,
  onDeleteClick
}) => {
  const permissions = usePermissions()
  const canCurrentUserWrite = permissions.includes(
    UserPermissionsEnum.ProgramWrite
  )
  return (
    <HoverToEditContainer>
      {canCurrentUserWrite && (
        <div className="button-container">
          <Button startIcon={<Edit />} variant="outlined" onClick={onEditClick}>
            Edit
          </Button>
          <Button
            startIcon={<Delete />}
            variant="outlined"
            onClick={onDeleteClick}
          >
            Delete
          </Button>
        </div>
      )}
      <div className="background"></div>
    </HoverToEditContainer>
  )
}

export default HoverToEdit
