import { DAYS_OF_WEEK } from 'src/constants/prescriptions'
import { FrequencyEnum, IDosingSchedule } from 'src/models/prescription'
import { convertTime12to24, getDateStringOffset } from './dates'

export const generateDosingArray = (
  frequency: FrequencyEnum,
  dosesPerDay: number,
  doseTimes?: string[],
  daysOfWeek?: {
    value: string
  }[],
  everyNthDay?: number
): IDosingSchedule[] => {
  let dosing: IDosingSchedule[] = []
  if (doseTimes?.length) {
    const times = doseTimes.slice(0, dosesPerDay).map((dt: string) => {
      const time = `${convertTime12to24(dt)}${getDateStringOffset()}`

      return time
    })

    // create times array based on doseTimes and selected frequency
    if (frequency === FrequencyEnum.Specific && daysOfWeek?.length) {
      dosing = daysOfWeek.map((day) => ({
        times,
        dayOfWeek: day.value.toUpperCase()
      }))
    } else if (frequency === FrequencyEnum.Interval) {
      dosing.push({
        times,
        everyNthDay: parseInt(everyNthDay as unknown as string, 10)
      })
    } else if (frequency === FrequencyEnum.Daily) {
      dosing = DAYS_OF_WEEK.map((day) => ({
        times,
        dayOfWeek: day.toUpperCase()
      }))
    }
  }

  return dosing
}
