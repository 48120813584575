import React from 'react'
import { Controller } from 'react-hook-form'

import { useLMSTextForm } from '../hooks/forms'
import { Learning, Text } from 'src/shared/styled'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import { ILMSTextSection } from 'src/models/learning'
import { useLMS } from 'src/contexts/Learning'

const { BoldHeading } = Text
const { AddTextContainer, Form, DescriptionTextField } = Learning

interface IAddTextSection {
  cancelEditText: () => void
  section: ILMSTextSection
  pageId: string
}

const AddTextSection: React.FC<IAddTextSection> = ({
  cancelEditText,
  section,
  pageId
}) => {
  const { saveSection, setSavedFalse } = useLMS()
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isDirty }
  } = useLMSTextForm()

  React.useEffect(() => {
    if (isDirty && section.saved) {
      setSavedFalse(pageId, section.id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, pageId, section.id])

  const resetForm = () => {
    cancelEditText()
    reset()
  }
  const handleSave = (formValues: any) => {
    saveSection(pageId, section.id, formValues)
    cancelEditText()
  }

  watch()

  return (
    <AddTextContainer>
      <Form onSubmit={handleSubmit(handleSave)}>
        <div className="fields-container">
          <BoldHeading>Text</BoldHeading>
          <Controller
            defaultValue={section.text}
            name="text"
            control={control}
            render={({ field: { ref, ...props } }) => (
              <DescriptionTextField
                label="Text"
                variant="filled"
                inputRef={ref}
                error={!!errors?.text?.message}
                helperText={errors?.text?.message}
                {...props}
              />
            )}
          />
        </div>
        <div className="button-container">
          <ButtonWithWidth type="submit" color="secondary" disabled={!isDirty}>
            SAVE
          </ButtonWithWidth>
          <ButtonWithWidth variant="outlined" onClick={resetForm}>
            CANCEL
          </ButtonWithWidth>
        </div>
      </Form>
    </AddTextContainer>
  )
}

export default AddTextSection
