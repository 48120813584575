import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ModulesApi } from 'src/api/client'
import {
  FETCH_MODULE,
  FETCH_MODULES,
  FETCH_MODULES_FOR_PROGRAM
} from 'src/constants/queries'
import { IModule, IModuleFormData } from 'src/models/module'
import { logger } from 'src/utils/logger'

export const useGetModules = () => {
  const query = useQuery<IModule[]>(FETCH_MODULES, () =>
    ModulesApi.getModules()
  )

  return { ...query, modules: query.data }
}

export const useGetModule = (id: string) => {
  const query = useQuery<IModule>(FETCH_MODULE(id), () =>
    ModulesApi.getModule(id)
  )

  return { ...query, module: query.data }
}

export const useGetModulesForProgram = (programId: string) => {
  const query = useQuery<IModule[]>(FETCH_MODULES_FOR_PROGRAM(programId), () =>
    ModulesApi.getModulesForProgram(programId)
  )

  return { ...query, modules: query.data }
}

/**
 * ----- Mutations -----
 */

export const useCreateModule = ({
  onSuccess
}: {
  onSuccess?: (data: string) => void
} = {}) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (data: IModuleFormData) => ModulesApi.createModule(data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(FETCH_MODULES)

        if (onSuccess) onSuccess(data)
      },
      onError: (err) => {
        logger.error('Error creating module', err)
      }
    }
  )

  return { ...mutation, createModule: mutation.mutate }
}

export const useUpdateModule = (
  moduleId: string,
  {
    onSuccess
  }: {
    onSuccess?: () => void
  } = {}
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (data: IModuleFormData) => ModulesApi.putModule(moduleId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_MODULE(moduleId))
        queryClient.invalidateQueries(FETCH_MODULES)

        if (onSuccess) onSuccess()
      },
      onError: (err) => {
        logger.error('Error updating module', err)
      }
    }
  )

  return { ...mutation, updateModule: mutation.mutate }
}

export const useUpdateModulesForProgram = (
  programId: string,
  {
    onSuccess,
    onError
  }: { onSuccess?: () => void; onError?: (error: Error) => void } = {}
) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (moduleIds: string[]) =>
      ModulesApi.putModulesForProgram(programId, moduleIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_MODULES_FOR_PROGRAM(programId))

        if (onSuccess) onSuccess()
      },
      onError: (err: Error) => {
        logger.error('Error updating modules for program', err)
        if (onError) onError(err)
      }
    }
  )

  return { ...mutation, updateModulesForProgram: mutation.mutate }
}
