import React from 'react'

import HoverToEdit from './HoverToEdit'
import { ILMSQuestionSection } from 'src/models/learning'
import AddQuestionSection from './AddQuestionSection'
import { Text, Learning } from 'src/shared/styled'
import { useLMS } from 'src/contexts/Learning'

interface IDisplayQuestionSectionProps {
  questionSection: ILMSQuestionSection
  pageId: string
}

const { QuestionDisplayContainer } = Learning
const { LMSTextSectionDisplay } = Text

const DisplayQuestionSection: React.FC<IDisplayQuestionSectionProps> = ({
  questionSection,
  pageId
}) => {
  const { removeSection } = useLMS()
  const { question } = questionSection
  const [isEditingQuestion, setIsEdittingQuestion] = React.useState(() => {
    if (question.questionText) {
      return false
    } else {
      return true
    }
  })
  return (
    <div>
      {isEditingQuestion ? (
        <AddQuestionSection
          questionSection={questionSection}
          pageId={pageId}
          cancelEdit={() => setIsEdittingQuestion(false)}
        />
      ) : (
        <QuestionDisplayContainer className="hover-to-edit-anchor">
          <LMSTextSectionDisplay>{question.questionText}</LMSTextSectionDisplay>
          <div className="options">
            {question.answerOptions.map((option, index) => (
              <div className="option" key={index}>
                <div className="circle" />
                <LMSTextSectionDisplay>{option.text}</LMSTextSectionDisplay>
              </div>
            ))}
          </div>
          <HoverToEdit
            onEditClick={() => setIsEdittingQuestion(true)}
            onDeleteClick={() => removeSection(pageId, questionSection.id)}
          />
        </QuestionDisplayContainer>
      )}
    </div>
  )
}

export default DisplayQuestionSection
