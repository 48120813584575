import { FC, Fragment, ReactNode } from 'react'
import styled from 'styled-components'
import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderInputParams
} from '@material-ui/lab/Autocomplete'
import { Checkbox, Chip, InputAdornment } from '@material-ui/core'
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
  SearchOutlined
} from '@material-ui/icons'

import TextField from './TextField'
import { IDin, IIndication, IProgramList } from '../../models/program'
import { COLORS } from '../theme'

export interface Props
  extends Omit<
    AutocompleteProps<string, true, true, true>,
    'error' | 'required' | 'options' | 'value' | 'renderInput'
  > {
  helperText?: ReactNode
  label: string
  inputId: string
  options: { value: string }[]
  value: any | any[]
}

const StyledAutoComplete = styled(Autocomplete)`
  & .MuiFormLabel-root.Mui-focused,
  .MuiOutlinedInput-root.Mui-focused {
    color: ${(props) => props.theme.palette.secondary.main};
  }
  && [class*='MuiOutlinedInput-root'] {
    padding-right: 9px;
  }
`
const icon = <CheckBoxOutlineBlank fontSize="small" />

const StyledCheckedIcon = styled(CheckBoxIcon)`
  color: ${(props) => props.theme.palette.primary.main};
`

const checkedIcon = <StyledCheckedIcon fontSize="small" />

const ThemedAutoComplete: FC<Props> = ({
  multiple,
  id,
  inputId,
  options,
  label,
  value,
  onInputChange,
  onChange,
  ...props
}: Props) => {
  return (
    <StyledAutoComplete
      multiple={multiple}
      id={id}
      disableCloseOnSelect
      disableClearable
      options={options}
      value={value}
      onInputChange={onInputChange}
      onChange={onChange}
      getOptionLabel={(option: IDin | IIndication | IProgramList) =>
        option.value
      }
      getOptionSelected={(
        option: IDin | IIndication | IProgramList,
        value: IDin | IIndication | IProgramList
      ) => option.value === value.value}
      renderOption={(
        option: IDin | IIndication | IProgramList,
        { selected }
      ) => (
        <Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.value}
        </Fragment>
      )}
      renderTags={(
        value: IDin[] | IIndication[] | IProgramList[],
        getTagProps
      ) =>
        value?.map(
          (option: IDin | IIndication | IProgramList, index: number) => (
            <Chip
              size="small"
              style={{ marginRight: '2em' }}
              variant="outlined"
              label={option.value}
              {...getTagProps({ index })}
            />
          )
        )
      }
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          id={inputId}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            type: 'search',
            endAdornment: (
              <InputAdornment position="end">
                <SearchOutlined style={{ color: COLORS.empress }} />
              </InputAdornment>
            )
          }}
        />
      )}
      {...props}
    />
  )
}

export default ThemedAutoComplete
