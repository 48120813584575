import { useMutation, useQuery } from 'react-query'
import { VerificationsApi } from 'src/api/client'
import {
  FETCH_VERIFICATION_TASK_AUDIT_LOGS,
  FETCH_VERIFICATION_TASKS
} from 'src/constants/queries'
import { ProgramMembershipVerificationStatuses } from 'src/models/user'
import {
  IPrescriptionVerificationTask,
  IVerificationTaskAuditLog,
  IVerificationTaskResponseBody,
  PrescriptionVerificationStatuses,
  VerificationTaskStatus
} from 'src/models/verificationTask'
import { logger } from 'src/utils/logger'

export const useVerifications = (
  status: VerificationTaskStatus,
  offset: number,
  limit: number,
  dateAfter?: Date,
  userId?: string
) => {
  const query = useQuery<IVerificationTaskResponseBody>(
    FETCH_VERIFICATION_TASKS(status, offset, limit, dateAfter, userId),
    () =>
      VerificationsApi.listVerificationTasks(
        status,
        offset,
        limit,
        dateAfter,
        userId
      ),
    {
      cacheTime: 0
    }
  )

  return {
    ...query,
    verificationTasks: query.data?.data,
    totals: query.data?.tabTotals
  }
}

export const useUpdatePrescriptionVerification = (
  userId: string,
  options: { onSuccess?: (data: IPrescriptionVerificationTask) => void }
) => {
  const mutation = useMutation(
    (data: {
      prescriptionId: string
      status: PrescriptionVerificationStatuses
      customRejectionMsg?: string
    }) => {
      return VerificationsApi.updatePrescriptionVerificationStatus(
        userId,
        data.prescriptionId,
        data.status,
        data.customRejectionMsg
      )
    },
    {
      onSuccess: (data) => {
        options.onSuccess?.(data)
      },
      onError: (err) => {
        logger.error('Error updating this prescription', err)
      }
    }
  )

  return { ...mutation, updatePrescriptionVerification: mutation.mutate }
}

export const useUpdateProgramVerification = (
  userId: string,
  options: { onSuccess?: () => void }
) => {
  const mutation = useMutation(
    (data: {
      programId: string
      status: ProgramMembershipVerificationStatuses
    }) => {
      return VerificationsApi.updateProgramVerificationStatus(
        userId,
        data.programId,
        data.status
      )
    },
    {
      onSuccess: () => {
        options.onSuccess?.()
      },
      onError: (err) => {
        logger.error('Error updating this program', err)
      }
    }
  )

  return { ...mutation, updateProgramVerification: mutation.mutate }
}

export const useVerificationAuditLog = (userId: string, programId: string) => {
  const query = useQuery<IVerificationTaskAuditLog[]>(
    FETCH_VERIFICATION_TASK_AUDIT_LOGS(userId, programId),
    () => VerificationsApi.getTaskAuditLogs(userId, programId),
    {
      cacheTime: 0
    }
  )

  return {
    ...query,
    logs: query.data
  }
}
