import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { IBalanceReport } from 'src/models/tools'
import * as yup from 'yup'

const DinSchema = yup.object().shape({
  indications: yup.string(),
  sideEffects: yup.string()
})

const DrugLibSearchSchema = yup.object().shape({
  searchType: yup.string().required('Search Type is required'),
  din: yup.string().length(8, 'DIN must be 8 characters long'),
  brandName: yup
    .string()
    .notRequired()
    .test(
      'len',
      'Must enter at least 2 characters',
      (value) => !value || (!!value && value.length >= 2)
    ),
  manufacturer: yup
    .string()
    .notRequired()
    .test(
      'len',
      'Must enter at least 2 characters',
      (value) => !value || (!!value && value.length >= 2)
    ),
  activeIngredient: yup
    .string()
    .notRequired()
    .test(
      'len',
      'Must enter at least 2 characters',
      (value) => !value || (!!value && value.length >= 2)
    )
})

const IndicationSchema = yup.object().shape({
  indication: yup.string().required('Indication name is required')
})

const SideEffectSchema = yup.object().shape({
  sideEffect: yup.string().required('Side effect name is required')
})

const IndicationOrSideEffectSchema = yup.object().shape({
  name: yup.string().required('Name is required')
})

const PayerSchema = yup.object().shape({
  payerName: yup.string().required('Payer name is required')
})

const oneFiftyYears = new Date()
oneFiftyYears.setFullYear(oneFiftyYears.getFullYear() - 150)

const BalanceReportSchema = yup.object().shape({
  dob: yup
    .date()
    .min(oneFiftyYears, 'Date must be at least within last 150 years')
    .max(new Date(), 'Date cannot be in the future')
    .required('Date is required'),
  city: yup.string()
})

const usePayersForm = (options?: any) => {
  return useForm({
    resolver: yupResolver(PayerSchema),
    ...options
  })
}

const useIndicationSearchForm = (options?: any) => {
  return useForm({
    resolver: yupResolver(IndicationSchema),
    ...options
  })
}

const useSideEffectSearchForm = (options?: any) => {
  return useForm({
    resolver: yupResolver(SideEffectSchema),
    ...options
  })
}

const useDrugLibSearchForm = (options?: any) => {
  return useForm({
    resolver: yupResolver(DrugLibSearchSchema),
    ...options
  })
}

const useDrugLibItemForm = (options?: any) => {
  return useForm({
    resolver: yupResolver(DinSchema),
    ...options
  })
}

const useSideEffectOrIndicationForm = (options?: any) => {
  return useForm({
    resolver: yupResolver(IndicationOrSideEffectSchema),
    ...options
  })
}

const useBalanceReportsForm = (options?: any) => {
  return useForm<IBalanceReport>({
    resolver: yupResolver(BalanceReportSchema),
    ...options
  })
}

export {
  useBalanceReportsForm,
  useDrugLibItemForm,
  useDrugLibSearchForm,
  useIndicationSearchForm,
  usePayersForm,
  useSideEffectSearchForm,
  useSideEffectOrIndicationForm
}
