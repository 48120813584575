import DinInfo from '../views/DinInfo'

const DinInfoContainer = () => {
  return (
    <div style={{ width: '100%' }}>
      <DinInfo />
    </div>
  )
}

export default DinInfoContainer
