import { FC } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { UserAdminMatchParams } from 'src/models/routeParams'
import { usePurgeAllUserTraces } from 'src/shared/hooks/users'
import EraseUserView from '../views/EraseUser'

interface IEraseUserContainer {
  closeModal: () => void
}

const EraseUserContainer: FC<IEraseUserContainer> = ({ closeModal }) => {
  const { id: userId } = useParams<UserAdminMatchParams>()
  const history = useHistory()

  const eraseUser = usePurgeAllUserTraces(userId, {
    onSuccess: () => {
      history.push('/users')
      closeModal()
    }
  })

  return <EraseUserView closeModal={closeModal} eraseUserMutation={eraseUser} />
}

export default EraseUserContainer
