import React from 'react'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import { COLORS } from 'src/shared/theme'
import styled from 'styled-components'

interface IPhoneMockupProps {
  nextClick: () => void
}
const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #212121;
  border-radius: 30px 30px 0px 0px;
  height: 60px;
  width: 380px;
  & .speaker {
    height: 10px;
    width: 50px;
    background-color: white;
  }
`
const Chin = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #212121;
  border-radius: 0px 0px 30px 30px;
  height: 60px;
  width: 380px;
  & .outer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background-color: white;
    & .inner {
      border-radius: 50%;
      height: 25px;
      width: 25px;
      background-color: #212121;
    }
  }
`
const Side = styled.div`
  background-color: #212121;
  width: 15px;
`
const Screen = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow-y: scroll;
  width: 350px;
  height: 600px;
  padding: 10px;
  scrollbar-width: none;
  & .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    & .header {
      margin-bottom: 20px;
      & .title {
        margin-bottom: 10px;
      }
    }
    & .section {
      margin 10px 0px; 
    }
    & .question-text {
      text-align: center;
      font-weight: bolder;
    }
  }
  & .MuiButtonBase-root {
    width: 100%;
    margin: 0;
    background-color: ${COLORS.cerulean};
  }
  &::-webkit-scrollbar {
    width: 0px;
  }
  & img {
    max-width: 330px;
  }
`
const PhoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  & .screen-container {
    display: flex;
    flex-direction: row;
  }
`

const PhoneMockup: React.FC<IPhoneMockupProps> = ({ children, nextClick }) => {
  return (
    <PhoneContainer>
      <Header>
        <div className="speaker" />
      </Header>
      <div className="screen-container">
        <Side />
        <Screen>
          {children}
          <ButtonWithWidth variant="contained" onClick={nextClick}>
            NEXT
          </ButtonWithWidth>
        </Screen>
        <Side />
      </div>
      <Chin>
        <div className="outer">
          <div className="inner" />
        </div>
      </Chin>
    </PhoneContainer>
  )
}

export default PhoneMockup
