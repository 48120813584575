import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

const DirectDepositSchema = yup.object().shape({
  label: yup.string(),
  institutionNumber: yup.string().required(),
  transitNumber: yup.string().required(),
  accountNumber: yup.string().required()
})

const useDirectDepositForm = (options?: any) => {
  const form = useForm({
    resolver: yupResolver(DirectDepositSchema),
    defaultValues: {
      label: '',
      institutionNumber: '',
      transitNumber: '',
      accountNumber: ''
    },
    ...options
  })

  return { ...form }
}

export default useDirectDepositForm
